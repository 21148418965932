<script setup lang="ts">
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import {FButton} from "@/components/Global";
import CustomColorPicker from "./CustomColorPicker.vue";
import {useSchedulingStore} from "@/stores/schedulingStore";
import type {SchedulingColor} from "@oplit/shared-module";

/**
 * this component was made to be used through the defineColor directive (v-define-color)
 * see details in @/plugins/directives/defineColor.ts
 */
interface CustomColorDefinerProps {
  activator?: HTMLDivElement;
  parameters: SchedulingColor;
}

const props = withDefaults(defineProps<CustomColorDefinerProps>(), {
  activator: () => null as HTMLDivElement,
});

const {dailySchedulingColors} = storeToRefs(useSchedulingStore());
const {updateDailySchedulingColor} = useSchedulingStore();

const colors = [
  "newGreenRegular",
  "newPrimaryRegular",
  "newPurpleRegular",
  "newPinkRegular",
  "newOrangeRegular",
  "newDisableText",
  "newMainText",
];

const selectedColor = ref(null);
/**
 * original color prior to modifying
 * used to compare against `selectedColor` for deletion
 */
const originalColor = ref(null);
const isDisplayedMenu = ref(true);

function initializeColor(): void {
  const {day_date} = props.parameters;
  const {color_name = null} =
    dailySchedulingColors.value?.find(
      (dailySchedulingColor: SchedulingColor) =>
        dailySchedulingColor.day_date === day_date,
    ) || {};
  selectedColor.value = originalColor.value = color_name;
}
async function onSaveClick(): Promise<void> {
  await updateDailySchedulingColor({
    ...props.parameters,
    color_name: selectedColor.value,
  });

  isDisplayedMenu.value = false;
}

onMounted(initializeColor);
</script>

<template>
  <v-menu
    v-model="isDisplayedMenu"
    :activator="activator"
    :close-on-content-click="false"
    persistent
    @update:model-value="initializeColor"
  >
    <div class="custom-color-definer">
      <div class="d-flex justify-space-between">
        <span class="text-grey-500">
          {{ moment(parameters.day_date).format("DD MMMM YYYY") }}
        </span>

        <vue-feather
          type="x"
          size="16px"
          class="cursor-pointer"
          @click="() => (isDisplayedMenu = false)"
        />
      </div>

      <CustomColorPicker
        v-model:current-color="selectedColor"
        :colors="colors"
        class="pa-1"
      />

      <FButton
        filled
        :disabled="selectedColor === originalColor"
        @click="onSaveClick"
      >
        {{ $t("global.save") }}
      </FButton>
    </div>
  </v-menu>
</template>

<style scoped lang="scss">
.custom-color-definer {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;

  & > div:first-child {
    & .selected {
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
        background: transparent;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(var(--v-theme-newPrimaryRegular));
        border-radius: 50%;
      }
    }
  }
}
</style>
