import {ref, computed, App, unref} from "vue";
import {Pinia, defineStore, getActivePinia} from "pinia";
import _ from "lodash";
import {
  Unsubscribe,
  getDocs,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";

import {PROJECTED_DELAY_METHODS, SIMULATION_STATUS} from "@/config/constants";
import CapacitiesAPIClient from "@/api/capacities";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";

import light_variables from "@/scss/light_colors.module.scss";
import opalColors from "@/scss/themes/colors.module.scss";
import type {
  FabriqUser,
  GenericObject,
  User,
  Client,
  Team,
  Sector,
  Operator,
  Machine,
  Event,
  OplitMacroEvent,
  Simulation,
  ClientParameter,
  Perimeters,
  Poste,
  Parameter,
  ClientGroup,
  ParentIds,
  MercateamEmployee,
  SectorLike,
} from "@/interfaces";
import type {LocationQuery, RouteRecordName} from "vue-router";

const refreshTime = 60000 * 2;

import {
  ImportIdObj,
  asyncForEach,
  buildRecursivelySectorTree,
  getSortedPerimeters,
  hasSectorAutoOrga,
  levelCorresp,
  parseDate,
  simplifySector,
  type AppEnvironment,
  getSectorOrderedCalendars,
  getNextAvailableDate,
  type SectorCalendar,
  MAIN_OPLIT_MODULES,
  SECONDARY_OPLIT_MODULES,
  MAP_TABLE_MODULE_ROUTE_NAME,
  USER_ROLES,
} from "@oplit/shared-module";
import {
  filterSimulationsOnTeam,
  mapSimulationsName,
} from "@/tscript/utils/schedulingUtils";
import loggerHelper from "@/tscript/loggerHelper";
import {MultiFactorResolver} from "firebase/auth";
import {useClientsConfigurations} from "@/composables/useClientsConfigurations";
import {i18n} from "@/i18n";
import {useRoute} from "vue-router";
import {useDBUtils} from "@/composables/dbUtils";
import {fetchActiveMSD} from "@/tscript/utils/sharedModuleHelper";

export const useMainStore = defineStore("main", () => {
  /**
   * FIXME
   * we replaced `APIClient` by `CapacitiesAPIClient` which is an extended version
   * we did so to prevent redefining a specific store module/state/getter
   * this should be updated properly in the long run - either with specific modules or a refacto of the APIClient class
   */
  const route = useRoute();
  const {t} = i18n;
  const currentQuery = ref<LocationQuery>({});
  const apiClient = ref<CapacitiesAPIClient>(new CapacitiesAPIClient());
  const userId = ref<string>(null);
  const userData = ref<User>(null);
  const clientsList = ref<Client[]>(null);
  const teams = ref<Team[]>([]);
  const clientGroups = ref<ClientGroup[]>([]);
  const users = ref<User[]>([]);
  const perimeters = ref<Perimeters>({});
  const mercateamSectors = ref<Sector[]>([]);
  const operators = ref<Operator[]>([]);
  const machines = ref<Machine[]>([]);
  const clientParameters = ref<ClientParameter>({});
  const userParameters = ref<GenericObject>({});
  const events = ref<Event[]>([]);
  const macroEvents = ref<OplitMacroEvent[]>([]);
  const simulations = ref<Simulation[]>([]);
  const simulation = ref<Simulation>(null);
  const calendars = ref<SectorCalendar[]>([]);
  const imports = ref<ImportIdObj[]>([]);
  const importTypes = ref<unknown[]>([]);
  const activeMsd = ref<Record<string, any> & {id?: string}>({});
  const status = ref<string>(null);
  const error = ref<string>(null);
  const breadcrumbs = ref<unknown[]>([
    {name: "SOL", disabled: true, bcIdx: 0, placeholder: true},
  ]);
  const globalLoader = ref<boolean>(false);
  const version = ref<string>("");
  const allMercaEmployees = ref<MercateamEmployee[]>([]);
  const fabriqUsers = ref<FabriqUser[]>([]);
  const isDevEnv = ref<boolean>(import.meta.env.DEV);
  const optim = ref<GenericObject>({
    cache_store: 0,
    cache_calculation: false,
    multithread: false,
    events: false,
  });
  const pgRefresh = ref<number>(0);
  const disablePgRefresh = ref<boolean>(false);
  const pgTrigger = ref<GenericObject>({});
  const isAppLoading = ref<boolean>(false);
  const appLoadingValue = ref<number>(0);
  const isScheduling = ref<boolean>(false);
  const isSchedulingFullScreen = ref<boolean>(false);
  const realCapacity = ref<GenericObject>({
    value: 0,
    maille: {value: 3, unit: "month"},
  });
  const freezeOverlays = ref<boolean>(false);
  const redirect = ref<GenericObject>({});
  const mfaResolver = ref<MultiFactorResolver>();
  const resetPasswordNonce = ref<unknown>(null);
  const resetPasswordNonceFromEmail = ref<string>(
    new URLSearchParams(window.location.search).get("reset_password_nonce"),
  );
  const environment = ref<AppEnvironment>(null);
  const unsubscribesById = ref<Map<string, Unsubscribe>>(new Map());
  const planning_sectors = ref<(ParentIds & {secteur_id: string})[]>([]);
  const isSimulationUpdateSnackbarOpened = ref(false);

  const {
    getBaseOplitFirestoreDocument,
    getOplitFirestoreDocumentUpdateUserMetadata,
  } = useDBUtils();
  const doesSchedulingSimulationFloat = ref<boolean>(false);

  const hasAutoOrga = computed(() => {
    return hasSectorAutoOrga({
      planning_sectors: planning_sectors.value,
      secteur_id: activePerim.value?.id,
      secteur_type: activePerim.value?.type,
    });
  });

  const arePDPSimulationUpdatesDisabled = computed<boolean>(() => {
    return simulation.value?.status === SIMULATION_STATUS.ARCHIVED;
  });

  const hasMercateam = computed<boolean>(() => {
    return clientParameters.value?.api_connectors?.some(
      ({type}: {type: string}) => type === "mercateam",
    );
  });

  const activeSector = computed<Sector>(() => {
    const activeSector = breadcrumbs.value.at(-1);
    return activeSector || {};
  });

  const activePerim = computed<Sector>(() => {
    const {collection, id} = activeSector.value;
    if (!collection || !id) return {};

    const activePerim =
      perimeters.value[collection]?.find(
        (sector: Sector) => sector?.id === id,
      ) || {};
    const simpleActivePerim = simplifySector(activePerim);

    return simpleActivePerim;
  });

  const sectorTree = computed<Sector>(() => {
    const sectorTree = buildRecursivelySectorTree(
      activePerim.value,
      perimeters.value,
    );

    return sectorTree;
  });

  const isParent = computed<boolean>(() => {
    return !!(activePerim.value?.children || []).filter(
      (x: unknown) =>
        !!x && !["removed", "deleted"].includes((x as Sector).status),
    )?.length;
  });

  const team = computed<Team>(() => {
    if (!activePerim.value?.id || !teams.value?.length) return;
    let team: any;
    levelCorresp.forEach((level: any) => {
      const match = teams.value.find(
        (t: any) =>
          (activePerim.value[level.type + "_id"] === t.secteur_id ||
            activePerim.value.id === t.secteur_id) &&
          t.level / 1 === level.level / 1,
      );
      if (match) team = {...match};
    });
    return team || teams.value[0];
  });
  const teamIdBySector = computed<Record<string, string>>(() => {
    return stationsAndMachinesTags.value.reduce((acc, station) => {
      const {id: secteur_id} = station;
      const match = teams.value.find(
        (t: Team) =>
          station[t.type + "_id"] === t.secteur_id ||
          station.id === t.secteur_id ||
          station?.assignations?.[0]?.[t.type + "_id"] === t.secteur_id,
      );
      acc[secteur_id] = match?.id || "";
      return acc;
    }, {});
  });

  const teamSimulations = computed<Simulation[]>(() => {
    return mapSimulationsName(
      filterSimulationsOnTeam(simulations.value, team.value),
    );
  });

  const mergedParameters = computed<Parameter>(() => {
    const merged: any = {...clientParameters.value, ...userParameters.value};
    if (isNaN(merged.nbPeriodeHisto)) merged.nbPeriodeHisto = 2;
    if (isNaN(merged.nbPeriodePrev)) merged.nbPeriodePrev = 6;
    merged.netPeriodePrev = merged.nbPeriodePrev; // + Math.max(0, -merged.nbPeriodeHisto / 1);
    merged.netPeriodeHisto = Math.max(0, merged.nbPeriodeHisto / 1);
    if (merged.maille === undefined) merged.maille = "month";
    if (!merged.demonstratedCapacityPeriod)
      merged.demonstratedCapacityPeriod = {value: 3, unit: "months"};
    if (!merged.projectedDelayMethod)
      merged.projectedDelayMethod = PROJECTED_DELAY_METHODS.goal;
    return merged;
  });

  const variables = computed<GenericObject>(() => {
    return {...light_variables, ...opalColors};
  });
  const colors = computed<GenericObject>(() => opalColors);

  const stations = computed<Poste[]>(() => {
    if (!perimeters.value?.sites?.length) return [];
    const stations = [];
    levelCorresp.forEach((level: any) => {
      _.get(perimeters.value, level.collection, []).forEach((secteur: any) => {
        const chemin = [];
        // array of the ids from eldest parent to direct parent of secteur
        const idsPath = [];
        let shouldInsert = true;
        levelCorresp.forEach((sublevel: any) => {
          if (!shouldInsert || sublevel.level >= level.level / 1) return;
          if (secteur[sublevel.type + "_id"]) {
            const parent = (perimeters.value[sublevel.collection] || []).find(
              (s: any) => s.id === secteur[sublevel.type + "_id"],
            );
            if (!parent?.id) return (shouldInsert = false);
            chemin.push(parent.name);
            idsPath.push(parent.id);
          }
        });
        if (!shouldInsert) return;
        stations.push({
          text: [...chemin, secteur.name].filter(Boolean).join(" > "),
          parent_text: [_.last(chemin), secteur.name]
            .filter(Boolean)
            .join(" > "),
          idsPath,
          ...secteur,
          ...level,
        });
      });
    });
    return stations;
  });
  const stationsAndMachinesTags = computed(() => {
    const {scheduling_ignore_machines} = mergedParameters.value;

    if (scheduling_ignore_machines) return stations.value;
    return [
      ...stations.value,
      ...cleanMachineTags.value.reduce((acc, m) => {
        acc.push({...m, is_machine: true});
        return acc;
      }, []),
    ];
  });
  const stationsAndMachinesTagsGroupedById = computed(() =>
    _.groupBy<SectorLike>(stationsAndMachinesTags.value, "id"),
  );

  const hasStock = computed<boolean>(() => {
    return (
      (clientParameters.value?.has_module_stocks ||
        clientParameters.value?.has_stock) &&
      !isScheduling.value
    );
  });

  const hasNomenclature = computed<boolean>(
    () => clientParameters.value?.has_nomenclature,
  );

  const hasFullAccessPDP = computed<boolean>(
    () =>
      clientParameters.value?.has_module_pdp &&
      userData.value?.has_production_planning,
  );

  const hasFullAccessScheduling = computed<boolean>(
    () =>
      clientParameters.value?.has_module_scheduling &&
      userData.value?.has_scheduling,
  );

  const hasFullAccessStock = computed<boolean>(
    () =>
      clientParameters.value?.has_module_stocks && userData.value?.has_stocks,
  );

  const hasFullAccessCustomerInterfaceProviderSide = computed<boolean>(
    () =>
      clientParameters.value?.has_module_customer_interface_provider_side &&
      userData.value?.has_customer_interface_provider_side,
  );

  const hasFullAccessCustomerInterfaceCustomerSide = computed<boolean>(
    () =>
      clientParameters.value?.has_module_customer_interface_customer_side &&
      userData.value?.has_customer_interface_customer_side,
  );

  const hasOplitDateAccess = computed<boolean>(
    () => clientParameters.value?.can_see_oplit_date,
  );

  const pgSse = computed<unknown[]>(() => {
    return [
      ...new Set([
        ...(mergedParameters.value.pg_sse || []),
        "planning",
        "simulation",
        "charge",
        "capa",
        "scheduling",
      ]),
    ];
  });

  const siteId = computed<string>(() => {
    const {
      site_id,
      id: secteur_id,
      type: secteur_type,
    } = activePerim.value || {};
    const siteId = secteur_type === "site" ? secteur_id : site_id;
    return siteId;
  });
  const clientId = computed<string>(() => {
    return userData.value?.client_id;
  });

  const highestSector = computed<Sector>(() => {
    const {secteur_id, collection} = team.value || {};
    if (secteur_id) {
      const teamLevel = (perimeters.value[collection] || []).find(
        (x: any) => x.id === secteur_id,
      );
      if (teamLevel) return buildRecursivelySectorTree(teamLevel, perimeters);
    }
    return buildRecursivelySectorTree(perimeters.value.sites?.[0], perimeters);
  });

  const {getClientConfig} = useClientsConfigurations();
  const clientConfiguration = computed<any>(() => {
    return getClientConfig({
      client_id: clientId.value,
      environment: environment.value,
    });
  });

  const cleanMachineTags = computed(() => {
    return machines.value.filter(
      (x) => x.site_id && x.site_id === siteId.value,
    );
  });

  const hasOnlySideModules = computed<boolean>(
    () => !MAIN_OPLIT_MODULES.some((module) => unref(clientParameters)[module]),
  );

  const defaultRouteName = computed<string>(() => {
    if (!unref(hasOnlySideModules)) return "home";
    const firstActiveSideModule = SECONDARY_OPLIT_MODULES.find(
      (module) => unref(clientParameters)[module],
    );
    return MAP_TABLE_MODULE_ROUTE_NAME[firstActiveSideModule] || "home";
  });

  const mercateamCompetenciesBySector = computed<{
    [sectorId: string]: string[];
  }>(() => {
    if (!allMercaEmployees.value.length || !operators.value.length) return {};

    const operatorsWithMercateamAssociations = operators.value.filter(
      ({mercateam_id}) => !!mercateam_id,
    );

    return operatorsWithMercateamAssociations.reduce((acc, operator) => {
      const mercateamEmployee = allMercaEmployees.value.find(
        ({id}) => id === operator.mercateam_id,
      );

      if (!mercateamEmployee?.position_versatility?.length) return acc;

      for (const sector of mercateamEmployee.position_versatility)
        acc[sector.id] = [...(acc[sector.id] || []), operator.id];

      return acc;
    }, {} as {[sectorId: string]: string[]});
  });

  function incrementAppValue(increment = 0): void {
    appLoadingValue.value += increment || 10;
  }

  function removeUser(): void {
    userData.value = null;
  }

  function setFabriqUsers(users: FabriqUser[]): void {
    fabriqUsers.value = users.map((user) => ({
      ...user,
      is_fabriq: true,
    }));
  }

  function addUnsubscribe(unsubscribe: Unsubscribe): string {
    const id = `${
      Math.max(...Object.keys(unsubscribesById.value).map((key) => +key)) + 1
    }`;

    unsubscribesById.value.set(id, unsubscribe);

    return id;
  }
  function clearUnsubscribe(id: string): void {
    const unsubscribe = unsubscribesById.value.get(id);
    if (!unsubscribe) return;

    unsubscribe();
    unsubscribesById.value.delete(id);
  }
  function clearUnsubscribes(): void {
    unsubscribesById.value.forEach((_, key) => clearUnsubscribe(key));
  }

  function getClientModules(clientParameters = {}) {
    return [
      "has_module_pdp",
      "has_module_scheduling",
      "has_module_stocks",
      "has_module_customer_interface_customer_side",
      "has_module_customer_interface_provider_side",
    ].filter((module) => !!clientParameters[module]);
  }

  async function loadApiClient() {
    apiClient.value = new CapacitiesAPIClient();
  }
  async function loadTeams(clientId: string) {
    const teamResults = await dbHelper.getAllDataFromCollectionWithAll(
      "teams",
      {
        where: [
          {
            field: "client_id",
            value: clientId,
            compare: "==",
          },
        ],
      },
    );

    teams.value = _.sortBy(teamResults, (x: Team) => x.name);

    incrementAppValue(5);
  }

  const getClientGroupId = async (clientId: string): Promise<string> => {
    if (!clientId) return;
    const client = (await dbHelper.getDocFromCollection(
      "clients",
      clientId,
    )) as Client;
    return client?.group_id;
  };

  async function loadAllClientsGroup() {
    const groupResults = await dbHelper.getAllDataFromCollectionWithAll(
      "client_groups",
    );

    clientGroups.value = _.sortBy(groupResults, "name");
  }
  async function loadUsers(clientId: string) {
    if (clientId) {
      const {data} = await apiClient.value.getUsers(clientId);
      users.value = data || [];
    }

    incrementAppValue(5);
  }

  const getClientGroupList = async (groupClientID: string): Promise<Client[]> =>
    await dbHelper.getAllDataFromCollectionWithAll("clients", {
      where: [{field: "group_id", compare: "==", value: groupClientID}],
    });

  async function loadClientsList(user: User) {
    let clients = [];
    if (user?.role === USER_ROLES.ADMIN) {
      clients = await dbHelper.getAllDataFromCollectionWithAll("clients");

      const clientsIds = clients.map((client) => client.id);
      const clientsParams = (
        await dbHelper.getAllDataFromCollectionWithAll("parametres")
      )?.filter((params) => clientsIds.includes(params.client_id));

      clients = clients.map((client) => {
        const params = clientsParams.find(
          (params) => params.client_id === client.id,
        );

        return {
          ...client,
          modules: getClientModules(params),
        };
      });
    } else if (user?.client_id) {
      const client = await dbHelper.getDocFromCollection(
        "clients",
        user.client_id,
      );
      if (!client) return;
      if (
        client.group_id &&
        user.role === USER_ROLES.GROUP_ADMIN &&
        client.group_id === user.group_id
      )
        clients = await getClientGroupList(client.group_id);
      else clients = [client];
    }

    clientsList.value = _.orderBy(clients, "name");

    incrementAppValue(5);
  }
  async function loadPerimeters(user: User) {
    const {client_id, default_sector: saved_default_sector} = user;
    if (!client_id) return false;

    loggerHelper.time("perimeters firestore");

    const sectors = await dbHelper.getAllDataFromCollectionWithAll("sectors", {
      where: [{field: "client_id", value: client_id, compare: "=="}],
    });
    const perimeterResults: Perimeters = _.groupBy(sectors || [], "collection");
    levelCorresp.forEach((level) => {
      const {collection} = level;
      const data = perimeterResults[collection] || [];

      return _.set(
        perimeterResults,
        collection,
        getSortedPerimeters(
          data.filter(
            (x) =>
              !levelCorresp.some(
                (sublevel) =>
                  sublevel.level < level.level &&
                  x[sublevel.type + "_id"] &&
                  !perimeterResults[sublevel.collection].some(
                    (y) => y.id === x[sublevel.type + "_id"],
                  ),
              ),
          ),
        ),
      );
    });

    loggerHelper.timeEnd("perimeters firestore");

    const isSavedSectorOfCurrentClient =
      saved_default_sector?.collection &&
      perimeterResults[saved_default_sector?.collection]?.some(
        (p: any) => p.id === saved_default_sector.id,
      );
    const defaultSector = isSavedSectorOfCurrentClient
      ? (saved_default_sector as Sector)
      : perimeterResults?.sites?.[0];
    perimeters.value = perimeterResults;
    computeBreadcrumbs({perimeters: perimeterResults, defaultSector});

    incrementAppValue(5);
    return perimeterResults;
  }
  async function loadOperators(clientId: string) {
    const tags = await genericLoad({collection: "operateurs", clientId});

    operators.value = tags;

    incrementAppValue(5);
  }
  async function loadMachines(clientId: string) {
    const tags = await genericLoad({collection: "machine_tags", clientId});

    // We remove assignations if sector doesn't exist anymore
    const stationIds = unref(stations).map(({id}) => id);
    machines.value = tags.map((tag) => {
      if (!tag.assignations || !tag.assignations.length) return tag;
      const assignations = tag.assignations.filter(({id}) =>
        stationIds.includes(id),
      );
      return {...tag, assignations};
    });

    incrementAppValue(5);
  }
  async function loadClientParameters(clientId: string) {
    let parametersResults = [];
    let idx = 0;

    const queryRef = dbHelper.createRef("parametres", {
      where: [{field: "client_id", value: clientId, compare: "=="}],
    });
    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const {doc, type} = change;
        const data = doc.data() || {};

        if (type === "added") parametersResults.push(data);
        else if ([data.status, type].includes("removed"))
          parametersResults = parametersResults.filter((x) => x.id !== data.id);
        else {
          parametersResults = parametersResults.map((x) =>
            x.id === data.id ? data : x,
          );
        }
      });

      parametersResults = _.orderBy(
        parametersResults,
        (o) => parseDate(o.created_at || o.updated_at),
        "desc",
      );
      clientParameters.value = parametersResults[0];

      if (!idx) incrementAppValue(5);

      idx += 1;
    });

    addUnsubscribe(unsubscribe);
  }
  async function loadUserParameters(payload: {
    clientId: string;
    userId: string;
  }) {
    const {clientId, userId} = payload;
    const results = await dbHelper.getAllDataFromCollectionWithAll(
      "parametres_user",
      {
        where: [
          {field: "client_id", value: clientId, compare: "=="},
          {field: "user_id", value: userId, compare: "=="},
        ],
      },
    );

    let result: GenericObject = {};

    const sortedResults = _.orderBy(
      results,
      (o) => parseDate(o.created_at),
      "desc",
    );

    if (!sortedResults?.length)
      Object.assign(result, prepareUserParameterBaseObject(clientId, userId));
    else {
      const match = sortedResults.find((doc) => doc.status === "active");

      if (match) result = match;
      else result = sortedResults[0];
    }

    userParameters.value = result;

    incrementAppValue(5);
  }

  async function loadActiveMsd(clientId: string) {
    const activeMsdResult = await fetchActiveMSD({}, clientId);
    activeMsd.value = activeMsdResult;
  }
  async function loadCalendars(clientId: string) {
    let lastSnapshot = null;
    const calendarResults = [];

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const queryRef = dbHelper.createRef("daily_calendar", {
        where: [{field: "client_id", value: clientId, compare: "=="}],
        orderBy: [{field: "__name__"}],
        limit: 500,
        ...(lastSnapshot && {startAfterDocSnap: lastSnapshot}),
      });
      const snapshot = await getDocs(queryRef);
      calendarResults.push(
        ...snapshot.docs
          .map((x: any) => x.data())
          .filter((x) => x.status !== "removed"),
      );
      if (!snapshot.docs.length || snapshot.docs.length < 500) break;
      else lastSnapshot = snapshot.docs[snapshot.docs.length - 1];
    }

    calendars.value = calendarResults;

    incrementAppValue(5);
  }
  async function loadFabriqUsers() {
    const data = await apiClient.value
      .getFabriqUsers()
      .catch((e) => loggerHelper.error(e));
    const users = data?.users;
    if (users?.length) setFabriqUsers(users);
  }
  async function loadVersion() {
    const status = await apiClient.value.getStatus();
    if (status?.version) version.value = status.version;

    setTimeout(() => {
      loadVersion();
    }, refreshTime);
  }
  async function loadMercateamEmployees() {
    const result = await apiClient.value.getMercateamEmployees();
    if (!result?.length) return;

    allMercaEmployees.value = result.map((op: any) => ({
      ...op,
      name: [op.first_name, op.last_name].join(" "),
    }));
  }
  async function loadLastImport(payload?: {
    teamId: string;
    importType: string;
  }): Promise<{import_id?: string}> {
    const {teamId = team.value?.id, importType = "ofs"} = payload ?? {};

    const result = await apiClient.value.postRequest(`/api/imports/last`, {
      import_type: importType,
      team_id: teamId,
    });

    return result ?? {};
  }
  async function loadImportFields() {
    try {
      const team_id = team.value?.id;

      const results = await apiClient.value.postRequest<{field: string}[]>(
        `/api/imports/fields/keys`,
        {team_id},
      );

      return results;
    } catch (error) {
      return null;
    }
  }
  async function loadEnvironment() {
    try {
      const {value} =
        (await dbHelper.getDocFromCollection("public", "environment")) ?? {};

      environment.value = value;
    } catch (err: unknown) {
      // console.log({err});
    }
  }
  async function loadAutoOrga() {
    const results = await apiClient.value.getRequest(
      `/api/sectors/client-auto-orga`,
    );
    planning_sectors.value = results;
  }
  async function genericLoad(payload: {
    collection: string;
    clientId: string;
  }): Promise<any[]> {
    const {collection, clientId} = payload;
    let results = [];

    loggerHelper.time(`${collection} firestore`);
    results = await dbHelper.getAllDataFromCollectionWithAll(collection, {
      where: [
        {
          field: "client_id",
          value: clientId,
          compare: "==",
        },
      ],
    });
    loggerHelper.timeEnd(`${collection} firestore`);

    return results;
  }
  function loadImports(clientId: string) {
    const routes: RouteRecordName[] = [
      "scheduling-piloting",
      "scheduling-planning",
    ];

    const queryRef = dbHelper.createRef("imports", {
      where: [{field: "client_id", value: clientId, compare: "=="}],
      orderBy: [{field: "date"}],
    });

    if (imports.value?.length) imports.value = [];

    let importResults = [];

    const unsubscribe = onSnapshot(queryRef, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const data = doc.data() || {};

        if ([data.status, change.type].includes("removed"))
          importResults = importResults.filter((x: any) => x.id !== data.id);
        else if (change.type == "added") importResults.unshift(data);
        else {
          importResults = importResults.map((x: any) =>
            x.id === data.id ? data : x,
          );
          const {data: import_types, status, import_status} = data;
          if (
            import_types?.includes("ofs") &&
            status === "inserted" &&
            import_status === "job_done" &&
            routes.includes(route.name) &&
            doesSchedulingSimulationFloat.value
          ) {
            if (isSimulationUpdateSnackbarOpened.value) return;

            const forceRefresh = () => window.location.reload();
            isSimulationUpdateSnackbarOpened.value = true;

            _openSnackbar.value({
              timeout: 15 * 60 * 1000,
              message: `${t(`global.attention`)} - ${t(
                `Alert.new_available_import`,
              )}`,
              type: "default",
              actionLabel: t(`global.update_now`),
              action: () => {
                forceRefresh();
              },
              onModelUpdate: (v: boolean) => {
                if (v) return;
                isSimulationUpdateSnackbarOpened.value = false;
              },
            });
          }
        }
      });
      importResults = _.orderBy(
        importResults,
        (o) => parseDate(o.date),
        "desc",
      );
      imports.value = importResults;

      const allTypes = importResults.reduce((acc: any, x: any) => {
        return [...acc, ...(x?.data || [])];
      }, []);

      const importTypeResults = [...new Set(allTypes)];
      importTypes.value = importTypeResults;

      incrementAppValue(5);
    });

    addUnsubscribe(unsubscribe);
  }

  function parseImportType(importFile: {
    auto_prod?: boolean;
    data?: string[];
  }): string {
    if (importFile.auto_prod) return t("FileTypes.auto_prod");

    if (
      clientParameters.value.has_module_stocks &&
      importFile.data?.includes("ofs")
    )
      return t("FileTypes.demand");

    return (importFile.data || [])
      .filter((d) => !!d)
      .map((d) => t(`FileTypes.${d}`))
      .join("; ");
  }

  function prepareUserParameterBaseObject(clientId: string, userId: string) {
    return {
      id: dbHelper.getCollectionId("parametres_user"),
      client_id: clientId,
      collection: activePerim.value.collection,
      created_by: userId,
      updated_by: userId,
      created_at: serverTimestamp(),
      updated_at: serverTimestamp(),
      status: "active",
    };
  }

  async function setUserParameter(parameters: GenericObject) {
    if (!parameters || !userId.value || !clientId.value) return;
    const payload = {
      ...parameters,
      id:
        userParameters.value?.id || dbHelper.getCollectionId("parametres_user"),
      user_id: userId.value,
      client_id: clientId.value,
    };

    try {
      // update remote
      await dbHelper.setDataToCollection(
        "parametres_user",
        payload.id,
        payload,
        true,
      );

      userParameters.value = {
        ...(userParameters.value || {}),
        ...payload,
      };
    } catch (error: unknown) {
      // this._vm.$openDialog(null, "GENERIC_ERROR");
    }
  }
  async function setClientParameter(parameters: GenericObject) {
    if (!parameters) return;
    const id = await dbHelper.getCollectionId("parametres");
    const payload = {
      ...parameters,
      id: clientParameters.value?.id || id,
      user_id: userId.value,
      client_id: clientId.value,
    };

    try {
      await dbHelper.setDataToCollection(
        "parametres",
        payload.id,
        payload,
        true,
      );

      clientParameters.value = {
        ...(clientParameters.value || {}),
        ...payload,
      };
    } catch (error: unknown) {
      // this._vm.$openDialog(null, "GENERIC_ERROR");
    }
  }

  // FIXME: this should be placed in a separate module
  async function getMsdFieldsValues(payload: {
    fields: string[];
    clientId: string;
    parsedPeriod: GenericObject;
    sectorTree: Sector;
    simulation: Simulation;
  }): Promise<Record<string, unknown> | null> {
    try {
      const {parsedPeriod, sectorTree, simulation, fields, clientId} = payload;
      const {startDate, endDate} = parsedPeriod || {};

      const promises = [];
      await asyncForEach(fields, async (model: string) => {
        const params = {
          client_id: clientId,
          startDate,
          endDate,
          sector: sectorTree,
          simulation,
          field: model,
          load_calendar: true,
        };
        promises.push(apiClient.value.getCustomMSDFieldValue(params));
      });
      const results = await Promise.all(promises);
      const values: any = {};
      fields.forEach((model: string, i: number) => {
        const result = _.get(results, i) || {};
        const {field_value} = result;
        values[`pg_${model}`] = field_value;
      });
      return values;
    } catch (error) {
      return null;
    }
  }

  function computeBreadcrumbs(payload: {
    perimeters: Perimeters;
    defaultSector: Sector;
  }) {
    const {perimeters, defaultSector} = payload;
    // We implement the breadcrumbs for the default sector now
    const routeQuery = currentQuery.value || {};

    const isQuerySuitable =
      routeQuery.id && routeQuery.collection && routeQuery.level;
    const isNavSectorOfCurrentClient =
      isQuerySuitable &&
      perimeters[routeQuery.collection + ""].some(
        (p: any) => p.id === routeQuery.id,
      );

    const activeSector = isNavSectorOfCurrentClient
      ? routeQuery
      : defaultSector;
    const sector =
      _.get(perimeters, activeSector?.collection, []).find(
        (x: any) => x.id === activeSector?.id,
      ) ?? _.get(perimeters, ["sites", 0]);

    if (sector?.id) {
      const breadcrumbsResult = [];
      let higherLevelIds = {};
      levelCorresp.forEach((level: any, i: number) => {
        if (i >= sector.level) return;
        const parentId = sector[level.type + "_id"];
        if (parentId) {
          breadcrumbsResult.push({
            ...higherLevelIds,
            ...level,
            id: parentId,
            name: sector[level.type + "_name"],
            disabled: false,
          });
          higherLevelIds = {
            ...higherLevelIds,
            [level.type + "_id"]: sector[level.type + "_id"],
            [level.type + "_name"]: sector[level.type + "_name"],
          };
        }
      });

      breadcrumbsResult.push({
        ...higherLevelIds,
        level: sector.level,
        collection: sector.collection,
        type: levelCorresp.find((x: any) => x.collection === sector.collection)
          ?.type,
        id: sector.id,
        name: sector.name,
        disabled: true,
      });

      breadcrumbs.value = breadcrumbsResult;
    }
  }
  function updateOptim(payload: GenericObject) {
    optim.value = {...optim.value, ...payload};
  }
  async function setBreadcrumbs(payload: unknown[]) {
    breadcrumbs.value = payload;
  }
  function getCtxSectorOrderedCalendars(sector?: Sector): SectorCalendar[] {
    return getSectorOrderedCalendars(
      sector as Sector & {
        client_id: string;
        secteur_id: string;
        updated_at: string;
      },
      calendars.value,
    );
  }

  const theme = computed(() => {
    return userData.value?.light_mode ? "light" : "dark";
  });

  const addNewClientGroup = async (name: string) => {
    const clientGroup = getBaseOplitFirestoreDocument("client_groups");
    const error = await dbHelper.setDataToCollection(
      "client_groups",
      clientGroup.id,
      {
        name,
        ...clientGroup,
      },
      true,
    );
    loadAllClientsGroup();
    return error;
  };

  const updateClientGroup = async (
    collection: string,
    id: string,
    data: GenericObject,
  ) => {
    const dataToUpdate = {
      ...data,
      ...getOplitFirestoreDocumentUpdateUserMetadata(),
    };
    const error = await dbHelper.setDataToCollection(
      collection,
      id,
      dataToUpdate,
      true,
    );
    const constraints = {
      where: [
        {
          field: "group_id",
          value: id,
          compare: "==",
        },
      ],
    };
    for (const property in data) {
      if (property === "status" && data[property] === "removed") {
        await dbHelper.updateAllDataFromCollectionWithAll(
          "clients",
          constraints,
          {group_id: null},
        );
        await dbHelper.updateAllDataFromCollectionWithAll(
          "users",
          constraints,
          {group_id: null},
        );
      }
    }

    loadAllClientsGroup();
    return error;
  };
  /**
   * FIXME
   * we cannot access the provided openSnackbar from this store
   * the reason behind has not yet been discovered
   * this computed is a workaround to be able to use the app method from this file
   */
  const _openSnackbar = computed<(_d: Record<string, any>) => void>(() => {
    const activePinia = getActivePinia() as Pinia & {_a: App};
    return activePinia._a.config.globalProperties.$openSnackbar;
  });

  function getCtxNextAvailableDate(
    date: string,
    isMovingToPast = false,
    sector?: Sector,
  ) {
    const sortedUniqCalendars = getCtxSectorOrderedCalendars(sector);

    return getNextAvailableDate(date, sortedUniqCalendars, {
      isMovingToPast,
      isSorted: true,
    });
  }

  function hasOperatorCompetencyForMachine(
    operator: Operator,
    machine: Machine,
  ) {
    if (!operator || !machine) return false;

    const machineParentSectors = stations.value.filter(({machine_tags}) =>
      machine_tags?.some(({id}) => id === machine.id),
    );

    if (machineParentSectors.length === 0) return false;

    const machineParentSectorsMercateamOperatorsIDs = machineParentSectors
      .filter(({mercateam_id}) => mercateam_id)
      .map(
        ({mercateam_id}) => mercateamCompetenciesBySector.value[mercateam_id],
      )
      .flat()
      .filter(Boolean);

    if (machineParentSectorsMercateamOperatorsIDs.includes(operator.id))
      return true;

    const fullOperator =
      operator.competencies?.length > 0
        ? operator
        : operators.value.find(({id}) => id === operator.id);

    if (!fullOperator?.competencies) return false;

    const machineParentSectorsIDs = machineParentSectors.map(({id}) => id);

    return fullOperator.competencies.some(({id}) =>
      machineParentSectorsIDs.includes(id),
    );
  }

  function getSortedOperatorsByMachineCompetency(
    machine: Machine,
    operatorsList = operators.value,
  ) {
    return [...operatorsList].sort((opA, opB) => {
      const hasCompetencyA = hasOperatorCompetencyForMachine(opA, machine);
      const hasCompetencyB = hasOperatorCompetencyForMachine(opB, machine);

      return hasCompetencyA === hasCompetencyB
        ? opA.name.localeCompare(opB.name)
        : hasCompetencyA
        ? -1
        : 1;
    });
  }

  function getFullSectorFromSectorLikeObject(
    sectorLikeObject: SectorLike,
  ): SectorLike {
    if (!sectorLikeObject) return;

    const [sector] =
      stationsAndMachinesTagsGroupedById.value[
        sectorLikeObject.secteur_id ?? sectorLikeObject.id
      ] || [];

    return sector;
  }

  return {
    currentQuery,
    apiClient,
    userId,
    userData,
    clientsList,
    teams,
    users,
    perimeters,
    mercateamSectors,
    operators,
    machines,
    clientParameters,
    userParameters,
    events,
    macroEvents,
    simulations,
    simulation,
    calendars,
    imports,
    importTypes,
    activeMsd,
    status,
    error,
    breadcrumbs,
    globalLoader,
    version,
    allMercaEmployees,
    fabriqUsers,
    isDevEnv,
    optim,
    pgRefresh,
    disablePgRefresh,
    pgTrigger,
    isAppLoading,
    appLoadingValue,
    isScheduling,
    isSchedulingFullScreen,
    realCapacity,
    freezeOverlays,
    redirect,
    mfaResolver,
    resetPasswordNonce,
    resetPasswordNonceFromEmail,
    environment,
    theme,
    arePDPSimulationUpdatesDisabled,
    hasMercateam,
    activeSector,
    activePerim,
    sectorTree,
    isParent,
    team,
    teamSimulations,
    mergedParameters,
    variables,
    colors,
    stations,
    hasAutoOrga,
    hasStock,
    hasNomenclature,
    hasFullAccessPDP,
    hasFullAccessScheduling,
    hasFullAccessStock,
    pgSse,
    siteId,
    clientId,
    highestSector,
    clientConfiguration,
    cleanMachineTags,
    clientGroups,
    doesSchedulingSimulationFloat,
    hasOnlySideModules,
    defaultRouteName,
    isSimulationUpdateSnackbarOpened,
    getCtxSectorOrderedCalendars,
    incrementAppValue,
    removeUser,
    setFabriqUsers,
    addUnsubscribe,
    clearUnsubscribes,
    addNewClientGroup,
    getClientModules,
    loadApiClient,
    loadTeams,
    loadUsers,
    loadClientsList,
    loadPerimeters,
    loadOperators,
    loadMachines,
    loadClientParameters,
    loadUserParameters,
    loadActiveMsd,
    loadCalendars,
    loadFabriqUsers,
    loadVersion,
    loadMercateamEmployees,
    loadLastImport,
    loadImportFields,
    loadEnvironment,
    loadAutoOrga,
    loadImports,
    parseImportType,
    setUserParameter,
    setClientParameter,
    getMsdFieldsValues,
    computeBreadcrumbs,
    updateOptim,
    setBreadcrumbs,
    loadAllClientsGroup,
    getClientGroupId,
    updateClientGroup,
    getCtxNextAvailableDate,
    hasFullAccessCustomerInterfaceProviderSide,
    hasFullAccessCustomerInterfaceCustomerSide,
    hasOplitDateAccess,
    mercateamCompetenciesBySector,
    hasOperatorCompetencyForMachine,
    getSortedOperatorsByMachineCompetency,
    getFullSectorFromSectorLikeObject,
    teamIdBySector,
    stationsAndMachinesTags,
    stationsAndMachinesTagsGroupedById,
    clearUnsubscribe,
  };
});
