import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {environment} from "@/firebase/init";

const SGS_CLIENT_IDS = [
  // Saint Gobain - Sully
  "QR4UAKY1E4uLZhIKFTah",
  // Saint Gobain - Sully V2
  "nr8BSWiomTTdzIG2WNkq",
];

export const useDomain = () => {
  const mainStore = useMainStore();
  const {clientId} = storeToRefs(mainStore);

  const clientsFromDomain = computed<{
    name: string;
    client_ids: string[];
  } | null>(() => {
    const hostname = window.location.hostname;

    if (hostname.includes(`sgs-${environment}.oplit.fr`)) {
      return {
        name: "SGS",
        client_ids: SGS_CLIENT_IDS,
      };
    }

    if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
      return {
        name: "local",
        client_ids: SGS_CLIENT_IDS,
      };
    }

    return null;
  });

  const isSGSDomain = computed<boolean>(() => {
    if (!clientsFromDomain.value) return false;
    if (!clientId.value)
      return ["SGS", "local"].includes(clientsFromDomain.value.name);

    return (
      ["SGS", "local"].includes(clientsFromDomain.value.name) &&
      clientsFromDomain.value.client_ids.includes(clientId.value)
    );
  });

  return {clientsFromDomain, isSGSDomain};
};
