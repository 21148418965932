<script setup lang="ts">
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {OF_STATUS} from "@/config/constants";
import {OperationStatus} from "@/interfaces";

interface OperationStatusItemProps {
  status: OperationStatus;
  append?: string;
}

const props = defineProps<OperationStatusItemProps>();

const {t} = useI18n();

const colorsByStatus = {
  [OF_STATUS.AVAILABLE]: {
    bg: "green-50",
    dot: "green-500",
    text: "green-900",
  },
  [OF_STATUS.DONE]: {
    bg: "grey-200",
    dot: "grey-400",
    text: "grey-600",
  },
  [OF_STATUS.ON_GOING]: {
    bg: "orange-50",
    dot: "orange-500",
    text: "orange-900",
  },
  operation: {
    bg: "purple-50",
    dot: "purple-500",
    text: "purple-800",
  },
  [OF_STATUS.PENDING]: {
    bg: "purple-50",
    dot: "purple-500",
    text: "purple-800",
  },
  [OF_STATUS.POF]: {
    bg: "blue-50",
    dot: "blue-500",
    text: "blue-800",
  },
};

const colors = computed<{bg?: string; dot?: string; text?: string}>(() => {
  if (!colorsByStatus[props.status]) return {};

  return colorsByStatus[props.status];
});
</script>

<template>
  <div
    :class="{
      [`bg-${colors.bg}`]: colors.bg,
      [`text-${colors.text}`]: colors.text,
    }"
    class="flex-1 py-2 px-4 fd-flex-center justify-center gap-4 cursor-pointer"
  >
    <span
      class="html-icon__circle"
      :style="`--color: rgb(var(--v-theme-${colors.dot}))`"
    />

    <span class="fbody-2">
      {{ t(`operation.status.${status}`) }}

      <template v-if="append">
        {{ append }}
      </template>
    </span>
  </div>
</template>
