import {AsyncResult, Result} from "@oplit/shared-module";
import {
  AuthError,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";

const getSGSProvider = () => {
  const provider = new OAuthProvider("oidc.sgs_oplit_oidc");
  provider.addScope("openid");
  provider.addScope("profile");

  return provider;
};

export async function signInWithSGS(): AsyncResult<UserCredential, AuthError> {
  const provider = getSGSProvider();
  const auth = getAuth();

  try {
    const result = await signInWithPopup(auth, provider);
    return Result.success(result);
  } catch (error) {
    return Result.error(error);
  }
}
