import {
  type NavigationGuardNext,
  type RouteLocation,
  type Router,
} from "vue-router";
import {storeToRefs} from "pinia";
import {getAuth} from "firebase/auth";
import {unref} from "vue";
import {LOCAL_STORAGE} from "@/config/constants";
import {
  handleNewUserDataNavigation,
  handleRouteUserPermissions,
  handleRouteClientPermissions,
  routeRequires,
} from "@/router/utils";
import {useMainStore} from "@/stores/mainStore";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {useConnexionUser} from "@/composables/useConnexionUser";
import loggerHelper from "@/tscript/loggerHelper";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {GLOBAL_TABS_QUERY_PARAMETER} from "@/config/constants";

export function registerGlobalGuards(router: Router): void {
  async function beforeEachHanler(
    to: RouteLocation,
    _from: RouteLocation,
    next: NavigationGuardNext,
  ): Promise<void> {
    loggerHelper.log({to, _from});
    const query = {...(to.query || {})};
    const mainStore = useMainStore();
    const {setSelectedTab} = useGlobalTabs();
    const {
      userData,
      clientParameters,
      redirect,
      isScheduling,
      currentQuery,
      defaultRouteName,
      apiClient,
      isAppLoading,
    } = storeToRefs(mainStore);

    const {reloadUser} = useConnexionUser();
    currentQuery.value = query;

    if (!isAppLoading.value && to.name !== _from.name)
      apiClient.value.cancelOngoingRequests();

    // check to see if route has auth guard
    if (
      [
        "requiresAuth",
        "requiresAdmin",
        "requiresSuperAdmin",
        "requiresScheduling",
        "requiresPDP",
        "requiresCustomerInterface",
      ].some((metaKey: string): boolean => routeRequires(to, metaKey))
    ) {
      // check auth state of user
      const user = getAuth().currentUser;

      if (!user) {
        redirect.value = {path: to.path, query};
        return next({name: "login", query});
      } else if (!userData.value?.id) await reloadUser(user);

      const permissionsStore = usePermissionsStore();
      await permissionsStore.permissionsLoadingPromise;
      const hasUserPermissions = handleRouteUserPermissions(
        to,
        permissionsStore.currentPermissions,
      );
      const hasClientPermissions = handleRouteClientPermissions(
        to,
        unref(clientParameters),
      );

      let queryOrUndefined: Record<string, any> | undefined;
      // //This is a special condition to allow ADMIN users to easily change client when navigating from a link
      // if (
      //   query.cid &&
      //   query.cid !== userData.value?.client_id &&
      //   userData.value?.role === USER_ROLES.ADMIN
      // )
      //   await changeClient(query.cid as string);
      // else
      if (
        userData.value?.client_id &&
        (!query.cid || query.cid !== userData.value?.client_id)
      ) {
        //this makes sure to save the client_id in the URL query
        query.cid = userData.value.client_id;
        queryOrUndefined = {...to, query}; //to avoid redundant navigation, we define this object only once, and leave it undefined the other times
      }

      const gtab = to.query[GLOBAL_TABS_QUERY_PARAMETER];
      if (gtab) {
        const selectedTab = typeof gtab === "string" ? gtab : gtab[0];
        setSelectedTab(+selectedTab, {
          shouldNotUpdateRoute: true,
          shouldNotCancelRequests: true,
        });
      }

      if (!hasUserPermissions || !hasClientPermissions)
        return next({name: unref(defaultRouteName)});

      if (redirect.value?.path) redirect.value = {};
      if (
        ["requiresAdmin", "requiresSuperAdmin", "requiresScheduling"].every(
          (metaKey: string): boolean => !routeRequires(to, metaKey),
        )
      ) {
        if (
          userData.value?.has_scheduling &&
          userData.value?.has_production_planning
        )
          isScheduling.value = false;

        // User is authorized. Proceed to route
        return next(queryOrUndefined);
      }

      const localUserData =
        localStorage.getItem(LOCAL_STORAGE.USER_DATA) || "{}";
      /**
       * we fallback the user data to a locally stored object to handle the
       * multiple requirements routes & the async loading of userData
       * the values are synchronized
       */
      const finalUserData = userData.value || JSON.parse(localUserData);

      if (finalUserData?.id !== user.uid)
        return next({name: unref(defaultRouteName), query});

      const redirectRouteProps = handleNewUserDataNavigation(finalUserData, to);

      if (redirectRouteProps) return next({...redirectRouteProps, query});

      // setting the "is_scheduling" state from the route requirements
      if (
        finalUserData?.has_scheduling &&
        finalUserData?.has_production_planning
      )
        isScheduling.value = routeRequires(to, "requiresScheduling");

      next(queryOrUndefined);
    } else if (to.name === "login") {
      // check auth state of user
      const user = getAuth().currentUser;
      if (user) {
        if (redirect.value?.path) redirect.value = {};
        // user is signed in. Redirect to login
        next({name: unref(defaultRouteName), query});
      } else {
        // No user is signed in. Redirect to login
        next();
      }
    } else {
      if (to.matched.length < 1)
        return next({name: unref(defaultRouteName), query});

      if (redirect.value?.path) redirect.value = {};
      // if route is not guarded by auth, proceed
      next();
    }
  }

  router.beforeEach(beforeEachHanler);
}

// error handler
export function registerErrorGuards(router: Router): void {
  /**
   * OPL-7778
   * handles cached vs deployed chunks mismatch errors
   */
  function reloadPageOnBuildChange(error: Error, to: RouteLocation): void {
    if (
      [
        "Failed to fetch dynamically imported module",
        "Unable to preload CSS",
      ].some((err) => error.message.includes(err))
    ) {
      const route = router.resolve(to);
      if (!route.name)
        window.location.reload(); // tried to access a non-existing route, will redirect to home
      else window.location.assign(to.fullPath); // reloads & updates URL
    }
  }

  // registration
  router.onError(reloadPageOnBuildChange);
}
