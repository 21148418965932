import {AsyncResult, Result} from "@oplit/shared-module";
import {
  AuthError,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
} from "firebase/auth";

const getAuth0Provider = () => {
  const provider = new OAuthProvider("oidc.oplit-staging-oauth0");
  provider.addScope("openid");
  provider.addScope("profile");

  return provider;
};

export async function signInWithAuth0(): AsyncResult<
  UserCredential,
  AuthError
> {
  const provider = getAuth0Provider();
  const auth = getAuth();

  try {
    const result = await signInWithPopup(auth, provider);
    return Result.success(result);
  } catch (error) {
    return Result.error(error);
  }
}
