<script setup lang="ts">
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

interface IsSavingSpinnerProps {
  isChanging?: boolean;
  isSaving?: boolean;
  isSelected?: boolean;
  hasError?: boolean;
}

const props = defineProps<IsSavingSpinnerProps>();

const {variables} = useMainStore();
const {t} = useI18n();
const schedulingStore = useSchedulingStore();
const {localSchedulingParams, selectedSimulation} =
  storeToRefs(schedulingStore);

const isChangingInSimulation = computed(() => {
  return (
    props.isChanging &&
    localSchedulingParams.value.simulation_id === selectedSimulation.value?.id
  );
});
</script>

<template>
  <v-progress-circular
    v-if="isChangingInSimulation || isSaving"
    size="12"
    color="newPrimaryDark1"
    style="flex: 0 0 12px"
    indeterminate
  />

  <vue-feather
    v-else-if="hasError"
    tag="div"
    type="x"
    size="16"
    :stroke="variables.newPinkRegular"
    v-tooltip="t('operation.failed_saving')"
  />
</template>

<style scoped lang="scss"></style>
