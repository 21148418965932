<template>
  <v-lazy
    :min-height="35"
    @update:model-value="loadPostRowDisplay"
    class="gantt-row__lazy-wrapper"
  >
    <div class="gantt-row--wrapper">
      <div class="gantt-row gantt-row__sector">
        <GanttRowPrefixCalendar
          :entity="superEntity"
          :hide-progress-rate="hideProgressRate"
          :loading="loading"
          :is-synthetic-view="isSyntheticView"
          :show-operations="showOperations"
          :daily-load-rates="dailyLoadRates"
          @toggle-operations="onToggleOperations"
        />

        <GanttRowHeader
          :daily-load-rates="dailyLoadRates"
          :hide-daily-load-rate="isList"
          :diagram-left-scroll="diagramLeftScroll"
        />

        <div
          v-if="
            !isFirstAvailableOpVisible && !!formattedMinPlannedDate && isList
          "
          :class="
            isFirstAvailableOpInThePast
              ? 'position-to-left'
              : 'position-to-right'
          "
          class="gantt-row--cell-unfolding-button"
          data-testid="gantt-row--cell-unfolding-button"
        >
          <UnfoldingButton
            :unfolding-direction="
              isFirstAvailableOpInThePast ? 'right' : 'left'
            "
            :icon="isFirstAvailableOpInThePast ? 'arrow-left' : 'arrow-right'"
            @click.stop="changePeriodStartDate"
          >
            {{ formattedMinPlannedDate }}
          </UnfoldingButton>
        </div>
      </div>

      <template v-if="showOperations && superEntity.operations.length > 0">
        <v-lazy
          v-for="op in processedOperations"
          :key="op.op_id"
          :min-height="40"
        >
          <div
            class="gantt-row gantt-row__operation"
            v-click-outside="(e) => onClickOutsideCard(e, op)"
          >
            <div
              class="gantt-row__operation-header text-14"
              @click="() => $openOFSidebar(op)"
            >
              <span
                v-tooltip="getOperationComputedDisplay(op).header"
                class="text-ellipsis semi-bold d-block max-w-200px text-grey-900"
              >
                {{ getOperationComputedDisplay(op).header }}
              </span>

              <span
                v-tooltip="getOperationComputedDisplay(op).prefix"
                class="text-ellipsis text-grey-700 flex-1"
              >
                {{ getOperationComputedDisplay(op).prefix }}
              </span>

              <OperationComponentShortage :operation="op" />

              <vue-feather
                :stroke="variables['grey-700']"
                type="maximize-2"
                class="d-flex justify-center"
                style="flex: 0 0 24px"
                size="16px"
              />
            </div>

            <div class="gantt-row-calendar__cell-wrapper">
              <div
                v-for="(day, dayIndex) in ganttDaysArray"
                :key="getGanttRowCellKey(day, dayIndex)"
                :class="CSS_SCHEDULING_GANTT_CELL_CLASS"
                :style="getDayStyle(day, dayIndex)"
                class="gantt-row--cell"
              >
                <v-skeleton-loader v-if="loading" boilerplate type="button" />
                <template v-else>
                  <v-tooltip
                    v-for="operation in getOperationsPerDay(
                      day,
                      dayIndex,
                      op.op_id,
                    )"
                    :key="`${getGanttOperationKey(operation)}-${
                      operation.day_date
                    }`"
                    content-class="gantt-row--cell-tooltip shadow-neutral-lg"
                    location="top"
                  >
                    <template #activator="{props}">
                      <div
                        v-bind="props"
                        :class="getOperationClasses(operation)"
                        :style="getOperationStyle(operation, dayIndex)"
                        class="gantt-row--cell-operation shadow-neutral-sm"
                        @click="() => onOperationClick(operation)"
                      >
                        <div class="gantt-row__cell-content text-14">
                          <strong class="text-grey-700 d-inline-block">
                            {{
                              getReadableImportParsingRuleValue(operation.of_id)
                            }}
                          </strong>

                          <span>
                            {{ operationComputedQuantity(operation).txt }}
                          </span>

                          <OperationStatus :operation="operation" disabled />
                        </div>
                      </div>
                    </template>

                    <SchedulingOperationCard
                      :id="operation.op_id"
                      :operation="operation"
                      :order="dayIndex + 1"
                      :is-synthetic-view="isSyntheticView"
                      :has-done-ops="hasDoneOps"
                      compact
                    />
                  </v-tooltip>
                </template>
              </div>
            </div>
          </div>
        </v-lazy>
      </template>
    </div>
  </v-lazy>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  ref,
  unref,
  onMounted,
  onUnmounted,
  watch,
} from "vue";
import {PropType} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";

import {UnfoldingButton} from "@/components/Global";
import SchedulingOperationCard from "@/components/Scheduling/Operations/SchedulingOperationCard.vue";
import OperationStatus from "../Operations/OperationStatus.vue";
import GanttRowPrefixCalendar from "./GanttRowPrefixCalendar.vue";
import OperationComponentShortage from "@/components/Scheduling/Operations/OperationComponentShortage.vue";
import {
  computedDisplayFn,
  pgOpsMapFn,
  filterSchedulingOperations,
  getGanttOperationKey,
  onClickOutsideSchedulingOperation,
  isSelectedOperation,
  getGanttInterestKey,
  getColorCategoryClass,
  schedulingStatusColorBg,
  hasArrayDoneOperations,
} from "@/tscript/utils/schedulingUtils";
import {
  getOperationStatus,
  getReadableImportParsingRuleValue,
  DEFAULT_PAST_START_DATE,
} from "@oplit/shared-module";
import {
  CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
  CSS_OPERATION_CARD_SELECTED_CLASS,
  CSS_SCHEDULING_GANTT_CELL_CLASS,
  DATE_DEFAULT_FORMAT,
  OF_STATUS,
  USER_PARAMETERS_SELECTED_SECTORS_KEY,
} from "@/config/constants";

import type {
  GanttableEntity,
  HTMLElementStyleObject,
  OperationsActionsMenuSelectedOps,
  SchedulingFilter,
  SchedulingOperation,
  SectorLike,
  VueClassesArray,
} from "@/interfaces";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";
import {useParameterStore} from "@/stores/parameterStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGanttStore} from "@/stores/ganttStore";
import {usePGSubscriptions} from "@/composables/pgComposable";
import useComputeQuantityUnit from "@/composables/useComputeQuantityUnit";
import GanttRowHeader from "./GanttRowHeader.vue";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";

export default defineComponent({
  name: "gantt-row",
  components: {
    SchedulingOperationCard,
    GanttRowPrefixCalendar,
    UnfoldingButton,
    GanttRowHeader,
    OperationStatus,
    OperationComponentShortage,
  },
  props: {
    entity: {
      type: Object as PropType<GanttableEntity>,
      required: true,
    },
    hideProgressRate: {type: Boolean, default: false},
    filters: {type: Array as PropType<SchedulingFilter[]>, default: () => []},
    filterDoneOps: {type: Boolean, default: false},
    isList: {type: Boolean, default: false},
    isPiloting: {type: Boolean, default: false},
    isSyntheticView: {type: Boolean, default: false},
    isFirst: {type: Boolean, default: false},
    selectedDelayMesh: {
      type: Object as PropType<{label?: string; mesh?: string; value?: number}>,
      default: () => ({}),
    },
    isLast: {type: Boolean, default: false},
    isActive: {type: Boolean, default: false},
    diagramLeftScroll: {type: Number, default: 0},
  },
  emits: ["operations-loaded", "operations-unloaded", "toggle-machine-center"],
  setup(props, {emit}) {
    const getGanttIsPastDelayShown = inject<boolean>(
      "getGanttIsPastDelayShown",
    );

    const mainStore = useMainStore();
    const {
      machines,
      userData,
      calendars,
      apiClient,
      environment,
      pgRefresh,
      clientParameters,
      stations,
      teamIdBySector,
      userParameters,
      variables,
    } = storeToRefs(mainStore);
    const {getCtxSectorOrderedCalendars, getCtxNextAvailableDate} = mainStore;

    const hasBeenWithinViewport = ref(false);

    const {
      parametersSchedulingDisplay,
      doesClientHaveShiftPlanning,
      clientFirstShift,
      parametersAllSchedulingDisplaysByTeamId,
    } = storeToRefs(useParameterStore());
    const {setTotalLoadBySectorFromOperations, getSectorsDailyLoadRatesObject} =
      useSchedulingStore();
    const {
      selectedSimulation,
      schedulingCurrentColorCategory,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      schedulingMachineCentersAndTags,
      totalCapaBySector,
      messagesByOfId,
      pgOpsModifications,
    } = storeToRefs(useSchedulingStore());
    const {loadGanttOperations, getStartDateForDelay, getDailyColorCssVar} =
      useGanttStore();
    const {
      ganttMesh,
      ganttHideWeekend,
      ganttIsSyntheticView,
      ganttEntitiesToReload,
      ganttPeriod,
      ganttDaysArray,
      ganttPeriodStartDate,
      ganttDateUpdate,
      ganttSectorCapacities,
    } = storeToRefs(useGanttStore());
    const {pg_init, pg_subscribe} = usePGSubscriptions();
    const {getSelectedOperationDetail, operationComputedQuantity} =
      useComputeQuantityUnit();
    const {toggleSelectedOperations} = useSelectedOperations();
    const {
      selectedOperations,
      selectableOperations,
      tempUpdatedSelectedOperations,
    } = storeToRefs(useSelectedOperations());

    const pg_ops = ref<SchedulingOperation[]>([]);
    const isMenuShown = ref(false);
    const showOperations = ref(false);

    const messages = computed(() => {
      const {of_id} = (props.entity || {}) as {of_id: string};
      if (!of_id) return [];
      return messagesByOfId.value[of_id] || [];
    });

    const superEntity = computed(() => {
      const min_planned_date = pg_ops.value.find(
        ({min_planned_date}) => !!min_planned_date,
      )?.min_planned_date;
      const max_planned_date = pg_ops.value.find(
        ({max_planned_date}) => !!max_planned_date,
      )?.max_planned_date;
      return {
        messages: messages.value,
        ...(props.entity as GanttableEntity),
        operations: pg_ops.value,
        ...(min_planned_date && {min_planned_date}),
        ...(max_planned_date && {max_planned_date}),
      };
    });

    const processedOperations = computed<SchedulingOperation[]>(() => {
      const tempUpdatedSelectedOperationsByOpId = _.groupBy(
        tempUpdatedSelectedOperations.value,
        "op_id",
      );

      const ops = Array.from(
        superEntity.value.operations || [],
        (operation: SchedulingOperation) => {
          return {
            ...(tempUpdatedSelectedOperationsByOpId[operation.op_id]?.[0] ||
              operation),
            /**
             * we need to have a value defined for the op_duration for the sort comparison to work
             * the fallback value shouldn't matter since we use the css max() for the operation width
             */
            op_duration: operation.op_duration ?? 24,
          };
        },
      );

      const filteredOps = filterSchedulingOperations(ops, props.filters, {
        with_pending_order:
          clientParameters.value.has_ordered_pending_op_status,
      });

      return sortOperations(filteredOps);
    });
    const processedOperationsByDayDateAndOpId = computed(() => {
      const firstCalendarDate = moment(ganttPeriod.value[0]).format(
        DATE_DEFAULT_FORMAT,
      );
      const isPastDelayShown = unref(getGanttIsPastDelayShown);

      /**
       * if past is shown, we ensure that the operations starting
       * on the date bound to the past column are preserved
       */
      const isDateBeforeFirstCalendarDate = (date: string): boolean =>
        isPastDelayShown ? date <= firstCalendarDate : date < firstCalendarDate;

      return _.chain(processedOperations.value)
        .groupBy(({day_date, new_date, min_date, op_dates}) => {
          // removes eventual time from grouping
          let date = moment(new_date ?? day_date).format(DATE_DEFAULT_FORMAT);

          /**
           * if the first operation's `day_date` is past and if it should span
           * over the current period, we set its bound date to the first date of the period
           */
          if (
            min_date < firstCalendarDate &&
            op_dates.includes(firstCalendarDate)
          )
            date = firstCalendarDate;

          return isDateBeforeFirstCalendarDate(date)
            ? DEFAULT_PAST_START_DATE
            : date;
        })
        .mapValues((operations) => _.groupBy(operations, "op_id"))
        .value();
    });

    const capacities = computed(() =>
      (totalCapaBySector.value[superEntity.value.secteur_id] || [])
        .filter(Boolean)
        .reduce(
          (acc: Record<string, number>, {day_date, daily_capa}) => ({
            ...acc,
            [day_date]: (acc[day_date] || 0) + +daily_capa,
          }),
          {},
        ),
    );

    const dailyLoadRates = computed<{
      [day: string]: {
        value: string | number;
        load: number;
        capa: number;
        unit: string;
        loadDetail: {of_id: string; load: number}[];
        mappedOpsWithDailyLoad: {of_id: string; load: number}[];
      };
    }>(() =>
      getSectorsDailyLoadRatesObject(
        [(superEntity.value as SectorLike).secteur_id],
        ganttDaysArray.value,
        Object.values(superEntity.value.operations).flat(),
        {isPastDelayShown: unref(getGanttIsPastDelayShown)},
      ),
    );

    const selectedOps = computed((): OperationsActionsMenuSelectedOps => {
      if (!selectedOperations.value.length) return {};

      return selectedOperations.value.reduce(
        (acc, operation) => ({
          ...acc,
          [operation.op_id]: getSelectedOperationDetail(operation),
        }),
        {} as OperationsActionsMenuSelectedOps,
      );
    });

    function sortOperations(
      operations: SchedulingOperation[],
    ): SchedulingOperation[] {
      return _.orderBy(
        operations,
        [
          (o: SchedulingOperation) => o.new_date || o.day_date,
          (o: SchedulingOperation) => +(o.op_order ?? Infinity),
          (o: SchedulingOperation) => +(o.op_duration || 0),
          (o: SchedulingOperation) => o.initial_date,
        ],
        ["asc", "asc", "desc", "asc"],
      );
    }

    function changePeriodStartDate(): void {
      const minPlannedDate = (superEntity.value as {min_planned_date: string})
        .min_planned_date;

      ganttPeriodStartDate.value = {
        startDate: minPlannedDate,
        monthDate: minPlannedDate,
        bypassSlipperyView: true,
      };
    }

    const getGanttRowCellKey = (day, dayIndex) => {
      if (dayIndex) return `cell-${day}`;
      return `cell-${day}-${getGanttIsPastDelayShown}`;
    };

    const ofIdsToExport = computed<string[]>(() => {
      const hasActiveFilters = props.filters.some(
        ({selectedItem}) => selectedItem.length,
      );
      if (!hasActiveFilters && props.isList) return [];
      return _.uniqBy(unref(processedOperations), (op) => op.of_id).map(
        ({of_id}) => of_id,
      );
    });
    function getBackgroundColorFromStatus(operation: SchedulingOperation) {
      const status = getOperationStatus(operation);
      if (status === OF_STATUS.DONE) return "bg-newLightGrey";
      return `bg-${schedulingStatusColorBg(status)}`;
    }
    function onOperationClick(operation: SchedulingOperation) {
      toggleSelectedOperations([operation]);

      selectableOperations.value = [...processedOperations.value];
    }
    function onToggleOperations(sectorId: string) {
      showOperations.value = !showOperations.value;
      emit("toggle-machine-center", sectorId);
    }

    watch(
      () => [
        processedOperations.value,
        userParameters.value.include_filters_in_load_rate,
      ],
      ([newFilteredOps, newIncludeFiltersInLoadRate]) => {
        if (props.isList) return;

        const operations = newIncludeFiltersInLoadRate
          ? newFilteredOps
          : pg_ops.value;

        setTotalLoadBySectorFromOperations(
          operations.filter(({op_status}) => op_status !== OF_STATUS.DONE),
          props.entity as {secteur_id: string},
        );
      },
    );

    onMounted(() => {
      showOperations.value =
        userParameters.value[
          USER_PARAMETERS_SELECTED_SECTORS_KEY
        ].toggled?.includes((props.entity as SectorLike).secteur_id) || false;
    });

    onUnmounted(() => {
      if (props.isList) return;
      const id = (props.entity as {secteur_id: string})?.secteur_id;
      calendarOfIdsToExport.value = _.omit(unref(calendarOfIdsToExport), id);
      pilotingOfIdsToExport.value = _.omit(unref(pilotingOfIdsToExport), id);
    });

    return {
      hoveredOperation: ref<SchedulingOperation>(null),
      showAll: ref<boolean>(true),
      /**
       * contains operations linked to the OF (should probably be fetched before ?)
       */
      pg_ops,
      /**
       * contains user messages for this OF (to display the "comment" icon inside the prefix)
       */
      messages,
      loading: ref<boolean>(false),
      // setting this to `true` will prevent the visible loading effects from occurring when fetching load
      silentLoading: ref<boolean>(false),
      OF_STATUS,
      CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS,
      machines,
      userData,
      calendars,
      apiClient,
      pgRefresh,
      parametersSchedulingDisplay,
      selectedSimulation,
      schedulingCurrentColorCategory,
      loadGanttOperations,
      getStartDateForDelay,
      ganttMesh,
      ganttHideWeekend,
      ganttIsSyntheticView,
      getGanttIsPastDelayShown,
      ganttEntitiesToReload,
      ganttPeriod,
      ganttDaysArray,
      ganttPeriodStartDate,
      changePeriodStartDate,
      superEntity,
      processedOperations,
      sortOperations,
      getGanttRowCellKey,
      ofIdsToExport,
      calendarOfIdsToExport,
      pilotingOfIdsToExport,
      ganttDateUpdate,
      ganttSectorCapacities,
      environment,
      getCtxSectorOrderedCalendars,
      getCtxNextAvailableDate,
      doesClientHaveShiftPlanning,
      clientFirstShift,
      hasBeenWithinViewport,
      isMenuShown,
      clientParameters,
      getBackgroundColorFromStatus,
      pg_init,
      pg_subscribe,
      stations,
      totalCapaBySector,
      schedulingMachineCentersAndTags,
      teamIdBySector,
      parametersAllSchedulingDisplaysByTeamId,
      selectedOps,
      dailyLoadRates,
      capacities,
      showOperations,
      getReadableImportParsingRuleValue,
      onOperationClick,
      selectedOperations,
      onToggleOperations,
      processedOperationsByDayDateAndOpId,
      toggleSelectedOperations,
      pgOpsModifications,
      selectableOperations,
      variables,
      operationComputedQuantity,
      CSS_SCHEDULING_GANTT_CELL_CLASS,
      getDailyColorCssVar,
    };
  },
  computed: {
    isFirstAvailableOpVisible(): boolean {
      const {superEntity, ganttDaysArray} = this;
      const minPlannedDate = superEntity.min_planned_date;
      return (
        ganttDaysArray.at(0) <= minPlannedDate &&
        minPlannedDate <= ganttDaysArray.at(-1)
      );
    },
    isFirstAvailableOpInThePast(): boolean {
      const {superEntity, ganttDaysArray} = this;
      return superEntity.min_planned_date < ganttDaysArray.at(0);
    },
    formattedMinPlannedDate(): string {
      if (this.superEntity.min_planned_date)
        return moment(this.superEntity.min_planned_date).format("LL");
      return "";
    },
    hasDoneOps(): boolean {
      return hasArrayDoneOperations(this.selectedOperations);
    },
  },
  watch: {
    async pgOpsModifications(modifiedOperations: SchedulingOperation[]) {
      if (!modifiedOperations?.length) return;

      const sectorIds = modifiedOperations.map(({secteur_id}) => secteur_id);

      if (!sectorIds.includes(this.entity.secteur_id)) return;

      this.silentLoading = true;

      await this.loadCharge();

      this.selectableOperations = [...this.superEntity.operations];

      if (!this.showOperations) this.onToggleOperations(this.entity.secteur_id);

      this.silentLoading = false;
    },
    async ganttEntitiesToReload(newV: {
      sectorIds?: string[];
      ofIds?: string[];
    }) {
      const values = (this.isList ? newV.ofIds : newV.sectorIds) || [];
      if (values.includes(this.entity[getGanttInterestKey(this.isList)]))
        await this.loadCharge();

      // FIXME: disabled as per `isDisabledShowAll` explanation
      // this.handleShowAllDisplay(newV);
    },
    async ganttPeriod(newPeriod, oldPeriod) {
      if (!_.isEqual(newPeriod, oldPeriod))
        await this.loadPostRowDisplay(this.isActive);
    },
    async isActive(newBool) {
      if (newBool) this.loadPostRowDisplay(newBool);
      else this.hasBeenWithinViewport = false;
    },
    pg_ops(newOperations) {
      this.$emit("operations-loaded", newOperations);
    },
    async getGanttIsPastDelayShown(newBool) {
      if (newBool) await this.loadCharge();
    },
    async selectedDelayMesh() {
      await this.loadCharge();
    },
    async entity() {
      this.loadCharge();
    },
    ofIdsToExport(newVal: string[]) {
      if (this.isList) return;
      const id = this.entity.secteur_id;
      if (this.isPiloting) {
        this.pilotingOfIdsToExport = {
          ...this.pilotingOfIdsToExport,
          [id]: newVal,
        };
      } else {
        this.calendarOfIdsToExport = {
          ...this.calendarOfIdsToExport,
          [id]: newVal,
        };
      }
    },
    pgRefresh(val: number) {
      if (val) this.loadCharge();
    },
  },
  created() {
    this.pg_init();
    this.pg_subscribe(["daily_prod"], () => this.loadCharge());
  },
  beforeUnmount() {
    this.$emit("operations-unloaded", this.entity);
  },
  methods: {
    async loadPostRowDisplay(isActive: boolean): Promise<void> {
      if (!isActive && !this.hasBeenWithinViewport) return;
      this.hasBeenWithinViewport = isActive;
      await this.loadCharge();
    },
    async loadCharge() {
      this.loading = !this.silentLoading;
      if (!this.silentLoading) this.pg_ops = [];
      if (this.entity.of_id) {
        const operations = await this.loadChargeOf();

        this.pg_ops = operations || [];
      } else {
        let startDateForDelay = "";
        if (this.getGanttIsPastDelayShown)
          startDateForDelay = this.getStartDateForDelay(this.selectedDelayMesh);

        const [, operations] = await this.loadGanttOperations({
          sectors: [this.entity],
          params: {
            filter_done_ops: this.filterDoneOps,
            ...(startDateForDelay && {startDateForDelay}),
          },
        });

        this.pg_ops = operations;
      }
      this.silentLoading = false;
      // delay to have a smoother display of the spinner
      this.loading = false;
    },
    async loadChargeOf() {
      const {
        entity: {of_id},
        selectedSimulation,
        ganttPeriod,
        filterDoneOps,
      } = this;
      const {id: simulation_id} = selectedSimulation || {};
      if (!simulation_id || !of_id) return;
      const startDate = ganttPeriod[0].format(DATE_DEFAULT_FORMAT);
      const endDate = ganttPeriod[1].format(DATE_DEFAULT_FORMAT);
      const results = await this.apiClient.ofsOpsFn(simulation_id, of_id, {
        prepare_gantt_data: true,
        start_date: startDate,
        end_date: endDate,
        is_weekend_hidden: this.ganttHideWeekend,
        calendars: this.getCtxSectorOrderedCalendars(),
      });
      return pgOpsMapFn(results, {
        of_ordering: true,
        keepOpsDone: !filterDoneOps,
        removeSmoothedDuplicates: true,
      });
    },
    hasPriority(operation: SchedulingOperation): boolean {
      return !!operation.fast_track;
    },
    getOperationComputedDisplay(
      operation: SchedulingOperation,
    ): Record<string, string> {
      const {
        parametersSchedulingDisplay,
        isList,
        teamIdBySector,
        parametersAllSchedulingDisplaysByTeamId,
      } = this;

      const sectorTeamId = teamIdBySector[operation.secteur_id];
      const sector_scheduling_display =
        parametersAllSchedulingDisplaysByTeamId?.[sectorTeamId] ||
        parametersSchedulingDisplay;

      const computedDisplay = computedDisplayFn(
        operation,
        sector_scheduling_display,
        {
          theoric_date: operation.max_date_of,
          custom_view: isList ? "list_view" : "calendar_view",
          client_name: this.userData.client_name,
          environment: this.environment,
        },
      );
      return computedDisplay;
    },
    getDayStyle(day: string, dayIndex: number) {
      const populatedEntity = (
        this.entity.is_machine
          ? this.schedulingMachineCentersAndTags
          : this.stations
      ).find(({id}) => id === this.entity.secteur_id);

      if (!populatedEntity) return {};

      const totalCapaForEntity = this.totalCapaBySector[populatedEntity.id];

      if (!totalCapaForEntity) return {};

      let colorName = "grey-25";

      const totalCapaForEntityAndDay = totalCapaForEntity.find(
        ({day_date}) => day_date === day,
      );

      if (
        totalCapaForEntityAndDay?.daily_capa === undefined &&
        // we do not display the background for the column gathering past operations even tho the day itself is closed
        (dayIndex > 0 || !this.getGanttIsPastDelayShown)
      )
        colorName = "newLightGrey";

      return {
        backgroundColor: `var(${this.getDailyColorCssVar(day)}, ${
          this.variables[colorName]
        })`,
      };
    },
    getOperationClasses(operation: SchedulingOperation): VueClassesArray {
      const classes = [CSS_OPERATION_CARD_CLICK_OUTSIDE_CLASS];
      if (isSelectedOperation(this.selectedOperations, operation)) {
        classes.push(CSS_OPERATION_CARD_SELECTED_CLASS);
        // applying specific class for the first selected operation, which has the OperationsActionsMenu displayed nearby
        if (operation.op_id === this.selectedOperations[0].op_id)
          classes.push("selected-card__first");
      }

      /**
       * for the OF view, the color category border is applied on the details column
       */
      if (!this.isList && this.schedulingCurrentColorCategory) {
        const categoryClass = getColorCategoryClass(
          operation,
          this.schedulingCurrentColorCategory,
        );
        if (categoryClass) classes.push(categoryClass);
      }

      return classes;
    },
    getOperationStyle(
      operation: SchedulingOperation,
      dayIndex: number,
    ): HTMLElementStyleObject {
      const {
        delta_days = 0, //diff between max_date and min_date
        delta_weekend = 0, //number of weekend days between max_date and min_date (only has value if weekend are hidden to prevent unnecessary operations in the back)
      } = operation;
      const operationDaysSpan = Math.max(delta_days - delta_weekend, 1);
      const availableDaysSpan = this.ganttDaysArray.length - dayIndex;
      const finalSpan = Math.min(
        availableDaysSpan,
        Math.max(1, operationDaysSpan),
      );

      return {
        width: `calc(
          ${finalSpan} * var(--gantt-mesh-${this.ganttMesh}-cell-width)
          - var(--gantt-operation-spacing) * 2)`,
      };
    },
    getOperationsPerDay(
      day: string,
      dayIndex: number,
      op_id: string,
    ): SchedulingOperation[] {
      if (!this.processedOperations.length) return [];

      if (this.getGanttIsPastDelayShown && dayIndex === 0) {
        return this.processedOperationsByDayDateAndOpId[
          DEFAULT_PAST_START_DATE
        ]?.[op_id];
      }

      return this.processedOperationsByDayDateAndOpId[day]?.[op_id] || [];
    },
    isHoveredOperation(operation: SchedulingOperation): boolean {
      if (!this.hoveredOperation) return false;
      return (
        getGanttOperationKey(this.hoveredOperation) ===
        getGanttOperationKey(operation)
      );
    },
    setHoveredOperation(operation: SchedulingOperation) {
      this.hoveredOperation = operation;
    },
    onClickOutsideCard(event, operation: SchedulingOperation): void {
      if (!isSelectedOperation(this.selectedOperations, operation)) return;

      if (onClickOutsideSchedulingOperation(event))
        this.toggleSelectedOperations([]);
    },
    getGanttOperationKey,
  },
});
</script>

<style lang="scss">
// this is at the #app level
.gantt-row--cell-tooltip {
  padding: 0 !important;

  & .operation-card {
    padding: 0 !important;
  }

  &.mesh-week .gantt-header--wrapper {
    top: 0;
  }
}
</style>

<style scoped lang="scss">
@import "@/scss/constants.scss";

.gantt-row--wrapper {
  display: grid;

  --gantt-operation-spacing: 8px;

  & .gantt-row {
    display: grid;
    grid-template-columns: var(--gantt-prefix-width) 1fr;
    grid-template-rows: auto 1fr;
    width: fit-content;

    & .gantt-row-prefix {
      grid-row: 1/3;
    }

    & .gantt-row-calendar__cell-wrapper {
      position: relative;
      display: flex;
      overflow: hidden;
      min-height: inherit;

      &:hover::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: inherit;
        background: var(--o-black-opacity-25);
      }
    }

    & .gantt-row--cell {
      border-right: 1px solid rgb(var(--v-theme-grey-100));
      overflow: visible;
      padding: var(--gantt-operation-spacing);
    }

    & .gantt-row--cell-operation {
      --colors-categories-width: 0px;

      position: relative;
      height: 24px;
      background: rgb(var(--v-theme-newLayerBackground));
      border-radius: 4px;
      z-index: var(--gantt-z-index--operation);
      cursor: pointer;

      &[class*="--colors-categories"] {
        --colors-categories-width: 4px;

        &::before {
          width: var(--colors-categories-width);
        }
      }

      &:hover {
        background: rgb(var(--v-theme-grey-25));
      }
    }

    & .gantt-row__cell-content {
      position: absolute;
      left: -8px;
      top: -8px;
      padding-left: calc(12px + var(--colors-categories-width));
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      line-height: 40px;
      color: rgb(var(--v-theme-grey-700));
    }
  }
}

.v-lazy:last-child .gantt-row--wrapper {
  & .gantt-row-prefix,
  & .gantt-row-prefix--entity-name-wrapper {
    border-bottom-left-radius: 8px;
  }
}

.gantt-row__operation {
  position: relative;
  min-height: inherit;

  > div:first-child {
    background: rgb(var(--v-theme-newLayerBackground));
    position: sticky;
    left: 0;
    width: calc(var(--gantt-prefix-width) + 1px);
    min-height: inherit;
  }
}

.gantt-row__sector {
  position: sticky;
  top: var(--gantt-total-header-height);
  z-index: calc(var(--gantt-z-index--operation) + 2);
}

.gantt-row:not(.gantt-row__sector) {
  > div {
    border-bottom: var(--gantt-border);
  }
}

.gantt-row__operation-header {
  position: sticky;
  left: 0;
  width: calc(var(--gantt-prefix-width) + 1px);
  display: flex;
  align-items: center;
  gap: 4px;
  background: rgb(var(--v-theme-newLayerBackground));
  border-left: var(--gantt-border);
  border-right: var(--gantt-border);
  transition: all 0.25s;
  padding: 4px 8px;
  z-index: calc(var(--gantt-z-index--operation) + 1);
  cursor: pointer;

  &:hover {
    background: rgb(var(--v-theme-grey-25)) !important;
  }
  &:active {
    background: rgb(var(--v-theme-grey-50)) !important;
  }
}

.selected-card {
  background: rgb(var(--v-theme-cyan-100)) !important;
}

.gantt-row-prefix,
.gantt-row__operation-header {
  box-shadow: 4px 0px 8px 0px #0f0f0f0a;
}

:deep(.gantt-row--cell-tooltip) {
  border: none !important;

  & .scheduling-operation-card__content {
    border: none;
  }
}
</style>
