<script setup lang="ts">
import {computed, inject, ref, withDefaults} from "vue";
import {storeToRefs} from "pinia";
import {useRouter} from "vue-router";
import {serverTimestamp} from "firebase/firestore";
import _ from "lodash";
import SimulationForm from "@/views/Simulation/SimulationForm.vue";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {getImportsIds} from "@/tscript/utils";
import {useMainStore} from "@/stores/mainStore";
import {useSimulationStore} from "@/stores/simulationStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {useI18n} from "vue-i18n";
import loggerHelper from "@/tscript/loggerHelper";
import moment from "moment";
import {getSegmentPayload} from "@/tscript/utils/segmentHelper";
import {
  OpenDialogFunction,
  OpenSnackbarFunction,
  Segment,
  Simulation,
} from "@/interfaces";
import {useParametersUtils} from "@/composables/parameters/useParametersUtils";
import {oplitClient} from "@/api";

const props = withDefaults(defineProps<{isOld?: boolean}>(), {isOld: false});

const {t} = useI18n();
const router = useRouter();
const mainStore = useMainStore();
const {
  userData,
  apiClient,
  simulation,
  simulations,
  isScheduling,
  pgRefresh,
  team,
  hasStock,
} = storeToRefs(mainStore);
const simulationStore = useSimulationStore();
const {simulationStep, isCreatingSimulation} = storeToRefs(simulationStore);
const {setSimulationGlobal} = simulationStore;
const {selectedSimulation, schedulingSimulations} = storeToRefs(
  useSchedulingStore(),
);
const {currentPermissions} = storeToRefs(usePermissionsStore());
const {featureFlags} = useParametersUtils();

const openDialog = inject<OpenDialogFunction>("openDialog");
const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
const segment = inject<Segment>("segment");

const dialog = ref(true);
const isOperationOngoing = ref(false);

const formTitle = computed(() =>
  props.isOld ? t("Simulation.details") : t("Simulation.new_simulation"),
);

function parseLoadingStatus(simStatus: string) {
  if (!simStatus) return;
  openSnackbar({
    loading: simStatus !== "done",
    message: t("Simulation.creation_feedback." + simStatus),
    hideClose: false,
    overrideIcon: true,
  });
  if (simStatus === "done") {
    openSnackbar({
      timeout: 5000,
      message: t("Simulation.creation_feedback." + simStatus),
      hideClose: false,
      overrideIcon: true,
      type: "done",
    });
    pgRefresh.value++;
  }
}

async function _handleValidate({
  currentSimulation,
  selectedImports,
  selectedSimulation,
  mercaAbsences,
}) {
  const {
    id: user_id,
    first_name = "",
    last_name = "",
    client_id,
  } = userData.value || {};
  if (!client_id) return openDialog(null, "NO_PERMISSION");

  isOperationOngoing.value = true;

  const id = props.isOld
    ? currentSimulation?.id
    : dbHelper.getCollectionId("simulations");

  //We prepare import_ids
  const import_ids = getImportsIds(selectedImports);

  if (featureFlags.value.use_formula_for_pdp_load) {
    const fromSimulationId: string = (import_ids || []).find(
      ({collection, from_simu}) => from_simu && collection === "ofs",
    )?.id;
    if (fromSimulationId) {
      const [hasSectorWLoadAndFormula, error] =
        await oplitClient.apiSimulationsHasSectorWLoadAndFormula({
          scheduling_simulation_id: fromSimulationId,
        });

      if (!error && hasSectorWLoadAndFormula) {
        const confirm = await new Promise((resolve, reject) =>
          openDialog({
            type: "warning",
            action: () => resolve(true),
            onCancelClicked: () => reject(false),
            message: t("Simulation.Form.warning_from_scheduling_simulation"),
          }),
        ).catch(() => false);
        if (!confirm) return (isOperationOngoing.value = false);
      }
    }
  }

  if (props.isOld) {
    // simulation update handling
    if (!currentPermissions.value.general.create_update_simulation)
      return openDialog(null, "NO_PERMISSION");
    if (!currentSimulation?.client_id) return openDialog(null, "GENERIC_ERROR");
    //We prepare import_ids
    const newSimulation = {
      ...currentSimulation,
      ...(import_ids?.length && {import_ids}),
      ...(currentSimulation.forceToday && {
        dateMin: currentSimulation.forceToday,
        forceToday: currentSimulation.forceToday,
      }),
      trigger_function: true,
    };

    if (!import_ids?.length) {
      newSimulation.import_ids = [];
      newSimulation.import_id = null;
    }

    updateSimulation(newSimulation);
    onToggleModal();
  } else {
    //get the team id
    const team_ids = team.value?.id ? [team.value?.id] : null;

    let newSimulation: any;
    const eventMigrationParams: any = {
      client_id,
      simulation_id: id,
      simulation_start_date: currentSimulation.forceToday,
      is_scheduling: isScheduling.value,
      ...(selectedSimulation?.created_at && {
        selected_simulation: selectedSimulation,
        simulations: isScheduling.value
          ? schedulingSimulations.value
          : simulations.value,
      }),
      new_simulation_name: currentSimulation.name,
      userInfo: {user_id, first_name, last_name},
      import_ids,
      team: team.value,
      team_ids,
      merca_absences: mercaAbsences,
      is_stock: hasStock.value,
      ...(currentSimulation.only_admin && {only_admin: true}),
    };

    //we create a mnimal simulation just to create the document in the db and be able to listen to it afterwards
    const newSimulationPayload = {
      id,
      client_id,
      pg_parsed: moment.utc().format("YYYY-MM-DD HH:mm:ss.SSS"),
      should_use_stocks_events: true,
    } as Partial<Simulation>;

    if (selectedSimulation?.selected_period) {
      const start = currentSimulation.forceToday;
      const [, end] = selectedSimulation.selected_period || [];

      if (end >= start) newSimulationPayload.selected_period = [start, end];
    }

    await new Promise(async (resolve, reject) => {
      //subscribe to changes
      let unsub: () => void;
      const onChangeCallback = (doc: any) => {
        if (!doc) return;
        const {loading_status} = doc;
        parseLoadingStatus(loading_status);
        if (
          ["simulation_created", "simulation_first_tables"].includes(
            loading_status,
          )
        ) {
          newSimulation = doc;
          resolve(true);
        }
        if (loading_status === "done" && unsub) {
          resolve(true);
          unsub();
          isCreatingSimulation.value = false;
        }
      };

      isCreatingSimulation.value = true;

      await dbHelper.setDataToCollection(
        "simulations",
        newSimulationPayload.id,
        newSimulationPayload,
        true,
      );

      unsub = dbHelper.watchDocumentChanges(
        "simulations",
        id,
        onChangeCallback,
      );
      await apiClient.value
        .postRequest("/api/simulations/create", eventMigrationParams)
        .catch(reject);
    }).catch(loggerHelper.log);
    if (!newSimulation) {
      isOperationOngoing.value = false;
      return openDialog(null, "GENERIC_ERROR");
    }

    if (isScheduling.value) {
      schedulingSimulations.value = [
        newSimulation,
        ...(schedulingSimulations.value || []),
      ];
    } else simulations.value = [newSimulation, ...(simulations.value || [])];

    segment.value.track(
      isScheduling.value
        ? "[Ordo] Simulation Created"
        : "[PDP] Simulation Created",
      getSegmentPayload("simulation", newSimulation),
    );

    setSimulationGlobal(newSimulation);

    simulationStep.value = 1;

    onToggleModal();
  }
}
// save handler for a modified simulation (only for admin users)
async function updateSimulation(newSimulationConfig: Partial<Simulation>) {
  // baseSimulation is the original simulation in the case of a modification (props.isOld === true)
  const baseSimulation = isScheduling.value
    ? selectedSimulation.value
    : simulation.value;
  if (!baseSimulation?.id) return;

  const {first_name, last_name, id: user_id} = userData.value || {};

  const newSimulation = {
    ...newSimulationConfig,
    updated_at: serverTimestamp(),
    updated_by: {first_name, last_name, user_id},
  } as Simulation;

  const error = await dbHelper.setDataToCollection(
    "simulations",
    baseSimulation.id,
    newSimulation,
    true,
  );
  if (!error) {
    if (isScheduling.value) router.go(0);
    else {
      setSimulationGlobal({
        ...baseSimulation,
        ...newSimulation,
      });
    }

    openSnackbar(null, "SAVE_SUCCESS");
  } else {
    openSnackbar(null, "GENERIC_ERROR");
    return false;
  }
}
function onToggleModal(): void {
  isOperationOngoing.value = false;
  dialog.value = false;
}

const handleValidate = _.throttle(_handleValidate, 2000);
</script>

<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    persistent
    scroll-strategy="none"
    :retain-focus="false"
    id="simulation-form-modal"
  >
    <form
      class="new-simulation-form bg-newLayerBackground br-8 keep-scrollbar padded-scrollbar overflow-auto"
    >
      <SimulationForm
        :is-old="isOld"
        :form-title="formTitle"
        :toggle-modal="onToggleModal"
        :is-operation-ongoing="isOperationOngoing"
        @handle-validate="handleValidate"
      />
    </form>
  </v-dialog>
</template>
