import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {levelCorresp} from "@oplit/shared-module";
import {OpenDialogFunction, OpenSnackbarFunction, Sector} from "@/interfaces";
import {computed, inject} from "vue";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";

export const useFav = (sector: Sector) => {
  const openDialog = inject<OpenDialogFunction>("openDialog");
  const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");
  const {userData} = storeToRefs(useMainStore());

  const toggleFav = async (): Promise<boolean> => {
    if (!userData.value?.id) return openDialog(null, "NO_CLIENT_ID");
    // retrieves the levelCorresp if not passed in parameter
    const levelObject = levelCorresp[sector.level];
    const userfavorites = userData.value.favorites || [];
    let favorites;
    if (userfavorites.some((fav) => fav.secteur_id === sector.id))
      favorites = userfavorites.filter((fav) => fav.secteur_id !== sector.id);
    else {
      let entry: any = {};
      levelCorresp.forEach((level) => {
        if (sector[level.type + "_id"] !== undefined) {
          entry[level.type + "_id"] = sector[level.type + "_id"];
          entry[level.type + "_name"] = sector[level.type + "_name"];
        }
      });
      entry.secteur_id = sector.id;
      entry.secteur_name = sector.name;
      entry = {...entry, ...levelObject};
      favorites = [...userfavorites, entry];
    }
    //Save to firestore
    const error = await dbHelper.setDataToCollection(
      "users",
      userData.value.id,
      {favorites},
      true,
    );
    if (!error) {
      //We update the store
      userData.value = {...userData.value, favorites};
      return true;
    } else {
      openSnackbar(null, "GENERIC_ERROR");
      return false;
    }
  };

  const isFavorite = computed<boolean>(() => {
    const level = levelCorresp[sector.level];
    if (!level) return false;
    return userData.value?.favorites?.some(
      (fav) => fav?.secteur_id === sector?.id,
    );
  });

  return {
    toggleFav,
    isFavorite,
  };
};
