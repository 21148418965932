<script setup lang="ts">
import {inject, onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import FSwitch from "@/components/Global/Homemade/Controls/FSwitch.vue";
import FButton from "@/components/Global/Homemade/Buttons/FButton.vue";
import FTextField from "@/components/Global/Homemade/Inputs/FTextField.vue";
import SectorsGroupBreadcrumbs from "@/components/Navigation/SectorsGroupBreadcrumbs.vue";
import ConwipTicketsGaugeTicketsArea from "@/components/Scheduling/Conwip/ConwipTicketsGaugeTicketsArea.vue";
import {useMainStore} from "@/stores/mainStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {usePermissionsStore} from "@/stores/permissionsStore";
import {n} from "@/tscript/utils/generalHelpers";
import {ConwipTicketsGauge} from "@oplit/shared-module";
import {OpenSnackbarFunction, ConwipSectorLike} from "@/interfaces";

interface ConwipTicketsGaugeSidebarProps {
  sector: ConwipSectorLike;
}

const props = defineProps<ConwipTicketsGaugeSidebarProps>();

const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

const {conwipTicketsGaugeBySectorID, selectedConwipGroup} = storeToRefs(
  useSchedulingStore(),
);
const {apiClient} = storeToRefs(useMainStore());
const {currentPermissions} = storeToRefs(usePermissionsStore());

const isActive = ref(true);
const saving = ref(false);

const hasOverload = ref(false);
const hasUnderload = ref(false);
const defaultIdealTicketsCount = ref<number>(1);
const overloadModerateTicketsCount = ref<number>(null);
const underloadTicketsCount = ref<number>(null);

function closeSidebar() {
  if (saving.value) return;
  isActive.value = false;
}
async function save() {
  saving.value = true;

  const payload: ConwipTicketsGauge = {
    group_id: selectedConwipGroup.value.id,
    secteur_id: props.sector.id,
    has_overload: hasOverload.value,
    has_underload: hasUnderload.value,
    default_ideal_tickets_count: n(defaultIdealTicketsCount.value),
    overload_moderate_tickets_count: n(overloadModerateTicketsCount.value),
    underload_tickets_count: n(underloadTicketsCount.value),
  };

  const [isSuccess, error] = await apiClient.value.updateConwipTicketsGauges(
    payload,
  );

  if (!error) conwipTicketsGaugeBySectorID.value[props.sector.id] = payload;

  saving.value = false;

  openSnackbar(null, isSuccess ? "SAVE_SUCCESS" : "GENERIC_ERROR");

  closeSidebar();
}

onMounted(() => {
  if (!props.sector.ticketsGauge) return;
  const {
    has_overload = false,
    has_underload = false,
    default_ideal_tickets_count = 1,
    overload_moderate_tickets_count = null,
    underload_tickets_count = null,
  } = props.sector.ticketsGauge;

  hasOverload.value = has_overload;
  hasUnderload.value = has_underload;
  defaultIdealTicketsCount.value = default_ideal_tickets_count;
  overloadModerateTicketsCount.value = overload_moderate_tickets_count;
  underloadTicketsCount.value = underload_tickets_count;
});
</script>

<template>
  <v-overlay
    v-if="currentPermissions.scheduling.update_conwip_configuration"
    :model-value="isActive"
    class="conwip-tickets-gauge-sidebar__overlay"
  >
    <div class="conwip-tickets-gauge-sidebar__container">
      <div class="conwip-tickets-gauge-sidebar__header">
        <div>
          <h4 class="font-weight-bold">
            {{ $t("ConwipTicketsGaugeSidebar.header") }}
          </h4>

          <SectorsGroupBreadcrumbs
            :sector-group="selectedConwipGroup"
            :sector="sector"
            :separator="$t('global.in')"
            reverse
          />
        </div>

        <vue-feather type="x" class="cursor-pointer" @click="closeSidebar" />
      </div>

      <div class="conwip-tickets-gauge-sidebar__content-wrapper keep-scrollbar">
        <div class="conwip-tickets-gauge-sidebar__content">
          <FSwitch
            v-model="hasOverload"
            :label="$t('ConwipTicketsGaugeSidebar.switch_label__overload')"
            not-flat
          >
          </FSwitch>

          <template v-if="hasOverload">
            <ConwipTicketsGaugeTicketsArea
              type="overload-critical"
              has-bordered-content
            >
              <div class="pa-2 text-newPinkDark1">
                {{
                  $t(
                    "ConwipTicketsGaugeSidebar.overload_critical_tickets_count__text",
                  )
                }}
              </div>
            </ConwipTicketsGaugeTicketsArea>

            <ConwipTicketsGaugeTicketsArea
              type="overload-moderate"
              has-bordered-content
            >
              <div class="pa-2">
                <FTextField
                  v-model="overloadModerateTicketsCount"
                  :label="$t('ConwipTicketsGaugeSidebar.text_fields_label')"
                  type="number"
                  suffix="tickets"
                />
              </div>
            </ConwipTicketsGaugeTicketsArea>
          </template>

          <ConwipTicketsGaugeTicketsArea type="ideal" has-bordered-content>
            <div class="pa-2">
              <FTextField
                v-model="defaultIdealTicketsCount"
                :label="$t('ConwipTicketsGaugeSidebar.text_fields_label')"
                type="number"
                suffix="tickets"
              />
            </div>
          </ConwipTicketsGaugeTicketsArea>

          <FSwitch
            v-model="hasUnderload"
            :label="$t('ConwipTicketsGaugeSidebar.switch_label__underload')"
            not-flat
          >
          </FSwitch>

          <template v-if="hasUnderload">
            <ConwipTicketsGaugeTicketsArea
              type="underload"
              has-bordered-content
            >
              <div class="pa-2">
                <FTextField
                  v-model="underloadTicketsCount"
                  :label="$t('ConwipTicketsGaugeSidebar.text_fields_label')"
                  type="number"
                  suffix="tickets"
                />
              </div>
            </ConwipTicketsGaugeTicketsArea>
          </template>
        </div>
      </div>

      <div class="conwip-tickets-gauge-sidebar__footer">
        <FButton :disabled="saving" outlined @click="closeSidebar">
          {{ $t("global.annuler") }}
        </FButton>

        <FButton :loading="saving" filled @click="save">
          {{ $t("global.valider") }}
        </FButton>
      </div>
    </div>
  </v-overlay>
</template>

<style scoped lang="scss">
.conwip-tickets-gauge-sidebar__overlay {
  justify-content: flex-end;

  & :deep(.v-overlay__content) {
    border-radius: 0;
  }

  & :deep(label) {
    font-weight: bold;
  }

  & .conwip-tickets-gauge-sidebar__container {
    height: 100vh;
    width: 355px;
    background: white;
    display: flex;
    flex-direction: column;

    & > div {
      padding: 16px;
    }

    & .conwip-tickets-gauge-sidebar__header {
      display: flex;
      gap: 4px;
      max-width: 100%;

      & > div:first-child {
        flex: 1;
        max-width: calc(100% - 24px);
      }
    }

    & .conwip-tickets-gauge-sidebar__content-wrapper {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 0;

      & .conwip-tickets-gauge-sidebar__content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-height: inherit;

        & > * {
          flex: 0;
        }

        & .conwip-tickets-gauge-tickets-area {
          border-radius: 8px;
        }
      }
    }

    & .conwip-tickets-gauge-sidebar__footer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
  }
}
</style>
