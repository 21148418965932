import {GenericObject, ParametersHomepageUser} from "@/interfaces";
import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import _ from "lodash";
import loggerHelper from "@/tscript/loggerHelper";
import {defineStore, storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {useMainStore} from "@/stores/mainStore";
import {DEFAULT_HOMEPAGE_PARAMETERS} from "@/domains/homepage/constants";

export const useHomepageStore = defineStore("homepage", () => {
  const mainStore = storeToRefs(useMainStore());
  const rawHomepageParameters = ref<ParametersHomepageUser>({});

  const homepageParameters = computed(() => {
    if (!rawHomepageParameters.value) return;

    const filteredHomepageParameters = {
      ...rawHomepageParameters.value,
    };

    ["planning", "scheduling", "piloting"].forEach((parametersKey) => {
      if (!filteredHomepageParameters[parametersKey]) return;
      filteredHomepageParameters[parametersKey] = {
        ...filteredHomepageParameters[parametersKey],
        perimeters_ids: filteredHomepageParameters[
          parametersKey
        ].perimeters_ids?.filter((perimeterId) => {
          return mainStore.stations?.value.some(
            (poste) => poste.id === perimeterId,
          );
        }),
      };
    });

    return filteredHomepageParameters;
  });

  async function loadHomepageParameters(payload: {
    client_id: string;
    user_id: string;
  }) {
    const {client_id, user_id} = payload;
    const results = await dbHelper.getAllDataFromCollectionWithAll(
      "parametres_homepage_user",
      {
        where: [
          {field: "client_id", value: client_id, compare: "=="},
          {field: "user_id", value: user_id, compare: "=="},
        ],
      },
    );
    if (results.length === 0) {
      const id = await dbHelper.getCollectionId("parametres_homepage_user");
      const newParams = {
        ...DEFAULT_HOMEPAGE_PARAMETERS,
        id,
        user_id: user_id,
        client_id: client_id,
      };
      saveHomepageParameters(newParams);
      return;
    }
    if (results.length > 1)
      loggerHelper.log("Several parametres_homepage_user found for this user");
    const {...loadedParameters} = _.get(results, 0, {});
    rawHomepageParameters.value = {
      ...DEFAULT_HOMEPAGE_PARAMETERS,
      ...loadedParameters,
    };
  }

  async function saveHomepageParameters(parameters: GenericObject) {
    if (!parameters || !parameters.id) return;
    try {
      // update store
      rawHomepageParameters.value = parameters;
      // update remote
      await dbHelper.setDataToCollection(
        "parametres_homepage_user",
        parameters.id,
        {...parameters},
        true,
      );
    } catch (error: unknown) {
      loggerHelper.error("saveParameters - error", error);
      this._vm.$openDialog(null, "GENERIC_ERROR");
    }
  }

  return {
    rawHomepageParameters,
    homepageParameters,
    loadHomepageParameters,
    saveHomepageParameters,
  };
});
