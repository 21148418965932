import _ from "lodash";
import {toSafeString} from "@oplit/shared-module";
import type {NomenclatureProduct} from "@/interfaces";

/**
 * Retourne la liste de nomenclatures triée directement dans le front au moment de l'import du fichier
 */
export const getSortedNomenclatures = (
  newImportProducts: NomenclatureProduct[],
) => {
  const results = _.sortBy(
    [...newImportProducts],
    [
      (x) => toSafeString(x.product_name.toLowerCase()),
      (x) => toSafeString(x.child_product_name).toLowerCase(),
    ],
  );
  return results;
};
