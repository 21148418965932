import {inject} from "vue";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {isArchivedSimulation} from "@/tscript/utils/simulation";
import {SCHEDULING_ROUTES_NAMES_MAPPING} from "@/router/constants/scheduling";
import {GLOBAL_TAB_SCHEDULING_PLANNING_OFS} from "@/config/constants";
import {OpenOFSidebarFunction} from "@/interfaces";
import {useMainStore} from "@/stores/mainStore";

export function useRedirectAndOpenOF() {
  const openOFSidebar = inject<OpenOFSidebarFunction>("openOFSidebar");
  const route = useRoute();
  const router = useRouter();
  const {globalTabsPerRoute} = useGlobalTabs();
  const {mergedParameters} = storeToRefs(useMainStore());
  const {selectedSimulation} = storeToRefs(useSchedulingStore());

  async function redirectAndOpenOF(of_id: string) {
    // determine which route we should go depending on the current scheduling simulation
    const routeName =
      isArchivedSimulation(selectedSimulation.value) ||
      mergedParameters.value?.unique_scheduling_simulation
        ? SCHEDULING_ROUTES_NAMES_MAPPING.piloting
        : SCHEDULING_ROUTES_NAMES_MAPPING.planning;

    const gtab = globalTabsPerRoute.value[routeName].indexOf(
      GLOBAL_TAB_SCHEDULING_PLANNING_OFS,
    );

    const query = {
      ...route.query,
      search: of_id,
      gtab,
    };

    if (routeName === route.name) await router.replace({query});
    else await router.push({name: routeName, query});

    openOFSidebar({of_id});
  }

  return {redirectAndOpenOF};
}
