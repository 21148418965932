import {IResult, Result} from "@oplit/shared-module";
import {
  AuthError,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  UserCredential,
  linkWithPopup,
} from "firebase/auth";

const getMicrosoftProvider = () => {
  const provider = new OAuthProvider("microsoft.com");
  provider.setCustomParameters({
    tenant: "common",
  });
  return provider;
};

export async function signInWithMicrosoft(): Promise<
  [UserCredential, null] | [null, AuthError]
> {
  const provider = getMicrosoftProvider();
  const auth = getAuth();
  try {
    const result = await signInWithPopup(auth, provider);
    return [result, null];
  } catch (error) {
    return [null, error];
  }
}

export async function linkWithMicrosoft(): Promise<
  IResult<UserCredential, AuthError>
> {
  const provider = getMicrosoftProvider();
  const auth = getAuth();
  try {
    const result = await linkWithPopup(auth.currentUser, provider);
    return Result.success(result);
  } catch (error) {
    return Result.error(error as AuthError);
  }
}
