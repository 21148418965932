import {ref, computed, inject} from "vue";
import {defineStore, storeToRefs} from "pinia";
import {oplitClient} from "@/api";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useNomenclatures} from "@/composables/parameters/useNomenclatures";
import {
  type SchedulingOperation,
  type SchedulingComponentSidebarDetailsItem,
  SchedulingComponentDetailsItem,
} from "@oplit/shared-module";
import {OpenSnackbarFunction} from "@/interfaces";

export const useSchedulingComponentsStore = defineStore(
  "schedulingComponents",
  () => {
    const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

    const {selectedSimulation} = storeToRefs(useSchedulingStore());
    const {nomenclatureList} = useNomenclatures();

    const selectedSchedulingComponent =
      ref<SchedulingComponentSidebarDetailsItem>(null);
    const isLoadingSidebarComponentsDetails = ref<boolean>(false);
    const isLoadingComponentDetails = ref<boolean>(false);
    const isLoadingComponentAnalytics = ref<boolean>(false);

    /**
     * this is only used to display the "components" tab conditionally in OperationsRightBar
     */
    const articlesWithChildren = computed<Set<string>>(
      () =>
        new Set([
          ...nomenclatureList.value.map(({product_name}) => product_name),
        ]),
    );

    async function loadSidebarComponentsDetails(
      operation: SchedulingOperation,
    ) {
      try {
        isLoadingSidebarComponentsDetails.value = true;

        const [results, error] =
          await oplitClient.apiSchedulingComponentsGetSidebarComponentsDetailsPost(
            {
              simulation_id: selectedSimulation.value.id,
              ref_article: operation.ref_article,
              day_date: operation.day_date,
              of_id: operation.of_id,
            },
          );

        if (error) throw error;

        return results;
      } catch (error) {
        openSnackbar(null, null, error);
      } finally {
        isLoadingSidebarComponentsDetails.value = false;
      }
    }

    async function loadComponentDetails(params: {end_date: string | null}) {
      try {
        isLoadingComponentDetails.value = true;

        const [results, error] =
          await oplitClient.apiSchedulingComponentsGetComponentDetailsPost({
            ref_article: selectedSchedulingComponent.value.name,
            simulation_id: selectedSimulation.value.id,
            start_date: selectedSimulation.value.forceToday,
            end_date: params.end_date,
          });

        if (error) throw error;

        return results as SchedulingComponentDetailsItem[];
      } catch (error) {
        openSnackbar(null, null, error);
      } finally {
        isLoadingComponentDetails.value = false;
      }
    }

    async function loadComponentAnalytics() {
      try {
        isLoadingComponentAnalytics.value = true;

        const [result, error] =
          await oplitClient.apiSchedulingComponentsGetComponentAnalyticsPost({
            ref_article: selectedSchedulingComponent.value.name,
            simulation_id: selectedSimulation.value.id,
            start_date: selectedSimulation.value.forceToday,
          });

        if (error) throw error;

        return result;
      } catch (error) {
        openSnackbar(null, null, error);
      } finally {
        isLoadingComponentAnalytics.value = false;
      }
    }

    return {
      selectedSchedulingComponent,
      isLoadingSidebarComponentsDetails,
      isLoadingComponentDetails,
      isLoadingComponentAnalytics,
      articlesWithChildren,
      loadSidebarComponentsDetails,
      loadComponentDetails,
      loadComponentAnalytics,
    };
  },
);
