<script setup lang="ts">
import {computed} from "vue";
import {Poste} from "@/interfaces";

interface EnCoursColumnSectorBlockProps {
  sector: Poste;
}

const props = defineProps<EnCoursColumnSectorBlockProps>();

const sectorName = computed(
  () => props.sector.secteur_name ?? props.sector.name,
);
</script>

<template>
  <div class="en-cours-column-sector-block">
    <slot v-bind="{sectorName}" />
  </div>
</template>

<style scoped lang="scss">
.en-cours-column-sector-block {
  width: 100%;
  height: var(--sector-block--height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
</style>
