import {App, defineComponent, h, render} from "vue";
import FSnackbar from "@/components/Global/Homemade/Feedback/FSnackbar.vue";
import {
  AlertPlugin,
  createHTMLElementWithID,
} from "@/plugins/utils/pluginsHelper";
import {FSnackbarProps, UnknownError} from "@/interfaces";

/**
 * creates a FSnackbar component with given @snackbarConfiguration on-demand
 * invoke with this.$openSnackbar(...)
 * @snackbarConfiguration : an object containing props for the FSnackbar component
 */
const OplitSnackbar = {
  install(app: App, options) {
    const {defaultAlertConfigurations, getGenericConfigurationFromAPIResponse} =
      new AlertPlugin(options.i18n);

    const configurations: {[key: string]: FSnackbarProps} = {
      ...defaultAlertConfigurations,
      WARN_UNSAVED: {
        type: "warning",
        message: options.i18n.t("Alert.warn_unsaved"),
      },
    };

    const getSnackbarID = (baseID: string) => `${baseID}--snackbar`;

    const openSnackbar = function (
      snackbarConfiguration: FSnackbarProps,
      constant: string,
      error: UnknownError,
    ) {
      const actualConfiguration: FSnackbarProps = {
        /**
         * to specifically handle with the getGenericConfigurationFromAPIResponse,
         * this plugin has to be invoked with (null, null, <error>) as parameters
         */
        // since we pass this in any case, this has to remain first so that it is overriden by the next objects
        ...getGenericConfigurationFromAPIResponse(error),
        ...(configurations[constant] || {}),
        // this has to remain the last so that overriding is possible for every scenario
        ...(snackbarConfiguration || {}),
      };

      const {container, id} = createHTMLElementWithID();

      let snackbarInstance = h(
        FSnackbar as ReturnType<typeof defineComponent>,
        {
          modelValue: true, // defaulting the rendering but allowing value override
          id: getSnackbarID(id), // we pass an ID to be able to target the snackbar in further logic
          ...actualConfiguration,
        },
      );

      // handling indetermined timeouts
      if ("loading" in actualConfiguration) {
        const {$indefiniteSnackbarInstance} = app.config.globalProperties;
        if ($indefiniteSnackbarInstance) {
          document.getElementById($indefiniteSnackbarInstance)?.remove();
          app.config.globalProperties.$indefiniteSnackbarInstance = null;
        }
        if (!actualConfiguration.loading) return;
        // assigning the ID to a global object for further deletion
        app.config.globalProperties.$indefiniteSnackbarInstance =
          getSnackbarID(id);
      }

      document.getElementById("app").appendChild(container);

      snackbarInstance.appContext = app._context;
      render(snackbarInstance, container);

      return {
        closeSnackbar: () => {
          render(null, container);
          document.getElementById("app").removeChild(container);
        },
        modifySnackbar: (newProps: FSnackbarProps) => {
          snackbarInstance = h(
            FSnackbar as ReturnType<typeof defineComponent>,
            {
              ...snackbarInstance.props,
              ...newProps,
            },
          );
          render(snackbarInstance, container);
        },
      };
    };

    app.config.globalProperties.$openSnackbar = openSnackbar;
    app.provide("openSnackbar", openSnackbar);
  },
};

export default OplitSnackbar;
