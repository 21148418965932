<template>
  <span class="fd-flex-center">
    <span v-if="onlyDropdown" :class="{'w-100': fullSize}">
      <FDropdown
        v-if="shouldDisplayTree"
        :activator-id="EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID"
        :class="{'w-100': fullSize}"
        :content-class="contentClass"
        item-value="id"
        item-text="parent_text"
        :btn-class="['cursor-pointer', {'w-100': fullSize}]"
        type="perimeter"
        :default-path="selectedMachinePath"
        :only-select-last-level="!canSelectParent"
        :z-index="zIndex"
        single-select
        outlined
        input
        hide-chevron
        :display-machine-tags="!clientParameters.scheduling_ignore_machines"
        :disabled="disabled"
        :field-text="computedSelectedMachineProp"
        :max-field-height="maxFieldHeight"
        :placeholder="$t('scheduling.machine_centers')"
        :on-change="(s, l, f, r) => returnSelectedMachine(s)"
      />
      <FDropdown
        v-else
        :activator-id="EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID"
        :class="{'w-100': fullSize, 'sector-dropdown': true}"
        :content-class="contentClass"
        :items="schedulingMachineCenters"
        :btn-class="['cursor-pointer', {'w-100': fullSize}]"
        return-object
        input
        hide-chevron
        outlined
        :disabled="disabled"
        :model-value="selectedMachineProp"
        :placeholder="$t('scheduling.machine_centers')"
        :z-index="zIndex"
        item-text="secteur_name"
        item-value="secteur_id"
        @change="(event) => returnSelectedMachine(event)"
      />
    </span>

    <v-dialog
      v-else
      v-model="open"
      :disabled="disabled"
      :z-index="zIndex"
      width="50%"
    >
      <template v-slot:activator="{props}">
        <span v-bind="props" class="d-flex text-subtitle-1">
          <FButton
            v-tooltip="buttonTooltip"
            :id="EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID"
            outlined
            data-testid="machine-center-selector"
          >
            <template
              #icon-left
              v-if="populatedOriginalSectorsGroups.length > 0"
            >
              <OplitIcon :stroke="variables.newSubText" name="sector" />
            </template>

            <span class="sectors-selector__button-text text-ellipsis">
              {{ canSelectMultiple ? buttonText : computedSelectedMachineProp }}
            </span>

            <span
              v-if="
                canSelectMultiple && populatedOriginalSectorsGroups.length === 1
              "
              class="text-newDisableText fbody-2 pl-1"
            >
              {{ buttonTextHelper }}
            </span>
          </FButton>
        </span>
      </template>

      <v-card class="pa-6">
        <h4 v-if="title" class="font-weight-bold mb-4">{{ title }}</h4>

        <v-card-text class="pa-0 pb-6">
          <FDropdown
            v-if="shouldDisplayTree"
            item-value="id"
            item-text="parent_text"
            wrapper-class="cursor-pointer"
            type="perimeter"
            :field-text="computedSelectedMachine"
            :placeholder="$t('Profile.select_sector')"
            :tags="computedTags"
            :default-path="treeDefaultPath"
            :can-select-all="canSelectAll"
            :multi-select="canSelectMultiple"
            :single-select="!canSelectMultiple"
            :small="small"
            :z-index="zIndex"
            :max-field-height="maxFieldHeight"
            :only-select-last-level="!canSelectParent"
            :tag-input="canSelectMultiple"
            :keep-open="canSelectMultiple"
            :display-machine-tags="!clientParameters.scheduling_ignore_machines"
            outlined
            input
            block
            toggle-default
            keep-scrollbar
            aggregated-tooltips
            data-testid="tree-dropdown-selector"
            :on-change="(s, l, f, r) => selectMachine(s, r)"
            :on-change-multiple="selectSectorGroup"
            :on-select-all="onSelectAll"
            :on-reset-all="deselectAll"
          />
          <div v-else>
            <v-select
              v-model="computedMachineCenter"
              :items="filteredMachineCentersList"
              :label="$t('scheduling.machine_centers')"
              multiple
              :z-index="zIndex"
              variant="outlined"
              ref="multiple_machines"
              item-title="secteur_name"
              item-value="secteur_id"
              density="compact"
              data-testid="list-dropdown-selector"
            >
              <template v-slot:prepend-item>
                <div class="sectors-selector-search-input-container">
                  <v-list-item>
                    <v-list-item-title class="cursor-pointer flex-1">
                      <FTextField
                        class="my-2"
                        icon="search"
                        v-model="search"
                        clearable
                        flush-label
                        :label="$t('global.search')"
                        @click:clear="() => (search = '')"
                        @click.stop
                      />
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider class="mt-2" />
                </div>
                <v-list-item>
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="
                      () =>
                        areAllMachinesSelected ? deselectAll() : selectAll()
                    "
                  >
                    {{
                      areAllMachinesSelected
                        ? $t("global.deselect_all")
                        : $t("global.select_all")
                    }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{item, props}">
                <v-list-item
                  v-bind="props"
                  :title="undefined"
                  :data-testid="`list-dropdown-${computeSectorName(item.raw)}`"
                >
                  {{ computeSectorName(item.raw) }}
                </v-list-item>
              </template>
              <template v-slot:append-item>
                <div class="validate" data-testid="list-dropdown-validate">
                  <FButton filled @click="$refs['multiple_machines'].blur()">
                    {{ $t("global.validate") }}
                  </FButton>
                </div>
              </template>
            </v-select>
          </div>
        </v-card-text>

        <v-card-actions class="pa-0">
          <FButton
            v-if="canSelectMultiple"
            textClass="text-newPrimaryRegular"
            data-testid="reset-sectors-selector"
            @click="deselectAll"
          >
            {{ $t("global.clear") }}
          </FButton>
          <v-spacer></v-spacer>
          <FButton @click="handleReset">
            {{ $t("global.cancel") }}
          </FButton>
          <FButton
            filled
            data-testid="validate-sectors-selector"
            @click="handleConfirm"
          >
            {{ $t("global.valider") }}
          </FButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script lang="ts">
import {computed, defineComponent, inject, ref, watch} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import {FDropdown, FButton, FTextField} from "@/components/Global/Homemade";
import {buildRecursivelySectorTree} from "@oplit/shared-module";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {MachineCenter, SectorGroup, Segment} from "@/interfaces";

import {useSectors} from "@/composables/useSectors";
import useSimulationDataExport from "@/composables/useSimulationDataExport";
import {useMainStore} from "@/stores/mainStore";
import {useI18n} from "vue-i18n";
import {useParameterStore} from "@/stores/parameterStore";
import {
  EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID,
  USER_PARAMETERS_SELECTED_SECTORS_ON_GOING_KEY,
  USER_PARAMETERS_SELECTED_SECTORS_KEY,
} from "@/config/constants";

export default defineComponent({
  name: "sectors-selector",
  components: {FButton, FDropdown, FTextField},
  props: {
    fullSize: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    onlyDropdown: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    selectedMachineProp: {type: Object, default: () => ({})},
    canSelectParent: {type: Boolean, default: false},
    // determines if the defaultPath should match any eventual tag
    openFirstSelected: {type: Boolean, default: false},
    zIndex: {type: Number, default: 2000},
    maxFieldHeight: {type: String, default: "300px"},
    contentClass: {type: [String, Array, Object], default: () => []},
    isOngoingView: {type: Boolean, default: false},
    canSelectAll: {type: Boolean, default: true},
    canSelectMultiple: {type: Boolean, default: true},
    useSimpleUpdateEventInModal: {type: Boolean, default: false},
    title: {type: String, default: ""},
  },
  emits: ["update-sector"],
  setup(props) {
    const {t} = useI18n();
    const {onSelectAllSectors} = useSectors();
    const {setCurrentActiveSchedulingCenters} = useSimulationDataExport();

    const segment = inject<Segment>("segment");

    const mainStore = useMainStore();
    const {
      userData,
      clientParameters,
      isDevEnv,
      machines,
      perimeters,
      stations,
      activePerim,
      userParameters,
      variables,
      stationsAndMachinesTags,
    } = storeToRefs(mainStore);

    const {
      selectedSimulation,
      schedulingMachineCenters,
      schedulingMachineCentersAndTags,
    } = storeToRefs(useSchedulingStore());
    const {getUsersSelectedSchedulingSectors} = useSchedulingStore();

    const {parametersSectorGroups} = storeToRefs(useParameterStore());

    const machineCentersList = ref<MachineCenter[]>([]);
    const open = ref(false);
    const loading = ref(false);

    /**
     * stores the original configuration of the user's selected sectors/groups
     */
    const originalItems = ref<{
      groups: SectorGroup["id"][];
      sectors: MachineCenter["id"][];
    }>({
      groups: [],
      sectors: [],
    });
    /**
     * stores the updated version, prior validation
     */
    const selectedItems = ref<{
      groups: SectorGroup["id"][];
      sectors: MachineCenter["id"][];
    }>({
      groups: [],
      sectors: [],
    });

    const populatedSelectedSectorsGroups = computed(() =>
      getPopulatedSectorsGroups(selectedItems.value.groups),
    );
    const populatedOriginalSectorsGroups = computed(() =>
      getPopulatedSectorsGroups(originalItems.value.groups),
    );
    const buttonText = computed(() => {
      if (populatedOriginalSectorsGroups.value.length > 0) {
        return [...populatedOriginalSectorsGroups.value]
          .sort((a, b) => b.sectors.length - a.sectors.length)
          .map(({name}) => name)
          .join(", ");
      }

      return t("Commons.sectors_dropdown_button_text", {
        count: originalItems.value.sectors.length,
      });
    });
    const buttonTextHelper = computed(() =>
      populatedOriginalSectorsGroups.value.length > 0
        ? `(${
            populatedOriginalSectorsGroups.value
              .map(({sectors}) => sectors)
              .flat().length
          } sect.)`
        : "",
    );
    const buttonTooltip = computed(() =>
      [buttonText.value, buttonTextHelper.value].filter(Boolean).join(" "),
    );

    const computedSelectedMachineProp = computed(() => {
      const selectedTag = stationsAndMachinesTags.value.find(
        (p: {id?: string}) => p.id === props.selectedMachineProp?.secteur_id,
      );

      return selectedTag?.parent_text || selectedTag?.name || "";
    });
    const computedSelectedMachine = computed(() => {
      if (!selectedItems.value.sectors.length)
        return computedSelectedMachineProp.value;

      const selectedCenter = schedulingMachineCentersAndTags.value.find(
        ({secteur_id}) => selectedItems.value.sectors.includes(secteur_id),
      );

      if (selectedCenter?.is_machine) {
        return machines.value.find(
          (m: {id: string}) => m.id === selectedCenter?.secteur_id,
        )?.name;
      }

      return (
        stations.value.find(
          (p: {id?: string}) => p.id === selectedCenter?.secteur_id,
        )?.parent_text || ""
      );
    });

    const shouldDisplayTree = computed(() => {
      return clientParameters.value.scheduling_display_tree;
    });

    function initMachineCenters() {
      if (props.useSimpleUpdateEventInModal) return;

      const {allSelectedSectorsPopulated, sectors, groups} =
        getUsersSelectedSchedulingSectors(props.isOngoingView);

      machineCentersList.value = schedulingMachineCentersAndTags.value.map(
        (machineCenterAndTag) => {
          const {secteur_id, is_machine} = machineCenterAndTag;
          if (is_machine) return machineCenterAndTag;
          const match = stations.value.find(({id}) => id === secteur_id);
          if (!match) return machineCenterAndTag;
          const {text, parent_text} = match;
          return {...machineCenterAndTag, text, parent_text};
        },
      );

      selectedItems.value = {sectors, groups};
      originalItems.value = {sectors, groups};

      setCurrentActiveSchedulingCenters(
        allSelectedSectorsPopulated.map(({id}) => id),
      );
    }
    function removeSelectedSectorsFromConwipSectorsGroups() {
      if (!clientParameters.value.has_conwip) return;

      const selectedConwipGroupsIDs = populatedSelectedSectorsGroups.value
        .filter(({is_conwip}) => is_conwip)
        .map(({id}) => id);

      if (selectedConwipGroupsIDs.length === 0) return;

      selectedItems.value.groups = selectedItems.value.groups.filter(
        (groupID) => !selectedConwipGroupsIDs.includes(groupID),
      );
    }
    function selectMachine(
      sector: {id?: string; secteur_id?: string},
      remove: boolean,
    ) {
      const {id, secteur_id} = sector || {};
      if (!id && !secteur_id) return;

      const sectorID = secteur_id || id;

      removeSelectedSectorsFromConwipSectorsGroups();

      if (props.canSelectMultiple) {
        selectedItems.value.sectors = remove
          ? selectedItems.value.sectors.filter((id: string) => id !== sectorID)
          : _.uniq([...selectedItems.value.sectors, sectorID]);
      } else selectedItems.value.sectors = [sectorID];
    }
    function selectSectorGroup(group: SectorGroup, remove: boolean) {
      if (remove) {
        selectedItems.value.groups = selectedItems.value.groups.filter(
          (groupID) => groupID !== group.id,
        );
      } else {
        if (group.is_conwip) {
          selectedItems.value = {
            groups: [group.id],
            sectors: [],
          };

          save();
        } else {
          selectedItems.value.groups = [
            ...selectedItems.value.groups.filter((groupID) => {
              const {is_conwip} =
                populatedSelectedSectorsGroups.value.find(
                  ({id}) => id === groupID,
                ) || {};
              return !is_conwip;
            }),
            group.id,
          ];
        }
      }
    }

    async function save() {
      loading.value = true;

      const userParameterKey = props.isOngoingView
        ? USER_PARAMETERS_SELECTED_SECTORS_ON_GOING_KEY
        : USER_PARAMETERS_SELECTED_SECTORS_KEY;

      if (
        !_.isEqual(
          selectedItems.value,
          userParameters.value?.[userParameterKey],
        )
      ) {
        await mainStore.setUserParameter({
          [userParameterKey]: selectedItems.value,
        });

        segment.value.track("[Ordo] Machine Centers Updated", {
          is_piloting: props.isOngoingView,
          ...selectedItems.value,
        });
      }
      loading.value = false;
      open.value = false;
    }
    function getPopulatedSectorsGroups(groups: string[]): SectorGroup[] {
      return groups
        .map((groupID) =>
          parametersSectorGroups.value.find(({id}) => groupID === id),
        )
        .filter(Boolean);
    }

    watch(
      () => [
        userParameters.value[USER_PARAMETERS_SELECTED_SECTORS_KEY],
        userParameters.value[USER_PARAMETERS_SELECTED_SECTORS_ON_GOING_KEY],
        open.value,
      ],
      () => initMachineCenters(),
      {deep: true},
    );

    return {
      search: ref(""),
      open,
      machineCentersList,
      loading,
      onSelectAllSectors,
      userData,
      clientParameters,
      isDevEnv,
      machines,
      perimeters,
      stations,
      activePerim,
      selectedSimulation,
      schedulingMachineCenters,
      schedulingMachineCentersAndTags,
      userParameters,
      initMachineCenters,
      variables,
      buttonText,
      buttonTextHelper,
      buttonTooltip,
      computedSelectedMachineProp,
      computedSelectedMachine,
      shouldDisplayTree,
      populatedSelectedSectorsGroups,
      selectSectorGroup,
      selectMachine,
      save,
      EMPTY_SECTORS_PLACEHOLDER_ACTIVATOR_ID,
      selectedItems,
      populatedOriginalSectorsGroups,
    };
  },
  computed: {
    computedMachineCenter: {
      get() {
        return this.selectedItems.sectors;
      },
      set(newValue) {
        this.selectedItems.sectors = newValue;
      },
    },
    areAllMachinesSelected() {
      const {machineCentersList, selectedItems} = this;
      return machineCentersList?.length === selectedItems.sectors.length;
    },
    computedTags() {
      const {
        selectedItems,
        populatedSelectedSectorsGroups,
        stations,
        machineCentersList,
      } = this;

      const groups = populatedSelectedSectorsGroups.map((group) => ({
        ...group,
        // this key is used in FTagInput to distinguish the display of groups and sectors
        is_group: true,
      }));

      const sectors = selectedItems.sectors.map((x: any) => {
        return (
          stations.find(({id}) => id === (x?.secteur_id || x)) ||
          machineCentersList.find(({id}) => id === x)
        );
      });

      return [...groups, ...sectors].filter(Boolean);
    },
    selectedMachinePath() {
      const {selectedMachineProp, perimeters} = this;
      if (!selectedMachineProp || !perimeters) return {};
      const {secteur_id = "operations", secteur_collection} =
        selectedMachineProp;
      const match = (perimeters[secteur_collection] || []).find(
        (op: any) => op.id === secteur_id,
      );
      return buildRecursivelySectorTree(match, perimeters);
    },
    filteredMachineCentersList() {
      const {search, machineCentersList} = this;
      return machineCentersList.filter((machine: any) =>
        this.computeSectorName(machine)
          .toUpperCase()
          .includes(search?.toUpperCase() || ""),
      );
    },
    simulation() {
      return this.selectedSimulation;
    },
    // returns either this.activePerim or the first tag to set for default opened path within the tree view

    treeDefaultPath() {
      const {
        openFirstSelected,
        computedTags,
        activePerim,
        selectedMachinePath,
        useSimpleUpdateEventInModal,
      } = this;
      if (!openFirstSelected || !computedTags.length)
        return useSimpleUpdateEventInModal ? selectedMachinePath : activePerim;

      return computedTags[0];
    },
  },
  watch: {
    userData: {
      deep: true,
      immediate: true,
      handler() {
        this.initMachineCenters();
      },
    },
  },
  methods: {
    computeSectorName(sector: any) {
      if (
        !["g0E4nabmVzpLoQWUPnwq", "YYayeSzhWcd4DJOgIKmh"].includes(
          this.userData?.client_id,
        )
      )
        return sector.secteur_name;
      return sector?.text || sector.secteur_name;
    },
    selectAll() {
      const {filteredMachineCentersList} = this;
      this.selectedItems.sectors = [
        ...new Set(
          filteredMachineCentersList.map(({secteur_id}) => secteur_id),
        ),
      ];
    },
    deselectAll() {
      this.selectedItems = {
        sectors: [],
        groups: [],
      };
    },
    onSelectAll(...args: any): () => void {
      return this.onSelectAllSectors(this.selectMachine, ...args);
    },

    handleReset() {
      this.deselectAll();
      this.open = false;
    },
    handleConfirm() {
      this.useSimpleUpdateEventInModal
        ? this.returnSelectedMachine()
        : this.save();
    },
    returnSelectedMachine(value?: {id?: string}) {
      let selectedCenter = value || {};

      if (!this.onlyDropdown) {
        const [selectedCenterID] = this.selectedItems.sectors;
        selectedCenter = {id: selectedCenterID};
      }

      this.open = false;

      if (this.shouldDisplayTree) {
        return this.$emit(
          "update-sector",
          this.schedulingMachineCentersAndTags.find(
            (x: {secteur_id: string}) => x.secteur_id === selectedCenter.id,
          ),
        );
      }

      return this.$emit("update-sector", selectedCenter);
    },
  },
});
</script>
<style scoped>
.sectors-selector-search-input-container {
  position: sticky;
  top: -8px;
  margin-top: -8px;
  padding-top: 8px;
  background: rgb(var(--v-theme-newLayerBackground));
  z-index: 1;
}
.validate {
  position: sticky;
  bottom: -8px;
  display: flex;
  justify-content: flex-end;
  background: rgb(var(--v-theme-newLayerBackground));
  margin-bottom: -8px;
  padding: 8px; /* matches .v-list padding */
}
.blink {
  -webkit-animation: blink 1s step-end infinite;
  animation: blink 1s step-end infinite;
}
.sectors-selector__button-text {
  max-width: 165px;
  display: inline-block;
}
@-webkit-keyframes blink {
  50% {
    visibility: hidden;
  }
}
@keyframes blink {
  50% {
    visibility: hidden;
  }
}
</style>
