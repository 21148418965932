<template>
  <div
    :class="[
      'gantt-diagram--wrapper',
      `mesh-${ganttMesh}`,
      {
        'is-list': isList,
        'is-synthetic': isSyntheticView,
        'has-today-indicator': hasTodayIndicator,
      },
      getHiddenHeadersClasses,
    ]"
    :style="diagramStyle"
  >
    <GanttTableHeader
      :is-list="isList"
      :entities="slicedEntities"
      @update-tags="$emit('update-tags')"
      @update-status="$emit('update-status')"
    />

    <div
      ref="ganttDiagramRef"
      class="gantt-diagram keep-scrollbar padded-scrollbar"
      @scroll="onGanttDiagramScroll"
    >
      <GanttHeader
        :hide-daily-load-rate="isList"
        :diagram-left-scroll="diagramLeftScroll"
      />

      <Component
        v-for="(entity, entityIndex) in slicedEntities"
        :key="getEntityKey(entity, entityIndex)"
        :is="getRowComponent"
        :entity="entity"
        :hide-progress-rate="!isPiloting"
        :selected-operations="selectedOperations"
        :filters="filters"
        :filter-done-ops="filterDoneOps"
        :is-list="isList"
        :is-piloting="isPiloting"
        :is-first="entityIndex === 0"
        :is-synthetic-view="isSyntheticView"
        :selected-delay-mesh="selectedDelayMesh"
        :is-last="entityIndex === slicedEntities.length - 1"
        :is-active="isActive"
        :diagram-left-scroll="diagramLeftScroll"
        @operations-loaded="(ops) => onOperationsLoaded(ops, entity)"
        @operations-unloaded="() => onOperationsUnloaded(entity)"
        @operation-clicked="onOperationClick"
        @toggle-machine-center="(id) => $emit('toggle-machine-center', id)"
      />
    </div>

    <div
      v-if="hasPagination && entities.length"
      class="gantt-diagram--pagination-wrapper"
    >
      <div class="gantt-diagram--pagination">
        {{ $t("Commons.rows_per_page") }}

        <FDropdown
          :model-value="perPage"
          :items="perPageItems"
          :outlined="['newLayerBackground']"
          btn-class="gapped-button"
          @change="onChangePerPage"
        />

        {{ displayedRowsText }}

        <FButtonIcon
          v-for="direction in ['left', 'right']"
          :key="`page-${direction}`"
          :icon="`chevron-${direction}`"
          :disabled="isDisabledDirection(direction)"
          @click="onDirectionClick(direction)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  provide,
  PropType,
  ref,
  watch,
  nextTick,
} from "vue";
import {storeToRefs} from "pinia";
import _ from "lodash";
import GanttHeader from "./GanttHeader.vue";
import GanttRow from "./GanttRow.vue";
import GanttRowCalendar from "./GanttRowCalendar.vue";
import GanttTableHeader from "./GanttTableHeader.vue";
import {FButtonIcon, FDropdown} from "@/components/Global";
import {
  getGanttInterestKey,
  getGanttOperationKey,
  isSelectedOperation,
} from "@/tscript/utils/schedulingUtils";
import type {
  GanttableEntity,
  GenericConfigObject,
  OFsType,
  SchedulingOperation,
  SchedulingFilter,
} from "@/interfaces";
import {
  DATE_DEFAULT_FORMAT,
  SCHEDULING_GANTT_PER_PAGE_IDENTIFIER,
  SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE,
} from "@/config/constants";
import moment from "moment";

import {useMainStore} from "@/stores/mainStore";
import {useGanttStore} from "@/stores/ganttStore";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import {useIsArchivedSimulation} from "@/composables/useIsArchivedSimulation";
import {useSelectedOperations} from "@/stores/selectedOperationsStore";

export default defineComponent({
  name: "gantt-diagram",
  components: {
    FButtonIcon,
    FDropdown,
    GanttHeader,
    GanttRow,
    GanttRowCalendar,
    GanttTableHeader,
  },
  props: {
    // FIXME
    isList: {type: Boolean, default: false},
    isPiloting: {type: Boolean, default: false},
    entities: {
      type: Array as PropType<Array<GanttableEntity>>,
      required: true,
    },
    sortCriteria: {
      type: Array as PropType<Array<GenericConfigObject>>,
      default: () => [] as Array<GenericConfigObject>,
    },
    hiddenHeaders: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
    filters: {type: Array as PropType<SchedulingFilter[]>, default: () => []},
    filterDoneOps: {type: Boolean, default: false},
    /**
     * hasPagination & page props works together
     * the prop handling the current page should be from the paren't state and should be used with :page.sync
     */
    hasPagination: {type: Boolean, default: false},
    page: {type: Number, default: 1},
    selectedDelayMesh: {
      type: Object as PropType<{label?: string; mesh?: string; value?: number}>,
      default: () => ({}),
    },
    isActive: {type: Boolean, default: false},
  },
  emits: [
    "update-tags",
    "update-status",
    "update:page",
    "toggle-machine-center",
  ],
  provide() {
    return {
      getHiddenHeaders: () => this.hiddenHeaders,
    };
  },
  setup(props) {
    const mainStore = useMainStore();
    const {apiClient, userData, userParameters, clientParameters, variables} =
      storeToRefs(mainStore);
    const {setUserParameter} = mainStore;

    const {loadCapaMultiSectors, getStartDateForDelay, getDailyColorCssVar} =
      useGanttStore();
    const {
      ganttIsPastDelayShown,
      ganttMesh,
      ganttIsSyntheticView,
      ganttPeriodStartDate,
      ganttPeriod,
      ganttDaysArray,
    } = storeToRefs(useGanttStore());

    const {
      canOperateOnOperationCards,
      pgOpsModifications,
      selectedSimulation,
      totalCapaBySector,
      totalLoadBySector,
      dailySchedulingColors,
    } = storeToRefs(useSchedulingStore());

    const {tempUpdatedSelectedOperations} = storeToRefs(
      useSelectedOperations(),
    );

    const {isCurrentGlobalTab} = useGlobalTabs();

    const {isArchivedSimulation} = useIsArchivedSimulation();

    const operations = ref<Record<string, SchedulingOperation[]>>({});
    const selectedOperations = ref<SchedulingOperation[]>([]);
    const ganttDiagramRef = ref<HTMLElement>(null);
    /**
     * used in OperationsEditDialog to perform updates on distinct entities
     */
    provide("operationsByEntity", operations);

    /**
     * this computed is used in subcomponents to determine the effective activation of ganttIsPastDelayShown
     * at the time of writing, this option is disabled for the OFs view
     */
    const getGanttIsPastDelayShown = computed(
      () => !props.isList && ganttIsPastDelayShown.value,
    );
    const capacities = computed(() =>
      _.flatten(Object.values(totalCapaBySector.value || {}))
        .filter(Boolean)
        .reduce(
          (acc: Record<string, number>, {day_date, daily_capa}) => ({
            ...acc,
            [day_date]: (acc[day_date] || 0) + +daily_capa,
          }),
          {},
        ),
    );
    const getRowComponent = computed(() =>
      props.isList ? GanttRow : GanttRowCalendar,
    );
    const hasTodayIndicator = computed(() =>
      ganttDaysArray.value.includes(moment().format(DATE_DEFAULT_FORMAT)),
    );
    const diagramStyle = computed(() => {
      const cellWidth = `var(--gantt-mesh-${ganttMesh.value}-cell-width)`;
      const prefixWidth = `var(--gantt-prefix-width)`;
      const coeff =
        ganttDaysArray.value.indexOf(moment().format(DATE_DEFAULT_FORMAT)) + 1;

      const [start, end] = ganttPeriod.value.map((mDate) =>
        mDate.format(DATE_DEFAULT_FORMAT),
      );
      const dailyColorsVars = dailySchedulingColors.value
        .filter(({day_date}) => day_date >= start && day_date <= end)
        .reduce(
          (acc, {day_date, color_name}) => ({
            ...acc,
            [getDailyColorCssVar(day_date)]: `${variables.value[color_name]}1A`, // 10%
          }),
          {} as Record<string, string>,
        );

      return {
        "--today-left-row": `calc(
          ${prefixWidth} +
          ${coeff} * ${cellWidth}
          - ${cellWidth} / 2
        )`,
        ...dailyColorsVars,
      };
    });

    provide("getGanttIsPastDelayShown", getGanttIsPastDelayShown);

    watch(selectedSimulation, () => (selectedOperations.value = []));

    watch(
      tempUpdatedSelectedOperations,
      async (operations: SchedulingOperation[]) => {
        if (!operations?.length) return;
        /**
         * logic to update diagram scrolls positions to the first selected operation card
         */
        await nextTick();

        const firstSelectedCard = document.querySelector(".selected-card");

        if (!firstSelectedCard || !ganttDiagramRef.value) return;

        const {top, left, bottom, right} =
          firstSelectedCard.getBoundingClientRect();

        const {
          top: containerTop,
          left: containerLeft,
          bottom: containerBottom,
          right: containerRight,
        } = ganttDiagramRef.value.getBoundingClientRect();

        const isSelectedCardVisible =
          top >= containerTop &&
          left >= containerLeft &&
          bottom <= containerBottom &&
          right <= containerRight;

        if (isSelectedCardVisible) return;

        firstSelectedCard.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    );

    return {
      diagramStyle,
      operations,
      capacities,
      perPage: ref<number>(SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE),
      diagramLeftScroll: ref<number>(0),
      apiClient,
      userData,
      userParameters,
      clientParameters,
      isCurrentGlobalTab,
      loadCapaMultiSectors,
      getStartDateForDelay,
      ganttMesh,
      ganttIsSyntheticView,
      ganttPeriodStartDate,
      ganttPeriod,
      setUserParameter,
      isArchivedSimulation,
      ganttIsPastDelayShown,
      getGanttIsPastDelayShown,
      canOperateOnOperationCards,
      pgOpsModifications,
      selectedOperations,
      totalCapaBySector,
      totalLoadBySector,
      getRowComponent,
      hasTodayIndicator,
      ganttDiagramRef,
    };
  },
  computed: {
    isSyntheticView(): boolean {
      return this.ganttIsSyntheticView && this.isCurrentGlobalTab("ofs");
    },
    getHiddenHeadersClasses(): string {
      return Array.from(
        this.hiddenHeaders,
        (key: string) => `hidden-${key}`,
      ).join(" ");
    },
    slicedEntities(): Array<GanttableEntity> {
      if (!this.entities.length) return [];
      const sortedEntities = this.sortEntities(this.entities);
      if (!this.hasPagination) return sortedEntities;
      return sortedEntities.slice(
        (this.page - 1) * this.numPerPage,
        this.page * this.numPerPage,
      );
    },
    allDaysArr() {
      const {ganttPeriod} = this;
      const [startDate, endDate] = ganttPeriod;
      const length = Math.ceil(endDate.diff(startDate, "days", true));
      if (length < 0) return [];
      const allDaysArr = Array.from(
        {length: length + 1},
        (_: undefined, i: number) => {
          const day_date = moment(startDate)
            .add(i, "days")
            .format(DATE_DEFAULT_FORMAT);
          if (day_date > endDate) return;
          return day_date;
        },
      ).filter(Boolean);
      return allDaysArr;
    },
    // returns the numerical value behind the current this.perPage
    numPerPage(): number {
      // if false, we are viewing "all" rows
      return typeof this.perPage === "number"
        ? this.perPage
        : this.entities.length;
    },
    displayedRowsText(): string {
      return `${[
        (this.page - 1) * this.numPerPage + 1,
        Math.min(this.page * this.numPerPage, this.entities.length),
      ].join(" - ")} ${this.$t("Commons.de")} ${this.entities.length}`;
    },
    perPageItems(): Array<number | string> {
      return [
        10,
        SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE,
        100,
        this.$t("Commons.all"),
      ];
    },
  },
  created() {
    const savedPerPage =
      this.userParameters[SCHEDULING_GANTT_PER_PAGE_IDENTIFIER];
    if (!savedPerPage) return;
    const perPageFromParametres = this.perPageItems.find(
      (item: number | string) => item === savedPerPage,
    );
    this.perPage =
      perPageFromParametres ?? SCHEDULING_GANTT_PER_PAGE_DEFAULT_VALUE;
  },
  methods: {
    onGanttDiagramScroll: _.debounce(function (this, event) {
      this.diagramLeftScroll = event.target.scrollLeft;
    }, 50),
    getEntityKey(entity: GanttableEntity, index: number) {
      if (this.isList) {
        const e = entity as OFsType;
        /**
         * we add the `e.date` so that modifying through the sidebar/update modal reloads the data for a modified row
         * it prevents adding complexity on the frontend
         */
        return [e.of_id, e.date, index].join("-");
      }
      return [JSON.stringify(entity), index].join("-");
    },
    onOperationsLoaded(
      operations: SchedulingOperation[],
      entity: GanttableEntity,
    ) {
      this.operations[entity[getGanttInterestKey(this.isList)]] = operations;
    },
    onOperationsUnloaded(entity: GanttableEntity) {
      delete this.operations[entity[getGanttInterestKey(this.isList)]];
    },
    sortFunctionForCriterion({field, type, order}) {
      const sortRatio = order === "desc" ? -1 : 1;
      switch (type) {
        case "number":
          return (a, b) => (a[field] - b[field]) * sortRatio;
        default:
          /**
           * this is also valid for most "date"-typed field since we usually compare stringified dates formatted as DEFAULT_DATE_FORMAT
           * NB : the values are stringified because some fields seem to have different types between objects
           * seen for Cartier, `famille` has numbers & strings
           */
          return (a, b) =>
            `${a[field]}`.localeCompare(`${b[field]}`) * sortRatio;
      }
    },
    sortEntities(array: GanttableEntity[]): GanttableEntity[] {
      if (!this.sortCriteria || this.sortCriteria.length === 0) return array;
      return array.sort((a, b) => {
        let result = 0;
        for (let i = 0; i < this.sortCriteria.length; i++) {
          const criterion = this.sortCriteria[i];
          result = this.sortFunctionForCriterion(criterion)(a, b);
          if (result !== 0) break;
        }
        return result;
      });
    },
    onOperationClick(operation?: SchedulingOperation) {
      if (!operation) return (this.selectedOperations = []);
      if (!this.canOperateOnOperationCards) return;
      if (!this.selectedOperations.length)
        return this.selectedOperations.push(operation);
      if (
        /**
         * for the OFs view, we want to be able to select operations from different OFs
         * to perform date updates on multiple entities
         */
        !this.isList &&
        operation[getGanttInterestKey(this.isList)] !==
          this.selectedOperations[0][getGanttInterestKey(this.isList)]
      )
        this.selectedOperations = [];
      if (isSelectedOperation(this.selectedOperations, operation)) {
        return this.selectedOperations.splice(
          this.selectedOperations.findIndex(
            (op: SchedulingOperation) =>
              getGanttOperationKey(op) === getGanttOperationKey(operation),
          ),
          1,
        );
      }
      this.selectedOperations.push(operation);
    },
    onChangePerPage(value: string): void {
      this.perPage = value;
      this.$emit("update:page", 1);

      this.setUserParameter({[SCHEDULING_GANTT_PER_PAGE_IDENTIFIER]: value});
    },
    onDirectionClick(direction: string): void {
      this.$emit(
        "update:page",
        this.page + 1 * (direction === "left" ? -1 : 1),
      );
    },
    isDisabledDirection(direction: string): boolean {
      return (
        (direction === "left" && this.page === 1) ||
        (direction === "right" &&
          this.page * this.numPerPage >= this.entities.length)
      );
    },
  },
});
</script>

<style lang="scss">
$ganttMeshes: "month", "two-weeks", "week";

.gantt-diagram--wrapper {
  --gantt-border-width: 1px;
  --gantt-border-color: rgb(var(--v-theme-grey-100));
  --gantt-border: var(--gantt-border-width) solid var(--gantt-border-color);
  --gantt-entity-name-width: 200px;
  --gantt-entity-details-width: 0px;
  --gantt-entity-quantite-width: 0px;
  --gantt-entity-tags-width: 0px;
  --gantt-prefix-width: calc(
    var(--gantt-entity-name-width) + var(--gantt-entity-details-width) +
      var(--gantt-entity-quantite-width) + var(--gantt-entity-tags-width)
  );
  // describing more verbosely for further modifications
  // the width values are reused for JS calculations inside the ganttStore (see ganttCellWidth)
  --gantt-mesh-month-cell-width: 40px;
  --gantt-mesh-two-weeks-cell-width: 96px;
  --gantt-mesh-week-cell-width: 144px;
  --gantt-operation-height: 80px;
  --gantt-operation-spacing: 4px;
  --gantt-header-meshes-height: 32px;
  --gantt-header-height: 24px; // determined from auto-height of day sub-cells (38px) and the 2px of bottom borders
  --gantt-header-load-rate-height: 20px;
  --gantt-shifts-line-height: 24px;
  --gantt-shifts-height: 0px;
  --gantt-total-header-height: calc(
    var(--gantt-header-height) + var(--gantt-header-meshes-height) +
      var(--gantt-shifts-height)
  );
  --gantt-week-header-height: calc(
    var(--gantt-header-height) + var(--gantt-shifts-height)
  );
  --gantt-select-icon-flex: 0 0 20px;

  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  flex: 1;

  @each $mesh in $ganttMeshes {
    &.mesh-#{$mesh} {
      & .gantt--cell {
        width: var(--gantt-mesh-#{$mesh}-cell-width);
      }
    }
  }

  & .gantt-diagram {
    position: relative;
    overflow: scroll;
    width: calc(100% - 1px);
  }

  & .gantt-diagram--pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    padding-right: var(--g-scrollbar-area);

    & .gantt-diagram--pagination {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &.is-list {
    --gantt-entity-details-width: 200px;
    --gantt-entity-quantite-width: 80px;
    --gantt-entity-tags-width: 200px;

    & .gantt-diagram {
      width: calc(var(--g-scrollbar-container-width) - 1px);
    }

    & .gantt-header--wrapper .gantt-header--days-wrapper {
      border-bottom: none;
    }

    & .gantt-table-header {
      background: rgb(var(--v-theme-newSubBackground));
      border: var(--gantt-border);
      border-width: 1px 0 1px 1px;
      border-radius: 8px 0 0 0;

      &::after {
        content: none;
      }
    }
  }

  &.hidden-details {
    --gantt-entity-details-width: 0px;
  }

  &.hidden-quantite {
    --gantt-entity-quantite-width: 0px;
  }

  &.hidden-tags {
    --gantt-entity-tags-width: 0px;
  }
}

// FIXME : sort properly the specific use cases
.gantt-diagram--wrapper {
  &.is-synthetic {
    --gantt-operation-height: 26px;
  }
  // when there is/isn't the GanttHeaderMonth component displayed
  &.mesh-week .gantt-row--wrapper:nth-child(2) {
    // this value is due to the ::before of .gantt-header--wrapper
    margin-top: -6px;
  }

  &.is-list {
    overflow: hidden;

    & .gantt-row-prefix {
      border-top-left-radius: 0 !important;
    }

    &
      .gantt-header--wrapper
      .gantt-header
      .gantt-header--meshes-wrapper
      > div.is-first-mesh-displayed {
      border-top-left-radius: 0;
    }
  }

  &.mesh-week {
    --gantt-header-meshes-height: 0px;
    --gantt-shifts-height: calc(var(--gantt-shifts-line-height) + 3px);
    .gantt-header--wrapper {
      top: 0;
    }
  }
}

.gantt-diagram--wrapper:not(.is-list) {
  --gantt-entity-name-width: 400px;

  & .gantt-diagram {
    margin-bottom: var(--g-vertical-spacing);
  }
}
</style>

<style scoped lang="scss">
.gantt-diagram--wrapper {
  --gantt-z-index--today-indicator: 3;
  --gantt-z-index--operation: calc(var(--gantt-z-index--today-indicator) + 1);
  --gantt-z-index--operation-menu: calc(var(--gantt-z-index--operation) + 2);

  &.has-today-indicator:deep() {
    .gantt-row__operation,
    .gantt-row__sector,
    .gantt-row__lazy-wrapper {
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        background: rgb(var(--v-theme-red-600));
        left: var(--today-left-row);
        z-index: var(--gantt-z-index--today-indicator);
        top: 0;
        height: 100%;
      }
    }
  }
}
</style>
