// custom plugins creating custom components from an instance method
import openDialog from "@/plugins/methods/dialog";
import openSimulationModal from "@/plugins/methods/simulationModal";
import openSnackbar from "@/plugins/methods/snackbar";
import openOFSidebar from "@/plugins/methods/ofSidebar";
import dependenciesPlugin from "@/plugins/methods/dependencies";
import openConwipTicketsGaugeSidebar from "@/plugins/methods/conwipTicketsGaugeSidebar";

// custom components
import OplitContainer from "@/components/Commons/OplitContainer.vue";

// custom directives
import defineColor from "@/plugins/directives/defineColor";

const instancesMethods = [
  openDialog,
  openSimulationModal,
  openSnackbar,
  openOFSidebar,
  dependenciesPlugin,
  openConwipTicketsGaugeSidebar,
];

const components = {"oplit-container": OplitContainer};

const useCustomPlugins = (app, options) => {
  // creates instance methods from imported plugins
  instancesMethods.forEach((method) => app.use(method, options));

  // register all globally used custom components
  Object.entries(components).forEach(([name, component]) =>
    app.component(name, component),
  );

  // register all custom directives
  app.directive("define-color", defineColor(app));
};

export default useCustomPlugins;
