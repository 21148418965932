import {
  createVNode,
  reactive,
  render,
  type App,
  type Directive,
  type VNode,
} from "vue";
import {CustomColorDefiner} from "@/components/Commons";

/**
 * this directive opens a menu near an element to associate a color to a date\
 * the binding is meant to be used as such :\
 * ```vue
 * <my-element
 *    v-define-color="{ day_date: <string_date>, disabled: <is_the_directive_disabled> }"
 * />
 * ```
 * the `day_date` format could technically be anything, as long as it matches what you pass in parameter on the getter `getDailySchedulingColors`\
 * it was made using DATE_DEFAULT_FORMAT ("YYYY-MM-DD") and it is recommended to use with this format also
 */

const activeInstance = reactive<{
  vNode: VNode;
  el: HTMLElement | null;
}>({
  vNode: null,
  el: null,
});

const elementListeners = new Map<HTMLElement, () => void>();

const defineColor = (app: App): Directive => {
  return {
    mounted: function (
      el: HTMLElement,
      {value}: {value: Record<string, unknown>},
    ) {
      if (!value || value.disabled) return;
      el.classList.add("cursor-pointer");
    },
    beforeMount: function (
      el: HTMLElement,
      {value}: {value: Record<string, unknown>},
    ) {
      if (!value) return;
      const {disabled, ...parameters} = value;
      if (disabled) return;

      const handleClick = function (): void {
        // unmounts the previous instance if existing
        if (activeInstance.vNode && activeInstance.el) {
          render(null, activeInstance.el);

          activeInstance.vNode = null;
          activeInstance.el = null;
        }

        const vNode = createVNode(
          CustomColorDefiner,
          {
            activator: el,
            parameters,
          },
          () => [value],
        );

        vNode.appContext = app._context;
        render(vNode, el);

        activeInstance.vNode = vNode;
        activeInstance.el = el;
      };

      el.addEventListener("click", handleClick);
      elementListeners.set(el, handleClick);
    },
    beforeUnmount(el: HTMLElement) {
      elementListeners.delete(el);
    },
  };
};

export default defineColor;
