<script setup lang="ts">
import {ConwipTicketsGaugeType} from "@/interfaces";

interface ConwipTicketsGaugeTicketsAreaProps {
  type: ConwipTicketsGaugeType;
  hasLeftBorder?: boolean;
  hasBorderedContent?: boolean;
}

defineProps<ConwipTicketsGaugeTicketsAreaProps>();

const iconNameMap = {
  underload: "circle-dashed",
  ideal: "check-circle",
  "overload-moderate": "circle-half",
  "overload-critical": "warning-circle",
};
</script>

<template>
  <div
    :class="[
      `conwip-tickets-gauge-tickets-area__${type}`,
      {'has-left-border': hasLeftBorder},
    ]"
    class="conwip-tickets-gauge-tickets-area"
  >
    <div class="conwip-tickets-gauge-tickets-area__header">
      <OplitIcon :name="iconNameMap[type]" />

      <span class="fbody-2">
        {{ $t(`ConwipTicketsGaugeTicketsArea.${type}`) }}
      </span>

      <v-spacer />

      <slot name="append-header" />
    </div>

    <div
      :class="{'has-bordered-content': hasBorderedContent}"
      class="conwip-tickets-gauge-tickets-area__content"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
$colorsByType: (
  "underload": (
    bg: "cyan-50",
    text: "cyan-800",
    border: "cyan-300",
    borderLeft: "cyan-500",
  ),
  "ideal": (
    bg: "newGreenLight2",
    text: "newGreenDark2",
    border: "newGreenLight1",
    borderLeft: "newGreenDark1",
  ),
  "overload-moderate": (
    bg: "newOrangeLight2",
    text: "newOrangeDark2",
    border: "newOrangeLight1",
    borderLeft: "newOrangeDark1",
  ),
  "overload-critical": (
    bg: "newPinkLight2",
    text: "newPinkDark2",
    border: "newPinkLight1",
    borderLeft: "newPinkDark1",
  ),
);

@mixin get-colors-properties-from-type($type) {
  $colorsBinding: map-get($colorsByType, $type);

  background: rgb(var(--v-theme-#{map-get($colorsBinding, "bg")}));
  border-left-color: rgb(
    var(--v-theme-#{map-get($colorsBinding, "borderLeft")})
  );

  & .conwip-tickets-gauge-tickets-area__content.has-bordered-content {
    border: 1px dashed rgb(var(--v-theme-#{map-get($colorsBinding, "border")}));
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
  }

  & .conwip-tickets-gauge-tickets-area__header {
    color: rgb(var(--v-theme-#{map-get($colorsBinding, "text")}));
  }
}

.conwip-tickets-gauge-tickets-area {
  &.has-left-border {
    border-left-width: 1px;
    border-left-style: solid;
  }

  &.conwip-tickets-gauge-tickets-area__underload {
    @include get-colors-properties-from-type("underload");
  }

  &.conwip-tickets-gauge-tickets-area__ideal {
    @include get-colors-properties-from-type("ideal");
  }

  &.conwip-tickets-gauge-tickets-area__overload-moderate {
    @include get-colors-properties-from-type("overload-moderate");
  }

  &.conwip-tickets-gauge-tickets-area__overload-critical {
    @include get-colors-properties-from-type("overload-critical");
  }

  & .conwip-tickets-gauge-tickets-area__header {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 8px;
  }
}
</style>
