<script setup lang="ts">
import {ref, inject} from "vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import moment from "moment";

import {dbHelper} from "@/tscript/dbHelper/dbBuilder";
import {FTextField, AvatarPicture, FButtonIcon} from "@/components/Global";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {getSegmentPayload} from "@/tscript/utils/segmentHelper";
import {useMainStore} from "@/stores/mainStore";
import {SchedulingMessage} from "@oplit/shared-module";
import {OpenDialogFunction, OpenSnackbarFunction, Segment} from "@/interfaces";

const props = withDefaults(
  defineProps<{
    op: Record<string, any>;
    messages?: {
      name: string;
      timestamp: number;
      id: string;
      uid: string;
      content: string;
    }[];
    loading?: boolean;
  }>(),
  {
    op: () => ({}),
    messages: () => [],
    loading: false,
  },
);

const segment = inject<Segment>("segment");
const openDialog = inject<OpenDialogFunction>("openDialog");
const openSnackbar = inject<OpenSnackbarFunction>("openSnackbar");

const {t} = useI18n();

const mainStore = useMainStore();
const {userData} = storeToRefs(mainStore);

const schedulingStore = useSchedulingStore();
const {schedulingUpdate} = storeToRefs(schedulingStore);

const commentsBodyRef = ref<HTMLDivElement>(null);
const newMessage = ref<string>(null);
const feedback = ref<string>(null);

function scroll() {
  if (!commentsBodyRef.value) return;
  const lastMessageRef = commentsBodyRef.value.lastElementChild;
  if (!lastMessageRef) return;
  lastMessageRef.scrollIntoView({behavior: "smooth"});
}
function getMessageTimestamp(message: {
  name: string;
  timestamp: number;
}): string {
  return `${moment(message.timestamp).fromNow()}`;
}

async function handleMessageDeletion(message: {id: string; content: string}) {
  const hasConfirmedDeletion = await openDialog({
    type: "warning",
    header: t("Comments.delete_warning_title"),
    message: t("Comments.delete_warning_message"),
    validateText: t("Comments.delete_warning_validation"),
    hideIcon: true,
    hidePrefix: true,
    confirmBtnProps: {
      // OPL-7691
      // Workaround to make the button red with the current dialog implementation
      // FIXME
      class: "bg-newPinkRegular",
      white: true,
    }
  });

  if (!hasConfirmedDeletion) return;

  await deleteMessage(message);
}

async function createMessage() {
  await addMessage();

  scroll();
  newMessage.value = null;
  feedback.value = null;
}
async function addMessage() {
  try {
    const {client_id, name, email} = userData.value;

    const message: SchedulingMessage = {
      id: dbHelper.getCollectionId("scheduling_messages"),
      client_id,
      content: newMessage.value,
      name,
      email,
      op_id: props.op.op_id,
      of_id: props.op.of_id,
      uid: userData.value.id,
      timestamp: Date.now(),
    };
    newMessage.value = "";
    await dbHelper.setDataToCollection(
      "scheduling_messages",
      message.id,
      message,
      true,
    );
    //Object.assign is to keep reactivity
    Object.assign(schedulingUpdate.value, {
      type: "message",
      field: "of_id",
      value: props.op.of_id,
      payload: message,
    });
    segment.value.track(
      "[Ordo] Chat Message on operation created",
      getSegmentPayload("operation", props.op),
    );
  } catch (error) {
    openSnackbar(null, null, {
      message: t("Comments.creation_error"),
    });
  }
}
async function deleteMessage(message: {id: string; content: string}) {
  try {
    await dbHelper.setDataToCollection(
      "scheduling_messages",
      message.id,
      {
        status: "removed",
      },
      true,
    );

    Object.assign(schedulingUpdate.value, {
      type: "message",
      field: "of_id",
      value: props.op.of_id,
      payload: {...message, status: "removed"},
    });
    segment.value.track(
      "[Ordo] Chat Message on operation deleted",
      getSegmentPayload("operation", props.op),
    );

    openSnackbar({
      message: t("Comments.delete_success"),
    });
  } catch (error) {
    openSnackbar(null, null, {
      message: t("Comments.delete_error"),
    });
  }
}
</script>

<template>
  <div class="comments__wrapper">
    <div ref="commentsBodyRef" class="comments__body">
      <v-row
        v-for="message in messages"
        :key="message.id"
        class="comments-message__wrapper"
        no-gutters
      >
        <div class="comments-message__header-wrapper">
          <div class="comments-message__header">
            <span class="comments-message__header-author">
              {{ message.name }}
            </span>

            <span class="comments-message__header-timestamp">
              {{ getMessageTimestamp(message) }}
            </span>
          </div>

          <FButtonIcon
            v-if="message.uid === userData.id"
            icon="trash-2"
            icon-stroke="rgb(var(--v-theme-red-700))"
            small
            @click="handleMessageDeletion(message)"
          />
        </div>

        <div class="comments-message__body">
          <AvatarPicture
            class="comments-message__avatar"
            :user-id="message.uid"
            size="24"
          />

          <div class="comments-message__content">
            {{ message.content }}
          </div>
        </div>
      </v-row>
    </div>

    <div class="comments__footer">
      <form @submit.prevent="createMessage">
        <div class="comments__footer-form">
          <FTextField
            v-model="newMessage"
            class="w-100"
            borderless
            :placeholder="t('Comments.add_comment')"
          />

          <FButtonIcon
            icon="send"
            icon-stroke="rgb(var(--v-theme-newPrimaryRegular))"
            @click="createMessage"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.comments__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.comments__body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.comments-message__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 8px;
}

.comments-message__header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comments-message__header {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.comments-message__header-author {
  color: rgb(var(--v-theme-grey-700));
  margin-right: 4px;
}

.comments-message__header-timestamp {
  color: rgb(var(--v-theme-grey-500));
}

.comments-message__body {
  display: flex;
  gap: 8px;
}

.comments-message__content {
  font-size: 14px;
  font-weight: 400;
  color: rgb(var(--v-theme-newMainText));
  background-color: rgb(var(--v-theme-blue-25));
  border-radius: 8px;
  padding: 8px;
}

.comments-message__avatar {
  margin: 0 !important;
  margin-top: 4px !important;
}

.comments__footer {
  background-color: rgb(var(--v-theme-newLayerBackground));
  position: sticky;
  margin-top: auto;
  bottom: 0;
}

.comments__footer-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
}

.sendicon {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
