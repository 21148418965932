import {createVuetify} from "vuetify";

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import variables from "@/scss/colors.module.scss";
import lightVariables from "@/scss/light_colors.module.scss";
import opalColors from "@/scss/themes/colors.module.scss";

export default createVuetify({
  theme: {
    //TODO fix customProperties
    // The theme system now uses CSS variables internally, so customProperties is no longer required.
    // If you were using customProperties in v2, the naming scheme has changed from --v-primary-base to --v-theme-primary.
    // dark: true,
    // options: {
    // customProperties: true,
    // variations: false,
    // },
    defaultTheme: "light",
    themes: {
      light: {
        dark: false,
        colors: {
          primary: lightVariables.newPrimaryRegular,
          secondary: lightVariables.newPrimaryDark2,
          accent: lightVariables.newPrimaryLight1,
          error: lightVariables.newPinkRegular,
          info: lightVariables.newPrimaryRegular,
          success: lightVariables.newGreenRegular,
          warning: lightVariables.newOrangeDark1,
          background: lightVariables.newAppBackground,
          "on-newLightGrey": lightVariables.newDisableText,
          ...lightVariables,
          ...opalColors,
        },
      },
      dark: {
        dark: true,
        colors: {
          primary: variables.newPrimaryRegular,
          secondary: variables.newPrimaryDark2,
          accent: variables.newPrimaryLight1,
          error: variables.newPinkRegular,
          info: variables.newPrimaryRegular,
          success: variables.newGreenRegular,
          warning: variables.newOrangeDark1,
          background: variables.newAppBackground,
          "on-newLightGrey": variables.newDisableText,
          ...variables,
        },
      },
    },
  },
  defaults: {
    VLazy: {
      transition: "no",
    },
    VTooltip: {
      eager: false,
    },
  },
});
