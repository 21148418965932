<template>
  <Modal
    class="export-data"
    :title="$t('scheduling.data_export')"
    :subtitle="$t('scheduling.select_data')"
    :is-operation-ongoing="loading"
    :footer-props="{confirmButtonText: $t('scheduling.export_data')}"
    :can-confirm-modal="canConfirmModal"
    width="664px"
    hide-borders
    hide-scrollbar
    hide-close-icon
    is-dense
    @confirm-modal="onValidate"
    @close-modal="toggleModal"
  >
    <template v-slot:prepend-title>
      <vue-feather type="share" />
    </template>
    <div
      v-for="item in filteredExportItems"
      :key="item.id"
      class="field-wrapper justify-center"
    >
      <FCheck
        :id="item.id"
        :label="item.label"
        :model-value="item.checked"
        :data-testid="`export-modal-check-item-${item.id}`"
        @update:model-value="(v) => (item.checked = v)"
      />
    </div>
    <div v-if="hasActiveFilters()" class="export-with-filters-container">
      <hr class="my-3" />
      <FCheck
        :label="$t('scheduling.filter_data')"
        :model-value="shouldFilterExports"
        data-testid="export-modal-check-should-filter"
        @update:model-value="(v) => (shouldFilterExports = v)"
      />
    </div>
  </Modal>
</template>

<script lang="ts">
import {computed, defineComponent, ref, PropType} from "vue";
import {storeToRefs} from "pinia";
import moment from "moment";
import _ from "lodash";

import {Modal} from "@/components/Global";
import {FCheck} from "@/components/Global";
import {OplitSimulationPeriod, SimulationLoadSeriesData} from "@/interfaces";
import useExportGenerateCSV from "@/composables/useExportGenerateCSV";
import {usePGComputedProperties} from "@/composables/pgComposable";
import {useGlobalTabs} from "@/composables/useGlobalTabs";
import loggerHelper from "@/tscript/loggerHelper";
import {clickOnLink} from "@/tscript/utils/exportUtils";

import {useSchedulingStore} from "@/stores/schedulingStore";
import {useMainStore} from "@/stores/mainStore";
import {useAPIStore} from "@/stores/apiStore";
import {useEventsStore} from "@/stores/eventsStore";
import {
  EXPORT_LOAD_BYPASS_MAPPING,
  EXPORT_PROD_BYPASS_MAPPING,
  ofTooltipMatch,
  translateRawExportedOplitHeaders,
} from "@oplit/shared-module";
import {useParameterStore} from "@/stores/parameterStore";
import useSimulationDataExport from "@/composables/useSimulationDataExport";

interface ExportEntry {
  id: string;
  label: string;
  tracker?: string;
  checked: false;
  exportFn: () => Promise<void>;
}

export default defineComponent({
  name: "export-modal",
  props: {
    show: {type: Boolean, required: true},
    toggleModal: {
      type: Function as PropType<(...args) => void>,
      required: true,
    },
    forcedCanLoadCapa: {type: Boolean, default: false},
    pgOfs: {type: Array, default: () => []},
    loadCharge: {
      type: Function as PropType<(force: boolean, cb: () => void) => void>,
      default: () => ({}),
    },
    simulationPeriod: {
      type: Object as PropType<OplitSimulationPeriod>,
      default: () => ({}),
    },
    // contains IDs of `exportItems` that shouldn't be displayed
    // FIXME: OPL-3727
    disabledExports: {type: Array as PropType<string[]>, default: () => []},
  },
  components: {Modal, FCheck},
  setup() {
    const APIStore = useAPIStore();
    const schedulingStore = useSchedulingStore();
    const eventsStore = useEventsStore();
    const parameterStore = useParameterStore();
    const mainStore = useMainStore();

    const {getDailyDelay} = APIStore;
    const {selectedSimulation} = storeToRefs(schedulingStore);
    const {loadSimpleEvents} = eventsStore;
    const {clientFirstShift, importParsingRules} = storeToRefs(parameterStore);
    const {loadImportParsingRules} = parameterStore;

    const {
      userData,
      simulation,
      perimeters,
      isScheduling,
      apiClient,
      sectorTree,
      stations,
      team,
      mergedParameters,
      hasStock,
      activePerim,
      clientParameters,
    } = storeToRefs(mainStore);
    const {getMsdFieldsValues} = mainStore;

    const {generateCSV, getClientNameByOplitHeader} = useExportGenerateCSV();
    const {
      getCurrentActiveSchedulingCenters,
      getCurrentExportedLoadFilters,
      hasActiveFilters,
    } = useSimulationDataExport();
    const {isCurrentGlobalTab} = useGlobalTabs();

    const exportItems = ref<ExportEntry[]>();
    const shouldFilterExports = ref(false);
    const loading = ref(false);
    const schedulingFilters = ref(getCurrentExportedLoadFilters());
    const schedulingCenters = ref(getCurrentActiveSchedulingCenters());

    const canConfirmModal = computed(
      () =>
        exportItems.value.some(({checked}) => checked) &&
        !Array.isArray(importParsingRules.value),
    );

    const shouldFilterSchedulingCenters = computed(
      () => !isCurrentGlobalTab("ofs") && shouldFilterExports.value,
    );

    return {
      loading,
      shouldFilterExports,
      getDailyDelay,
      generateCSV,
      userData,
      simulation,
      perimeters,
      isScheduling,
      apiClient,
      sectorTree,
      stations,
      team,
      mergedParameters,
      hasStock,
      activePerim,
      getMsdFieldsValues,
      selectedSimulation,
      exportItems,
      loadSimpleEvents,
      clientParameters,
      clientFirstShift,
      importParsingRules,
      loadImportParsingRules,
      canConfirmModal,
      getClientNameByOplitHeader,
      schedulingFilters,
      schedulingCenters,
      hasActiveFilters,
      shouldFilterSchedulingCenters,
    };
  },
  computed: {
    $_options: {
      get: function (): boolean {
        return this.show;
      },
      set: function () {
        this.toggleModal();
      },
    },
    computedSimulationPeriod(): OplitSimulationPeriod {
      const {simulationPeriod, mergedParameters} = this;
      const {startDate: start} = simulationPeriod;
      if (start) return simulationPeriod;

      const todayDate = moment().format("YYYY-MM-DD");

      const {
        nbPeriodeHisto = 2,
        netPeriodeHisto = 2,
        nbPeriodePrev = 4,
        maille = "month",
      } = mergedParameters || {};
      const startDate = moment(todayDate || undefined)
        .startOf(maille)
        .subtract(nbPeriodeHisto, maille)
        .format("YYYY-MM-DD");
      const endDate = moment(startDate)
        .add(netPeriodeHisto + nbPeriodePrev, maille)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      return {startDate, endDate, maille};
    },
    filteredExportItems(): ExportEntry[] {
      if (!this.disabledExports.length) return this.exportItems;

      return this.exportItems.filter(
        ({id}) => !this.disabledExports.includes(id),
      );
    },
  },
  created() {
    this.loadImportParsingRules();

    if (this.isScheduling) {
      this.exportItems = [
        {
          id: "ops_details",
          label: this.$t("exports.ops_details"),
          tracker: "OPs Detailed Data Extracted",
          checked: false,
          exportFn: this.exportOpsDetail,
        },
        {
          id: "ofs_details",
          label: this.$t("exports.ofs_details"),
          tracker: "OFs Detailed Data Extracted",
          checked: false,
          exportFn: this.exportOfsDetail,
        },
      ];
    } else {
      this.exportItems = [
        {
          id: "load",
          label: this.$t("exports.load"),
          tracker: "Load Data Extracted",
          checked: false,
          exportFn: this.exportCharge,
        },
        {
          id: "prod",
          label: this.$t("exports.prod"),
          tracker: "Prod Output Data Extracted",
          checked: false,
          exportFn: this.exportProd,
        },
        {
          id: "event_list",
          label: this.$t("exports.event_list"),
          checked: false,
          exportFn: this.exportEvents,
        },
        {
          id: "simple_event",
          label: this.$t("exports.simple_event"),
          checked: false,
          exportFn: this.exportSimpleEvent,
        },
        {
          id: "capacity",
          label: this.$t("exports.capacity"),
          checked: false,
          exportFn: this.exportCapaWMevents,
        },
        {
          id: "initial_stock",
          label: this.$t("exports.initial_stock"),
          checked: false,
          exportFn: this.exportDelayAndStocks,
        },
      ];
    }
  },
  methods: {
    onValidate: _.throttle(async function () {
      this.loading = true;
      const exportItems: ExportEntry[] = this.exportItems.filter(
        (item) => item.checked,
      );

      try {
        const promises = exportItems.map((item) => item.exportFn());

        await Promise.all(promises);

        exportItems.forEach(({tracker, label}) =>
          this.$segment.value.track(
            this.isScheduling ? "[ORDO] " : "[PDP] " + (tracker ?? label),
          ),
        );
      } catch (err) {
        loggerHelper.error(err);
        this.$openSnackbar(null, "GENERIC_ERROR");
      }
      this.loading = false;
      this.toggleModal();
    }, 3000),
    exportCharge(): Promise<void> {
      const {pgOfs: ofs, importParsingRules} = this;

      const exportFn = (pg_ofs: Record<string, any>) => {
        if (!pg_ofs?.filter(({id}) => !!id)?.length) return false;

        let jsonData = [];
        if (importParsingRules?.LOAD) {
          const mapping = this.getClientNameByOplitHeader("LOAD", true);

          jsonData = pg_ofs.map((op) => {
            const {
              op_id,
              of_id,
              day_date,
              op_name,
              op_sequence,
              ref_article,
              load,
              quantite_of,
              quantite_op_2,
              order_number,
              fault,
              op_status,
              customer,
              internal_extra_infos,
              extra_infos,
            } = op;
            const {unite_op, unite_of, unite_op_2, pdc} =
              internal_extra_infos || {};

            return {
              // Fields covered by standard parsing, explicit since the order is
              // important according to OPL-6082
              op_id,
              erp_id: pdc,
              of_id,
              load,
              quantite_of,
              day_date,
              op_status,
              op_sequence,
              ref_article,
              op_name,
              unite_op,
              unite_of,
              quantite_op_2,
              unite_op_2,
              order_number,
              customer,
              fault,

              // We return everything else, we'll filter out stuff
              // afterwards to allow for added columns
              ...extra_infos,
            };
          });

          jsonData = translateRawExportedOplitHeaders(
            jsonData,
            mapping,
            EXPORT_LOAD_BYPASS_MAPPING,
          );
        } else {
          jsonData = pg_ofs.map((x: any) => {
            if (!x.data?.length) return {};
            const rightIdx = x.data.findIndex(
              (y: SimulationLoadSeriesData) => y.quantite_op,
            );

            const tooltips = ofTooltipMatch.reduce((acc: any, curr: any) => {
              const value = x[curr.field];
              if ([null, undefined].includes(value)) return acc;
              return {...acc, [curr.label]: value};
            }, {});

            const date_lancement_finale = x.data[rightIdx]?.label
              ? moment(x.data[rightIdx].label).format("YYYY-MM-DD")
              : null;
            const result = {
              op_id: x.id,
              of_id: x.name,
              quantite_op: x.quantite,
              date_lancement: Array.isArray(x.datelancement)
                ? x.datelancement[0]
                : x.datelancement,
              ...(date_lancement_finale && {date_lancement_finale}),
              unite_op: x.unite || x.unit,
              ...tooltips,
            };
            return result;
          });
        }

        this.generateCSV(jsonData, "Charge OFs");
      };

      if (!ofs?.filter(({id}) => !!id)?.length)
        return this.loadCharge(true, exportFn);

      exportFn(ofs);
    },
    async exportProd() {
      const {sectorTree} = this;
      const {canUseSector} = usePGComputedProperties({sectorTree});
      if (!canUseSector.value) return;

      const results = await this.apiClient.allProdFn(
        sectorTree.id,
        {
          startDate: "2020-01-01",
          endDate: moment().format("YYYY-MM-DD"),
        },
        this.team?.id || "",
        true,
      );
      if (!results?.length) return;
      const mappedResults = results.map(
        (x: {date: string; qte: number; import_date: string}) => {
          const {
            date: date_production,
            qte: quantite_produite,
            import_date,
          } = x;
          return {
            date_production,
            quantite_produite,
            import_date,
          };
        },
      );

      const mapping = this.getClientNameByOplitHeader("PROD", true);
      const translatedResults = translateRawExportedOplitHeaders(
        mappedResults,
        mapping,
        EXPORT_PROD_BYPASS_MAPPING,
      );

      this.generateCSV(translatedResults, "Prod");
    },
    async exportDelayAndStocks() {
      const {
        sectorTree: sector_tree,
        userData,
        canUseSector,
        simulation,
      } = this;
      if (!canUseSector) return;
      const {client_id} = userData;
      const {id: secteur_id} = sector_tree;
      const {startDate, endDate: dumEnd} = this.computedSimulationPeriod;
      if (!startDate || !secteur_id) return;
      const endDate = moment(dumEnd).add(1, "days").format("YYYY-MM-DD");

      const {id: simulation_id, forceToday: today_date} = simulation;

      const length = moment(endDate).diff(startDate, "days");
      const defined_periods = [...new Array(length + 1)].map(
        (_x: any, i: number) => {
          const start_date = moment(startDate)
            .add(i, "days")
            .startOf("day")
            .format("YYYY-MM-DD");
          const end_date = moment(start_date).endOf("day").format("YYYY-MM-DD");
          return {start_date, end_date};
        },
      );

      const params = {
        simulation_id,
        endDate,
        sector_tree,
        load_auto_orga: true,
        today_date,
        defined_periods,
        maille: "day",
      };
      const import_types = ["initial_stock"];
      if (
        [
          "lUNX07wlZHAtoexlzsNU", // Melisey
          "bkEfcYyUTImjXHU3xtsO", // Delle
          "lHLkwWtcv2jWEKq4M5lX", // Dasle
        ].includes(client_id)
      )
        import_types.push("initial_stock_pf");

      const [{pg_retard, raw_delay}, importIds] = await Promise.all([
        this.getDailyDelay(params),
        this.apiClient.importIdFn(simulation_id, import_types),
      ]);
      if (!pg_retard?.length) return;
      // this.generateCSV(
      //   pg_retard.map((x: any, idx: number) => ({
      //     day_date: defined_periods[idx]?.start_date,
      //     delay: Math.max(0, +x),
      //   })),
      //   "Retard",
      // );

      //stock now
      const initial_stocks = await this.apiClient.initialStockFn(
        secteur_id,
        importIds,
        {detailed: true},
      );
      if (initial_stocks.length)
        this.generateCSV(initial_stocks, "Initial stock");

      const initial_stock = _.sum(
        initial_stocks.map((x: any) => +x.initial_stock),
      );

      const stock_values: {
        pg_cmj_stock: number;
        pg_target_stock: number;
      } =
        (await this.getMsdFieldsValues({
          fields: ["cmj_stock", "target_stock"],
          parsedPeriod: {startDate, endDate},
          sector_tree,
          simulation,
        })) ?? {};

      if (
        [undefined, null].includes(initial_stock) &&
        [undefined, null].includes(stock_values.pg_cmj_stock)
      )
        return;

      const projected_stock = raw_delay.map((x: any) => {
        const {day_date, delay} = x;
        const stock_pieces = -delay + initial_stock || 0;
        return {
          day_date,
          stock_pieces,
          stock_days: Math.max(
            0,
            stock_pieces / (stock_values.pg_cmj_stock || 1),
          ),
          target_stock_pieces:
            stock_values.pg_target_stock * stock_values.pg_cmj_stock,
          target_stock_days: stock_values.pg_target_stock,
          cmj_stock: stock_values.pg_cmj_stock,
        };
      });
      this.generateCSV(projected_stock, "Stocks");
    },
    async exportSimpleEvent() {
      const {canUseSimulation} = usePGComputedProperties();

      if (!canUseSimulation.value) return;

      const [error, simpleEvents] = await this.loadSimpleEvents({
        parsedPeriod: this.computedSimulationPeriod,
      });

      if (error) loggerHelper.error(error);

      const data: any[] = simpleEvents.map(
        ({
          impact,
          pcs,
          start_date,
          end_date,
          qualification_text,
          title,
          updated_at,
        }) => ({
          title,
          qualification: qualification_text,
          secteur_name: pcs,
          impact,
          start_date,
          end_date,
          updated_at,
        }),
      );

      this.generateCSV(data, "Imprévus");
    },
    async exportCapaWMevents() {
      const {
        userData,
        sectorTree: sector_tree,
        simulation,
        forcedCanLoadCapa,
        perimeters,
      } = this;
      const {client_id} = userData || {};
      const {startDate, endDate} = this.computedSimulationPeriod || {};
      const {id: simulation_id, forceToday: today_date} = simulation || {};
      if (!forcedCanLoadCapa) return;

      const getAllChildren = (serie: any, results: any = []) => {
        if (serie.children?.length) {
          return [
            ...results,
            {...serie},
            ...serie.children.reduce((acc: any, curr: any) => {
              return [...acc, ...getAllChildren(curr)];
            }, []),
          ];
        } else return [...results, {...serie}];
      };
      const allChildren = getAllChildren(sector_tree);
      const promises = allChildren.map((sector: any) => {
        const params = {
          query_type: "capa",
          client_id,
          sector,
          startDate,
          endDate,
          simulation_id: simulation_id,
          today_date,
          load_auto_orga: true,
          is_verbose: true,
        };
        return this.apiClient.getCustomCapa(params);
      });
      const results = await Promise.all(promises);
      const data = results.reduce((acc: any, curr: any, idx: number) => {
        const {periods} = curr || {};
        if (!periods?.length) return;
        const sector = allChildren[idx];
        const {name: sector_name, id, collection} = sector;
        const erp_id = perimeters[collection].find(
          (x: any) => x.id === id,
        )?.movex_id;
        const child_data = periods
          .filter((x: any) => x.includesNew)
          .map((x: any) => {
            const {
              startDate: start_date,
              endDate: end_date,
              workdays,
              capa: total_capa,
              daily_capa,
              base_formula: theoric_formula,
              formula: parsed_formula,
              oldEvents,
              field_values,
            } = x;
            const {total_days} = workdays || {};
            const modifiedFields = _.reverse(oldEvents).reduce(
              (acc: any, curr: any) => {
                if (curr.tag !== "sector" || !curr.is_event) return acc;
                _.set(acc, curr.field, curr.value);
                return acc;
              },
              {},
            );
            return {
              sector_name,
              erp_id,
              start_date,
              end_date,
              daily_capa,
              total_days,
              total_capa,
              theoric_formula,
              parsed_formula,
              ...(field_values || {}),
              ...modifiedFields,
            };
          });
        return [...acc, ...child_data];
      }, []);
      if (!data.length) return;
      this.generateCSV(data, "Capacité", {pushKeysToFirstRow: true});
    },
    async exportEvents() {
      const {
        sectorTree: sector_tree,
        simulation,
        computedSimulationPeriod,
        forcedCanLoadCapa,
        stations,
      } = this;
      const {id: simulation_id} = simulation || {};
      if (!forcedCanLoadCapa) return;

      const events = await this.apiClient.eventsFn(
        simulation_id,
        sector_tree.id,
        sector_tree.type,
        computedSimulationPeriod,
      );

      const event_ids = [...new Set(events.map((x: any) => x.id))];
      if (!event_ids.length) return [];

      const eventFields = await this.apiClient.postRequest(
        `/api/simulations/${simulation_id}/event-fields`,
        {event_ids},
      );

      const data: any[] = events.reduce((acc: any[], curr: any) => {
        const {
          id,
          impact,
          pcs,
          start_date,
          end_date,
          qualification_text,
          title,
          updated_at,
          secteur_id,
        } = curr;
        // if (type !== "capa") return acc;
        const secteur = stations.find((p: any) => p.id === secteur_id);
        let eventFieldsMatch = eventFields.filter(
          (e: any) => e.event_id === id,
        );
        if (!eventFieldsMatch.length)
          eventFieldsMatch = [{field: "", value: ""}];

        eventFieldsMatch.forEach((eventFieldMatch: any) => {
          const {field, value} = eventFieldMatch;
          acc.push({
            id,
            title,
            qualification: qualification_text,
            secteur_name: pcs,
            secteur_path: secteur?.text,
            field,
            value,
            impact,
            start_date,
            end_date,
            updated_at,
          });
        });
        return acc;
      }, []);
      this.generateCSV(data, "Evenements");
    },
    async exportOpsDetail(): Promise<void> {
      const {
        userData,
        selectedSimulation: simulation,
        hasStock,
        shouldFilterExports,
        shouldFilterSchedulingCenters,
        schedulingFilters,
        schedulingCenters,
      } = this;
      const {client_id} = userData || {};
      const {id: simulation_id} = simulation || {};
      if (!client_id || !simulation_id) return;

      try {
        const requestResults = await this.apiClient.postRequest(
          `/api/simulations/${simulation_id}/ops`,
          {
            full: true,
            with_of_id: true,
            with_prod_target: hasStock,
            filters: shouldFilterExports ? schedulingFilters : [],
            secteur_ids: shouldFilterSchedulingCenters ? schedulingCenters : [],
            translation_mapping: this.getClientNameByOplitHeader(),
          },
        );
        const {download_url, filename} = requestResults;

        if (filename && download_url) clickOnLink(download_url, filename);
        else this.$openSnackbar(null, "GENERIC_ERROR");
      } catch (error) {
        this.$openSnackbar(null, "GENERIC_ERROR");
      }
    },
    async exportOfsDetail(): Promise<void> {
      const {
        userData,
        selectedSimulation: simulation,
        hasStock,
        shouldFilterExports,
        shouldFilterSchedulingCenters,
        clientParameters,
        schedulingFilters,
        schedulingCenters,
      } = this;
      const {client_id} = userData || {};
      const {id: simulation_id} = simulation || {};
      if (!client_id || !simulation_id) return;

      try {
        const requestResults = await this.apiClient.postRequest(
          `/api/simulations/${simulation_id}/ofs`,
          {
            full: true,
            with_of_id: true,
            with_prod_target: hasStock,
            compute_predictions: clientParameters.can_see_oplit_date,
            filters: shouldFilterExports ? schedulingFilters : [],
            secteur_ids: shouldFilterSchedulingCenters ? schedulingCenters : [],
            translation_mapping: this.getClientNameByOplitHeader(
              "LOAD",
              false,
              true,
            ),
            // should_check_component_shortage
          },
        );
        const {download_url, filename} = requestResults;

        if (filename && download_url) clickOnLink(download_url, filename);
        else this.$openSnackbar(null, "GENERIC_ERROR");
      } catch (error) {
        this.$openSnackbar(null, "GENERIC_ERROR");
      }
    },
  },
});
</script>

<style scoped lang="scss">
:deep(.export-modal) {
  padding: 0;
}
:deep(.f-modal-content) {
  --g-modal-spacing: 0px;
  > *:not(.v-progress-circular):not(:last-child)::after {
    border-bottom: none;
  }
}
hr {
  background: rgb(var(--v-theme-newDisableText));
}
</style>
