import {unref} from "vue";
import {storeToRefs} from "pinia";
import {useSchedulingStore} from "@/stores/schedulingStore";
import {useCustomerInterfaceStore} from "@/domains/customer-interface/stores/customerInterfaceStore";
import {SchedulingTag, getDefaultTagObject} from "@oplit/shared-module";

export function useSchedulingTag() {
  const {schedulingTags} = storeToRefs(useSchedulingStore());
  const {customerInterfaceTags} = storeToRefs(useCustomerInterfaceStore());

  /**
   * returns the populated tag item from the client tags
   * fallbacks to a default configuration for previously-configured tags (strings only)
   */
  function getSchedulingTag(tag: string | SchedulingTag, forceDisplay = false) {
    if (typeof tag !== "string") return tag;
    const populatedTag =
      unref(schedulingTags).find(({id}) => id === tag) ||
      unref(customerInterfaceTags).find(({id}) => id === tag);
    if (populatedTag || !forceDisplay) return populatedTag;
    return getDefaultTagObject(tag);
  }

  return {getSchedulingTag};
}
