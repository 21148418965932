import {computed, ref, unref} from "vue";
import {defineStore, storeToRefs} from "pinia";
import _ from "lodash";
import moment from "moment";
import {getResponsivePeriod, pgOpsMapFn} from "@/tscript/utils/schedulingUtils";
import {
  DATE_DEFAULT_FORMAT,
  SCHEDULING_GANTT_MESHES_OPTIONS,
} from "@/config/constants";
import type {CustomPeriod, GanttableEntity, Sector} from "@/interfaces";
import {useClientId} from "@/composables/useClientId";
import {useMainStore} from "./mainStore";
import {useSchedulingStore} from "./schedulingStore";
import {useSectorTree} from "@/composables/useSectorTree";
import {DailyLoadNew} from "@oplit/shared-module";

export const useGanttStore = defineStore("gantt", () => {
  const mainStore = useMainStore();
  const schedulingStore = useSchedulingStore();

  const ganttMesh = ref<string>(SCHEDULING_GANTT_MESHES_OPTIONS.month);
  const ganttPeriodStartDate = ref<CustomPeriod>({
    startDate: moment().format(DATE_DEFAULT_FORMAT),
    /**
     * is used for easier retrieval of the currently-viewed month
     * this was made because we can not simply get the "current month" from the period
     * sent by the original logic, since it often starts at the previous month (due to startOf('week'))
     * and also may end the next month (due to endOf('week'))
     * FIXME: usage of monthDate may be deprecated
     */
    monthDate: moment().format(DATE_DEFAULT_FORMAT),
  });
  const ganttSelectedEntities = ref<GanttableEntity[]>([]);
  const ganttTableHeaders = ref<string[]>([]);
  const ganttHideWeekend = ref<boolean>(false);
  const ganttIsSlipperyView = ref<boolean>(false);
  const ganttIsSyntheticView = ref<boolean>(false);
  const ganttIsPastDelayShown = ref<boolean>(false);
  const ganttEntitiesToReload = ref<{sectorIds?: string[]; ofIds?: string[]}>(
    {},
  );
  const ganttSectorCapacities = ref<Record<string, number>>({});
  /**
   * used as a watched variable on GanttRow to perform updates on multiple entities\
   * the update on multiple entities is only available for the OF view, since the selectedOperations
   * are reset when clicking on different ones in GanttDiagram/onOperationClick
   */
  const ganttDateUpdate = ref<{value?: number}>({});

  const ganttCellWidth = computed<number>(() => {
    if (ganttMesh.value === SCHEDULING_GANTT_MESHES_OPTIONS.month) return 40;
    return (
      (Object.keys(SCHEDULING_GANTT_MESHES_OPTIONS).indexOf(ganttMesh.value) +
        1) *
      48
    );
  });

  /**
   * @returns an array containing the start date and the end date (as Moment objects) based on the chosen gantt mesh
   */
  const ganttPeriod = computed<[moment.Moment, moment.Moment]>(() => {
    const {startDate, bypassSlipperyView} = ganttPeriodStartDate.value;

    /**
     * - 48 is the value in px passed for the different gantt views
     * this value is tied to the CSS variable values inside GanttDiagram.vue
     * - the staticOffset value is :
     *    - the value associated to the first column (250 + 1px border) which is always displayed
     * we ignore the fact that details/tags headers can be disabled because
     * having additional data is not wrong & it adds unneeded complexity
     *    - the left padding applied on the wrapper of the GanttDiagram, usually OplitContainer
     *      as per the above explanation and since this value being a CSS variable changing value responsive-ly
     *      we simplify the process by passing its maximal value directly (32)
     */
    const p = function getGanttResponsivePeriod(
      period: [moment.Moment, moment.Moment],
    ) {
      return getResponsivePeriod({
        period,
        periodCellWidth: ganttCellWidth.value,
        staticOffset: 251 + 32,
        isWeekendHidden: ganttHideWeekend.value,
      });
    };

    /**
     * the slippery view constructs dates from the current date onwards
     */
    if (ganttIsSlipperyView.value) {
      const args =
        ganttMesh.value === "two-weeks" ? [2, "week"] : [1, ganttMesh.value];

      /**
       * the logic in FDatePicker from which the gantt period is set is made so that in month view the slippery view starts at the start of the week
       * for the gantt month view we want to start at the exact date : the below snippet is for that matter
       */
      let actualStartDate = moment(startDate);
      if (!bypassSlipperyView && moment(startDate).isSame(moment(), "week"))
        actualStartDate = moment();

      const finalStartDate = actualStartDate.subtract(
        +ganttIsPastDelayShown.value,
        "days",
      );

      const period: [moment.Moment, moment.Moment] = [
        moment(finalStartDate),
        moment(finalStartDate)
          .add(...args)
          .subtract(1, "days"),
      ];

      return p(period);
    }

    switch (ganttMesh.value) {
      case "two-weeks":
        return p([
          moment(startDate).startOf("week"),
          moment(startDate).add(1, "week").endOf("week"),
        ]);
      default: {
        return p([
          moment(startDate).startOf(
            ganttMesh.value as moment.unitOfTime.StartOf,
          ),
          moment(startDate).endOf(ganttMesh.value as moment.unitOfTime.StartOf),
        ]);
      }
    }
  });
  /**
   * @returns the number of cells displayed within the diagram, given the chosen gantt mesh
   */
  const ganttCellCount = computed<number>(() => {
    const [startDate, endDate] = ganttPeriod.value;
    return moment(endDate).diff(moment(startDate), "days") + 1;
  });
  /**
   * @returns an array of formatted dates corresponding to the days displayed in the calendar for the current gantt mesh
   */
  const ganttDaysArray = computed<string[]>(() => {
    const [startDate] = ganttPeriod.value;
    const originalArray = Array.from(
      {length: ganttCellCount.value},
      (_, index: number): moment.Moment => moment(startDate).add(index, "days"),
    );
    const f = function formatArray(array: Array<moment.Moment>) {
      return Array.from(array, (date: moment.Moment) =>
        date.format(DATE_DEFAULT_FORMAT),
      );
    };

    if (!ganttHideWeekend.value) return f(originalArray);
    return f(
      originalArray.filter(
        (date: moment.Moment) => ![6, 7].includes(date.isoWeekday()),
      ),
    );
  });
  /**
   * @returns the moment.format for the readability of getGanttDaysArray()
   */
  const ganttDaysMomentFormat = computed<string>(() => {
    switch (ganttMesh.value) {
      case SCHEDULING_GANTT_MESHES_OPTIONS["two-weeks"]:
        return "ddd D";
      case SCHEDULING_GANTT_MESHES_OPTIONS.week:
        return "ddd D MMMM";
      default:
        return "D";
    }
  });

  const loadGanttOperations = async ({
    sectors,
    params = {},
  }: {
    sectors: Sector[];
    params: {filter_done_ops?: boolean; startDateForDelay?: string};
  }) => {
    try {
      const {clientId} = useClientId();
      const {apiClient} = storeToRefs(mainStore);
      const {getCtxSectorOrderedCalendars} = mainStore;
      const {selectedSimulation} = storeToRefs(schedulingStore);
      const simulationId = selectedSimulation.value.id;
      const {filter_done_ops = false, startDateForDelay} = params;

      if (!clientId.value || !simulationId || !sectors?.length)
        return [null, []];

      const sectorsIDs = Array.from(
        sectors,
        (sector: Sector) => sector.secteur_id,
      ).filter(Boolean);

      const [startDate, endDate] = Array.from(
        ganttPeriod.value,
        (momentItem: moment.Moment) => momentItem.format(DATE_DEFAULT_FORMAT),
      );

      const results: DailyLoadNew[] = await apiClient.value.postRequest(
        `/api/simulations/gantt-loads`,
        {
          simulation_id: simulationId,
          secteur_ids: sectorsIDs,
          original_start_date: startDate,
          actual_start_date: startDateForDelay || startDate,
          end_date: endDate,
          is_weekend_hidden: ganttHideWeekend.value,
          calendars: getCtxSectorOrderedCalendars(),
          filter_done_ops,
          should_check_component_shortage:
            mainStore.clientParameters.enable_scheduling_components,
        },
      );
      return [null, pgOpsMapFn(results, {keepOpsDone: !filter_done_ops})];
    } catch (error: unknown) {
      return [error, null];
    }
  };
  const loadCapaMultiSectors = async (
    sectors: Sector[],
    startDateForDelay?: string,
  ): Promise<[unknown, object]> => {
    try {
      const {clientId} = useClientId();
      const {perimeters, apiClient} = storeToRefs(mainStore);
      const {selectedSimulation, schedulingMachineCentersAndTags} =
        storeToRefs(schedulingStore);
      const simulationId = selectedSimulation.value.id;

      if (!clientId.value || !simulationId || !sectors?.length)
        return [null, []];
      const [startDate, endDate] = Array.from(
        ganttPeriod.value,
        (momentItem: moment.Moment) => momentItem.format(DATE_DEFAULT_FORMAT),
      );
      const promises = sectors.map(
        (
          sector: Sector & {secteur_collection: string; is_machine: boolean},
        ) => {
          const {
            secteur_id = "operations",
            secteur_collection,
            is_machine,
          } = sector || {};
          let sectorTree = {};
          if (is_machine) {
            sectorTree = schedulingMachineCentersAndTags.value.find(
              (m: any) => m.id === secteur_id,
            );
          } else {
            const match = (perimeters.value[secteur_collection] || []).find(
              (op: any) => op.id === secteur_id,
            );
            sectorTree = useSectorTree(match).sectorTree.value;
          }

          const params = {
            client_id: clientId.value,
            simulation_id: simulationId,
            startDate: startDateForDelay || startDate,
            endDate,
            sector: sectorTree,
            load_auto_orga: true,
          };
          return apiClient.value.getCustomDailyTotalCapa(params);
        },
      );
      const results = await Promise.all(promises);
      if (!results?.length) return [null, []];
      sectors.forEach((sector: Sector, idx: number) => {
        const capacities = results[idx];
        const capacity =
          +capacities.find(
            (capa) => +capa.daily_capa > 0 && !capa.real_daily_capa,
          )?.daily_capa || 0;
        ganttSectorCapacities.value[sector.secteur_id] = capacity;
      });
      const flatResults = _.flatten(results);
      const groupByDate = _.groupBy(flatResults, "day_date");
      const totalCapaObj = Object.keys(groupByDate).reduce(
        (acc: object, day_date: string) => {
          const capacities = groupByDate[day_date];
          const capacity = _.sumBy(capacities, (o) => +(o.daily_capa || 0));
          return {...acc, [day_date]: capacity};
        },
        {},
      );
      return [null, totalCapaObj];
    } catch (error: unknown) {
      return [error, null];
    }
  };
  const setGanttPeriodStartDate = (payload: CustomPeriod) => {
    const {startDate, periodArray} = payload;

    /**
     * the code below is made to retrieve the currently-viewed month from the periodArray,
     * that can start before and end after the actual current month
     * NB: we do not use the full periodArray since the addition of the getResponsivePeriod logic because
     * it fills the calendar depending on window screen
     */
    const consideredPeriodArray = periodArray.slice(0, 5);
    const dates = Array.from(
      consideredPeriodArray,
      ({start_date, end_date}: {start_date: string; end_date: string}) =>
        moment(start_date).isSame(moment(end_date), "month")
          ? moment(start_date).startOf("month").format(DATE_DEFAULT_FORMAT)
          : null,
    ).filter(Boolean);
    const countedDates = _.countBy(dates);
    const mostRepresentedDate = _.maxBy(
      Object.entries(countedDates),
      ([, occCount]: [date: string, occCount: number]) => occCount,
    )[0];

    // is used for easier retrieval of the currently-viewed month
    const monthDate = mostRepresentedDate || startDate;

    ganttPeriodStartDate.value = {...payload, monthDate};
  };
  const getStartDateForDelay = ({
    mesh,
    value,
  }: {
    mesh: "months" | "";
    value: number;
  }) =>
    mesh
      ? moment(unref(ganttPeriodStartDate).startDate)
          .subtract(value, mesh)
          .startOf("month")
          .format(DATE_DEFAULT_FORMAT)
      : "1970-01-01";
  function getDailyColorCssVar(date: string) {
    return `--daily-color--${date}`;
  }

  return {
    ganttMesh,
    ganttPeriodStartDate,
    ganttSelectedEntities,
    ganttTableHeaders,
    ganttHideWeekend,
    ganttIsSlipperyView,
    ganttIsSyntheticView,
    ganttIsPastDelayShown,
    ganttEntitiesToReload,
    ganttSectorCapacities,
    ganttCellWidth,
    ganttPeriod,
    ganttCellCount,
    ganttDaysArray,
    ganttDaysMomentFormat,
    loadGanttOperations,
    loadCapaMultiSectors,
    setGanttPeriodStartDate,
    ganttDateUpdate,
    getStartDateForDelay,
    getDailyColorCssVar,
  };
});
