import {IMPORT, ImportParsingType} from "@oplit/shared-module";

const ITEM_HEIGHT = 72; // 60px is height of FImportRow, 12 is arbitrary padding between virtual scroll items

const PARAMETERS_IMPORT_RULE_DISPATCH_OPERANDS = [
  "begins_with",
  "ends_with",
  "includes",
  "excludes",
  "equals",
  "different",
  "greater_than",
  "less_than",
  "greater_than_or_equal_to",
  "less_than_or_equal_to",
];

const PARAMETERS_IMPORT_RULE_FORMULA_CONDITION = [{or: "||"}, {and: "&&"}];

const MANDATORY_LOAD_MATCHING_FIELDS = IMPORT.LOAD.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_LOAD_STOCKS_MATCHING_FIELDS =
  IMPORT.LOAD_STOCKS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_LOAD_SCHEDULING_FIELDS =
  IMPORT.LOAD.MANDATORY_COLUMNS.SCHEDULING;
const MANDATORY_PROD_MATCHING_FIELDS = IMPORT.PROD.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_PROD_STOCKS_MATCHING_FIELDS =
  IMPORT.PROD_STOCKS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_PROD_SCHEDULING_FIELDS =
  IMPORT.PROD.MANDATORY_COLUMNS.SCHEDULING;
const MANDATORY_GAMMES_MATCHING_FIELDS =
  IMPORT.GAMMES.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_NOMENCLATURES_MATCHING_FIELDS =
  IMPORT.NOMENCLATURES.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_CMJ_MATCHING_FIELDS = IMPORT.CMJ.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_FP_STOCKS_MATCHING_FIELDS =
  IMPORT.FP_STOCKS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_WIP_STOCKS_MATCHING_FIELDS =
  IMPORT.WIP_STOCKS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_PURCHASE_ORDERS_MATCHING_FIELDS =
  IMPORT.PURCHASE_ORDERS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_CUSTOMERS_ORDERS_MATCHING_FIELDS =
  IMPORT.CUSTOMERS_ORDERS.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_TECHNO_FAMILIES_MATCHING_FIELDS =
  IMPORT.TECHNO_FAMILIES.MANDATORY_COLUMNS.MATCHING;
const MANDATORY_COMPONENTS_INITIAL_STOCK_MATCHING_FIELDS =
  IMPORT.COMPONENTS_INITIAL_STOCK.MANDATORY_COLUMNS.MATCHING;

// refactor this into a bigger object
const LOAD_MATCHING_FIELDS_TEMPLATE = IMPORT.LOAD.TEMPLATE;
const LOAD_STOCKS_MATCHING_FIELDS_TEMPLATE = IMPORT.LOAD_STOCKS.TEMPLATE;
const PROD_MATCHING_FIELDS_TEMPLATE = IMPORT.PROD.TEMPLATE;
const PROD_STOCKS_MATCHING_FIELDS_TEMPLATE = IMPORT.PROD_STOCKS.TEMPLATE;
const GAMMES_MATCHING_FIELDS_TEMPLATE = IMPORT.GAMMES.TEMPLATE;
const NOMENCLATURES_MATCHING_FIELDS_TEMPLATE = IMPORT.NOMENCLATURES.TEMPLATE;
const CMJ_MATCHING_FIELDS_TEMPLATE = IMPORT.CMJ.TEMPLATE;
const FP_STOCKS_MATCHING_FIELDS_TEMPLATE = IMPORT.FP_STOCKS.TEMPLATE;
const WIP_STOCKS_MATCHING_FIELDS_TEMPLATE = IMPORT.WIP_STOCKS.TEMPLATE;
const PURCHASE_ORDERS_MATCHING_FIELDS_TEMPLATE =
  IMPORT.PURCHASE_ORDERS.TEMPLATE;
const CUSTOMERS_ORDERS_MATCHING_FIELDS_TEMPLATE =
  IMPORT.CUSTOMERS_ORDERS.TEMPLATE;
const TECHNO_FAMILIES_MATCHING_FIELDS_TEMPLATE =
  IMPORT.TECHNO_FAMILIES.TEMPLATE;
const COMPONENTS_INITIAL_STOCK_MATCHING_FIELDS_TEMPLATE =
  IMPORT.COMPONENTS_INITIAL_STOCK.TEMPLATE;

const LEGACY_TO_NEW_FIELD_NAMES = {
  quantite_produite: "quantite_of_produite",
  parent_article_id: "parent",
  child_article_id: "enfant",
};
const LOAD_TO_DEMAND_FIELD_NAMES = {
  op_id: "id",
  date_lancement: "date_livraison",
  quantite_of: "quantite",
};

const IMPORT_TYPES = {
  LOAD: "ofs",
  PROD: "prod",
  PROD_PF: "prod_pf",
  PROD_ST: "prod_st",
  STOCK: "initial_stock",
  STOCK_PF: "initial_stock_pf",
  GAMMES: "gammes",
  NOMENCLATURES: "nomenclatures",
  CMJ: "cmj",
  PURCHASE_ORDERS: "purchase_orders",
  CUSTOMERS_ORDERS: "customers_orders",
  TECHNO_FAMILIES: "techno_families",
  COMPONENTS_INITIAL_STOCK: "components_initial_stock",
};

const IMPORT_STATUS = {
  INSERTED: "inserted",
  PENDING: "pending",
  FAILED: "failed",
  REMOVED: "removed",
  CACHING: "caching",
};

const IMPORT_LOGS_HEADERS_MAPPING = {
  scope: "Colonne",
  event_name: "Type d'erreur",
  row: "Ligne",
  // TODO: rename in backend directly and remove the mapping
  event_id: "import_flow_event_id",
};

const IMPORT_PARSING_TYPES: Record<ImportParsingType, ImportParsingType> = {
  LOAD: "LOAD",
  GAMMES: "GAMMES",
  PROD: "PROD",
  CMJ: "CMJ",
  NOMENCLATURES: "NOMENCLATURES",
  WIP_STOCKS: "WIP_STOCKS",
  FP_STOCKS: "FP_STOCKS",
  PURCHASE_ORDERS: "PURCHASE_ORDERS",
  CUSTOMERS_ORDERS: "CUSTOMERS_ORDERS",
  TECHNO_FAMILIES: "TECHNO_FAMILIES",
  COMPONENTS_INITIAL_STOCK: "COMPONENTS_INITIAL_STOCK",
};

export {
  ITEM_HEIGHT,
  PARAMETERS_IMPORT_RULE_DISPATCH_OPERANDS,
  PARAMETERS_IMPORT_RULE_FORMULA_CONDITION,
  MANDATORY_LOAD_MATCHING_FIELDS,
  MANDATORY_LOAD_SCHEDULING_FIELDS,
  MANDATORY_LOAD_STOCKS_MATCHING_FIELDS,
  MANDATORY_PROD_MATCHING_FIELDS,
  MANDATORY_PROD_SCHEDULING_FIELDS,
  MANDATORY_PROD_STOCKS_MATCHING_FIELDS,
  MANDATORY_GAMMES_MATCHING_FIELDS,
  MANDATORY_NOMENCLATURES_MATCHING_FIELDS,
  MANDATORY_CMJ_MATCHING_FIELDS,
  MANDATORY_FP_STOCKS_MATCHING_FIELDS,
  MANDATORY_WIP_STOCKS_MATCHING_FIELDS,
  MANDATORY_PURCHASE_ORDERS_MATCHING_FIELDS,
  MANDATORY_CUSTOMERS_ORDERS_MATCHING_FIELDS,
  MANDATORY_TECHNO_FAMILIES_MATCHING_FIELDS,
  MANDATORY_COMPONENTS_INITIAL_STOCK_MATCHING_FIELDS,
  LOAD_MATCHING_FIELDS_TEMPLATE,
  LOAD_STOCKS_MATCHING_FIELDS_TEMPLATE,
  PROD_MATCHING_FIELDS_TEMPLATE,
  PROD_STOCKS_MATCHING_FIELDS_TEMPLATE,
  GAMMES_MATCHING_FIELDS_TEMPLATE,
  NOMENCLATURES_MATCHING_FIELDS_TEMPLATE,
  CMJ_MATCHING_FIELDS_TEMPLATE,
  FP_STOCKS_MATCHING_FIELDS_TEMPLATE,
  WIP_STOCKS_MATCHING_FIELDS_TEMPLATE,
  PURCHASE_ORDERS_MATCHING_FIELDS_TEMPLATE,
  CUSTOMERS_ORDERS_MATCHING_FIELDS_TEMPLATE,
  TECHNO_FAMILIES_MATCHING_FIELDS_TEMPLATE,
  COMPONENTS_INITIAL_STOCK_MATCHING_FIELDS_TEMPLATE,
  IMPORT_TYPES,
  IMPORT_STATUS,
  IMPORT_LOGS_HEADERS_MAPPING,
  LEGACY_TO_NEW_FIELD_NAMES,
  LOAD_TO_DEMAND_FIELD_NAMES,
  IMPORT_PARSING_TYPES,
};
