<template>
  <v-snackbar
    :timeout="loading ? -1 : timeout"
    v-model="snackbar"
    :color="computedBackground"
    :transition="transition"
    :location="location"
    class="elevation-24 ma-0"
    :class="{
      'snackbar-wrapper': true,
      'snackbar-wrapper-lp': lowPriority,
      'snackbar-wrapper-feedback': overrideIcon,
    }"
    :data-testid="`fsnackbar-${type}`"
    :z-index="zIndex"
    @update:model-value="updateModel"
  >
    <div
      class="snackbar-content d-flex align-center justify-space-between gap-4 text-14"
    >
      <div class="d-flex align-center">
        <div
          :class="
            'snackbar-icon-wrapper d-flex align-center justify-space-around mr-4 snackbar-icon-' +
            type
          "
        >
          <template v-if="overrideIcon">
            <template v-if="type === 'done'"> 🤗 </template>
            <template v-else> ⏳ </template>
          </template>

          <template v-else>
            <vue-feather
              v-if="!loading"
              tag="div"
              size="24"
              :type="computedIcon"
              :stroke="variables[computedBackground]"
            />
            <v-progress-circular
              v-else
              indeterminate
              :color="computedBackground"
              size="24"
            />
          </template>
        </div>

        <slot>
          {{
            loading && !message ? loadingMessage : message || computedMessage
          }}
        </slot>
      </div>

      <div class="d-flex align-center gap-8">
        <FButton
          v-if="action && !loading"
          height="32"
          white
          outlined
          :data-testid="`fsnackbar-action-${type}`"
          @click="onActionButtonClick"
        >
          {{ actionLabel }}
        </FButton>

        <FButtonIcon
          v-if="!hideClose"
          size="32"
          white
          icon="x"
          :data-testid="`fsnackbar-close-${type}`"
          @click="updateModel(false)"
        />
      </div>
    </div>
  </v-snackbar>
</template>

<script scoped lang="ts">
import {defineComponent, inject, PropType, ref} from "vue";
import {storeToRefs} from "pinia";
import {FButtonIcon, FButton} from "@/components/Global/Homemade/Buttons";
import {FEEDBACK_COMPONENTS_DEFAULT_TYPE} from "@/config/constants";
import {useMainStore} from "@/stores/mainStore";
import {Segment} from "@/interfaces";

export default defineComponent({
  name: "figma-snackbar",
  components: {FButtonIcon, FButton},
  props: {
    modelValue: Boolean,
    hideClose: Boolean,
    loading: Boolean,
    location: {
      type: String as PropType<"top" | "right" | "bottom" | "left">,
      default: "bottom",
    },
    lowPriority: Boolean,
    type: {type: String, default: FEEDBACK_COMPONENTS_DEFAULT_TYPE},
    actionLabel: {type: String, default: "Action"},
    transition: String,
    loadingMessage: {type: String, default: "Chargement..."},
    action: {
      type: Function as PropType<(...args) => unknown>,
      default: null, // `null` init for conditional display of actions
    },
    message: {type: String, default: ""},
    timeout: {type: Number, default: 2000},
    overrideIcon: {type: Boolean, default: false},
    zIndex: {type: Number, default: 3333},
    onModelUpdate: {type: Function, default: () => void 0},
  },
  setup(props) {
    const mainStore = useMainStore();
    const {variables} = storeToRefs(mainStore);
    const snackbar = ref(false);

    const segment = inject<Segment>("segment");

    function onActionButtonClick() {
      segment.value.track("[PDP] Snackbar Action Clicked", {
        label: props.actionLabel,
      });

      props.action();
    }

    return {
      variables,
      snackbar,
      onActionButtonClick,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: function (val: boolean) {
        this.snackbar = val;
      },
    },
  },
  computed: {
    computedBackground() {
      if (this.overrideIcon && this.type !== "done") return "newSubText";
      if (this.loading) return "newPrimaryRegular";
      switch (this.type) {
        case "positive":
        case "done":
          return "newGreenRegular";
        case "negative":
          return "newPinkRegular";
        case "warning":
          return "newOrangeRegular";
        default:
          return "newPrimaryRegular";
      }
    },

    computedIcon() {
      switch (this.type) {
        case "positive":
          return "check";
        case "negative":
          return "x";
        case "warning":
          return "alert-triangle";
        default:
          return "info";
      }
    },

    computedMessage() {
      switch (this.type) {
        case "positive":
          return this.$t("Alert.Prefixes.success");
        case "negative":
          return this.$t("Alert.Prefixes.error");
        case "warning":
          return this.$t("Alert.Prefixes.warning");
        default:
          return this.$t("Alert.Prefixes.info");
      }
    },
  },
  methods: {
    updateModel(v: boolean) {
      this.snackbar = v;
      this.onModelUpdate(v);
    },
  },
});
</script>

<style lang="scss">
.v-snackbar.snackbar-wrapper {
  width: 100vw;
  position: fixed;

  & > div {
    margin: 16px;
  }

  &-lp {
    z-index: 50;
  }

  &-feedback {
    .snackbar-content {
      min-width: 550px;
      min-height: 72px;
    }
  }

  .v-snack__action {
    display: none;
  }

  .v-snack__content {
    padding: 6px 8px;
  }

  .snackbar-content {
    border-radius: 4px;
    min-width: 460px;
    min-height: 32px;
  }

  .snackbar-icon-wrapper {
    background-color: rgb(var(--v-theme-blanc));
    border-radius: 8px;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;

    &.snackbar-icon {
      &-informative {
        color: rgb(var(--v-theme-newPrimaryRegular));
      }

      &-positive {
        color: rgb(var(--v-theme-newGreenRegular));
      }

      &-negative {
        color: rgb(var(--v-theme-newPinkRegular));
      }

      &-warning {
        color: rgb(var(--v-theme-newOrangeRegular));
      }
    }
  }
}
</style>
