<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {SchedulingOperation} from "@/interfaces";

interface OperationComponentShortageProps {
  operation: SchedulingOperation;
}

defineProps<OperationComponentShortageProps>();

const {t} = useI18n();
</script>

<template>
  <OplitIcon
    v-if="operation?.has_component_shortage"
    v-tooltip="t('OperationComponentShortage.tooltip')"
    name="puzzle"
    size="16px"
    background-color="red-700"
  />
</template>

<style scoped lang="scss">
.with-background {
  padding: 4px;
  border-radius: 4px;
}
</style>
