<script setup lang="ts">
import {ref} from "vue";
import FChip from "@/components/Global/Homemade/Commons/FChip.vue";
import OperationsActionsMenu from "@/components/Scheduling/Operations/OperationsActionsMenu.vue";
import {TEST_IDS} from "@/config/constants";

interface OperationsCardsGroupWrapperProps {
  count?: number;
  isSelected?: boolean;
  operationsActionsMenuProps?: InstanceType<
    typeof OperationsActionsMenu
  >["$props"];
}

withDefaults(defineProps<OperationsCardsGroupWrapperProps>(), {
  operationsActionsMenuProps: () =>
    ({} as OperationsCardsGroupWrapperProps["operationsActionsMenuProps"]),
});

const emit = defineEmits<{
  (e: "move-operations", {code});
  (e: "toggle", isToggled: boolean);
  (e: "click");
}>();

const isToggled = ref(false);

function moveOperations(code: string) {
  emit("move-operations", {code});
}
function toggleDefault() {
  isToggled.value = !isToggled.value;

  emit("toggle", isToggled.value);
}
</script>

<template>
  <div
    :class="{selected: isSelected}"
    class="operations-cards-group-wrapper__container"
    @click.prevent
  >
    <div class="operations-cards-group-wrapper" @click="() => emit('click')">
      <div class="operations-cards-group-wrapper__top-left">
        <div class="fd-flex-center gap-4 overflow-hidden">
          <slot name="prepend-top-left" />

          <span class="fbody-2 text-ellipsis semi-bold flex-1">
            <slot name="top-left" />
          </span>
        </div>

        <slot name="append-top-left" />
      </div>

      <div class="fbody-2">
        <slot name="bottom-left" />
      </div>

      <div class="operations-cards-group-wrapper__top-right">
        <FChip class="fbody-2" medium>
          {{ count }}
        </FChip>
      </div>

      <vue-feather
        :type="isToggled ? 'chevron-up' : 'chevron-down'"
        class="operations-cards-group-wrapper__bottom-right"
        :data-testid="
          TEST_IDS.SCHEDULING_OPERATIONS_CARDS_GROUP_WRAPPER__TOGGLE_ICON
        "
        @click.stop="() => toggleDefault()"
      />
    </div>

    <div v-if="isToggled" class="operations-cards-group-wrapper__content">
      <slot />
    </div>

    <OperationsActionsMenu
      v-bind="operationsActionsMenuProps"
      :show-actions-menu="
        'showActionsMenu' in operationsActionsMenuProps
          ? operationsActionsMenuProps.showActionsMenu
          : isSelected
      "
      @trigger-key-event="moveOperations"
    />
  </div>
</template>

<style scoped lang="scss">
.operations-cards-group-wrapper__container {
  --operations-cards-group-wrapper-spacing: 8px;

  &.selected {
    background-color: rgb(var(--v-theme-newHover)) !important;
    border-color: rgb(var(--v-theme-newPrimaryRegular)) !important;
  }

  &:not(:first-child) {
    border-top: 2px solid rgb(var(--v-theme-newSelected));
  }

  & .operations-cards-group-wrapper {
    display: grid;
    grid-template:
      "top-left top-right"
      "bottom-left bottom-right";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    gap: 4px;
    padding: var(--operations-cards-group-wrapper-spacing);
    color: rgb(var(--v-theme-newSubText));
    cursor: pointer;

    & .operations-cards-group-wrapper__top-left {
      grid-area: top-left;
      display: flex;
      align-items: center;
      gap: 8px;
      overflow: hidden;

      & > :first-child {
        margin-right: auto;
      }
    }

    & .operations-cards-group-wrapper__top-right {
      grid-area: top-right;
      justify-self: center;
    }

    & .operations-cards-group-wrapper__bottom-right {
      grid-area: bottom-right;
      cursor: pointer;
      justify-self: center;
    }
  }

  & .operations-cards-group-wrapper__content {
    display: flex;
    flex-direction: column;
    gap: var(--operations-cards-group-wrapper-spacing);
  }
}
</style>
