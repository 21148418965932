import {ref} from "vue";
import {storeToRefs} from "pinia";

import {useMainStore} from "@/stores/mainStore";

const SEPARATOR = "_";

// Incrementing this will reset all local storage values for all clients
const LOCAL_STORAGE_SHARED_VERSION = ref<number>(0);
// Incrementing this will reset all local storage values for a specific client
const LOCAL_STORAGE_VERSION_BY_CLIENT = ref<Record<string, number>>({
  QR4UAKY1E4uLZhIKFTah: 2, // SGS
  nr8BSWiomTTdzIG2WNkq: 2, // SGS V2
});

export const useLocalStorage = (localStorageIdentifier: string) => {
  const mainStore = useMainStore();
  const {userData} = storeToRefs(mainStore);

  const buildStorageKey = (key: string) => {
    const {client_id} = userData.value || {};

    const keyElements = [localStorageIdentifier, key];
    const version = `${LOCAL_STORAGE_SHARED_VERSION.value}.${
      LOCAL_STORAGE_VERSION_BY_CLIENT.value[client_id] ?? 0
    }`;

    // Once there's at least one refresh, the storage key becomes specific to the client
    // This will help with local debugging later on
    if (
      LOCAL_STORAGE_SHARED_VERSION.value ||
      LOCAL_STORAGE_VERSION_BY_CLIENT.value[client_id]
    ) {
      keyElements.unshift(client_id);
      keyElements.push(version);
    }

    return keyElements.join(SEPARATOR);
  };

  return {
    buildStorageKey,
  };
};
