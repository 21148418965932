<template>
  <v-dialog
    v-model="dialog"
    :transition="transition"
    content-class="dialog-wrapper"
    :data-testid="`fdialog-wrapper-${type}`"
    @update:model-value="(v) => $emit('update:model-value', v)"
  >
    <v-row class="dialog-content flex-nowrap gap-16">
      <div v-if="!hideIcon" :class="'dialog-icon dialog-icon-' + type">
        <vue-feather tag="div" size="100" :type="computedIcon" />
      </div>

      <div class="d-inline w-100">
        <h4
          v-if="header"
          :class="`dialog-header-${type}`"
          class="dialog-header mb-2"
        >
          {{ header }}
        </h4>
        <span v-if="!hidePrefix">
          <span :class="'dialog-prefix dialog-prefix-' + type">
            {{ prefix || computedPrefix }}
          </span>
          -
        </span>
        <slot>
          <span :class="{'text-newSubText': header}">{{ message }}</span>

          <div
            v-if="$slots['additional-body']"
            :class="{'mt-4': message || header}"
          >
            <slot name="additional-body" />
          </div>
        </slot>
      </div>
    </v-row>

    <v-row class="dialog-actions mt-8 justify-end align-center gap-4">
      <FButton
        v-bind="finalCancelBtnProps"
        :data-testid="`cancel-btn-${type}`"
        @click="onClose"
      >
        {{ cancelText || (action ? t("global.annuler") : t("global.close")) }}
      </FButton>

      <FButton
        v-if="action"
        v-bind="finalConfirmBtnProps"
        :data-testid="`validate-btn-${type}`"
        @click="triggerAction"
      >
        {{ validateText || t("global.valider") }}
      </FButton>
    </v-row>
  </v-dialog>
</template>

<script scoped lang="ts">
import {PropType, computed, defineComponent, ref} from "vue";
import {storeToRefs} from "pinia";
import {FButton} from "@/components/Global/Homemade/Buttons";
import {FEEDBACK_COMPONENTS_DEFAULT_TYPE} from "@/config/constants";

import {FButtonProps} from "@/interfaces";

import {useSimulationStore} from "@/stores/simulationStore";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "figma-dialog",
  components: {FButton},
  props: {
    modelValue: Boolean,
    action: Function as PropType<() => void>,
    type: {type: String, default: FEEDBACK_COMPONENTS_DEFAULT_TYPE},
    message: String,
    prefix: String,
    transition: String,
    header: String,
    hidePrefix: {type: Boolean, default: false},
    cancelText: String,
    validateText: String,
    onCancelClicked: {
      type: Function as PropType<() => void>,
      default: () => undefined,
    },
    onDestroyed: {type: Function as PropType<() => void>, default: () => null},
    cancelBtnProps: {
      type: Object as PropType<FButtonProps>,
      default: () => ({outlined: true} as FButtonProps),
    },
    confirmBtnProps: {
      type: Object as PropType<FButtonProps>,
      default: () => ({filled: true} as FButtonProps),
    },
    hideIcon: {type: Boolean, default: false},
  },
  emits: ["update:model-value"],
  setup(props) {
    const simulationStore = useSimulationStore();
    const {simulationBtnClicked} = storeToRefs(simulationStore);
    const {t} = useI18n();
    const dialog = ref(true);
    const actionBtnClicked = ref(false);

    const finalCancelBtnProps = computed<FButtonProps>(() => ({
      ...props.cancelBtnProps,
      disabled: actionBtnClicked.value || props.cancelBtnProps?.disabled,
    }));
    const finalConfirmBtnProps = computed<FButtonProps>(() => ({
      ...props.confirmBtnProps,
      disabled: actionBtnClicked.value || props.confirmBtnProps?.disabled,
    }));

    async function triggerAction() {
      actionBtnClicked.value = true;
      await props.action();
      dialog.value = false;
    }

    return {
      simulationBtnClicked,
      t,
      dialog,
      finalCancelBtnProps,
      finalConfirmBtnProps,
      triggerAction,
      actionBtnClicked,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: function (val: boolean) {
        this.dialog = val;
      },
    },
    $route: {
      deep: true,
      handler: function () {
        this.dialog = false;
      },
    },
  },
  computed: {
    computedIcon() {
      switch (this.type) {
        case "negative":
          return "alert-triangle";
        case "positive":
          return "check";
        default:
          return "info";
      }
    },

    computedPrefix() {
      switch (this.type) {
        case "warning":
          return this.t("Alert.Prefixes.warning");
        case "negative":
          return this.t("Alert.Prefixes.error");
        case "positive":
          return this.t("Alert.Prefixes.success");
        default:
          return this.t("Alert.Prefixes.info");
      }
    },
  },

  methods: {
    onClose() {
      this.onCancelClicked();
      this.dialog = false;
      /**
       * TODO:
       * the $emit here is only to toggle props.modelValue that changes this.dialog
       * this will be unnecessary because of the $destroy() after that'll be part
       * of the $openDialog rework
       */
      this.$emit("update:model-value", false);
      /**
       * resetting the state to have proper watch interactions on simulationBtnClicked
       * required at least for the scheduling Planning.vue watcher
       */
      this.simulationBtnClicked = null;
    },
  },
  unmounted() {
    this.onDestroyed();
  },
});
</script>

<style lang="scss">
.v-dialog .dialog-wrapper {
  background-color: rgb(var(--v-theme-newLayerBackground));
  border-radius: 8px;
  width: 664px;
  font-size: 16px;
  padding: 16px;
  // this needs to be above EventModal's
  z-index: 300;

  .dialog {
    &-content {
      margin: 0;
    }

    &-actions {
      margin: 0;
    }

    &-icon {
      color: rgb(var(--v-theme-newPrimaryRegular));
      &-warning {
        color: rgb(var(--v-theme-newOrangeRegular));
      }

      &-negative {
        color: rgb(var(--v-theme-newPinkRegular));
      }

      &-positive {
        color: rgb(var(--v-theme-newGreenRegular));
      }
    }

    &-prefix,
    &-header {
      color: rgb(var(--v-theme-newPrimaryRegular));
      font-weight: bold;

      &-warning {
        color: rgb(var(--v-theme-newOrangeRegular));
      }

      &-negative {
        color: rgb(var(--v-theme-newPinkRegular));
      }

      &-positive {
        color: rgb(var(--v-theme-newGreenRegular));
      }
    }
  }
}
</style>
