<script setup lang="ts">
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useStorage} from "@vueuse/core";
import _ from "lodash";
import {useMainStore} from "@/stores/mainStore";
import {ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER} from "@/config/constants";

interface OperationManualSortProps {
  operations: {wip_order?: number}[];
}

const props = withDefaults(defineProps<OperationManualSortProps>(), {
  operations: () => [] as OperationManualSortProps["operations"],
});

const {variables} = storeToRefs(useMainStore());

const isManualSortingEnabled = useStorage(
  ENABLE_MANUAL_SORTING_STORAGE_IDENTIFIER,
  false,
);

const minWipOrder = computed(() => {
  if (!props.operations?.length) return null;
  if (props.operations.length === 1) return props.operations[0].wip_order;
  return _.minBy(props.operations, "wip_order")?.wip_order;
});
const isDisplayed = computed(
  () => isManualSortingEnabled.value && minWipOrder.value != null,
);
</script>

<template>
  <div v-if="isDisplayed" class="fd-flex-center gap-2">
    <DevHelper class="text-newMainText"> ({{ minWipOrder }}) </DevHelper>

    <OplitIcon
      :fill="variables.newPrimaryRegular"
      name="hand-swipe-left"
      size="20px"
    />
  </div>
</template>
