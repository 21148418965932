import type {Operator, Poste, Sector} from "@/interfaces";
import {storeToRefs} from "pinia";
import {useMainStore} from "@/stores/mainStore";
import {getSortedPerimeters} from "@oplit/shared-module";

type OnSelectCallback = (
  secteur: Sector,
  remove: boolean,
  operateur?: Operator,
  idx?: number,
  field?: string,
) => void;

/**
     * @param functionToCallOnSelect : function that is called individually on each children machine of the clicked entity
     * for this function to work as intended, each component using this mixin should have a separate method calling
     * this function with the convenient method
     * e.g.
     
     // template
     :on-select-all="onSelectAll"
     
     // methods
     onSelectAll (...args) { return this.onSelectAllSectors(this.selectMachine, ...args) }
     
     */
export const useSectors = () => {
  const {stations} = storeToRefs(useMainStore());

  const onSelectAllSectors = (
    functionToCallOnSelect: OnSelectCallback,
    ...rest
  ) => {
    const [secteur, _, remove, onlyFirstLevel, extraParams = {}]: any[] = rest;
    const {operateur, idx, field} = extraParams; // optional yet case specific params for the sector selection on settings screen
    // pushes current sector in the array of selected sectors
    functionToCallOnSelect(secteur, remove, operateur, idx, field);

    if (!secteur?.children?.length) return;

    const sortedSectorChildren = getSortedPerimeters<Sector>(secteur.children);

    sortedSectorChildren.forEach((child): void => {
      if (["removed", "deleted"].includes(child.status) && !remove) return;
      // If only first level is true, we only select the N-1 children.
      if (onlyFirstLevel)
        return functionToCallOnSelect(child, false, operateur, idx, field);
      /**
       * when we click on a "select/deselect all" button at a sector level N, we only have the N+1 children that are populated
       * the children of these N+1 children are formated as such
       * { collection, id, level, name, status, type }
       * which prevents the nested select/deselect to work
       */
      if (!("children" in child)) {
        const populatedChild = stations.value.find(
          (poste: Poste) => poste.id === child.id,
        );
        if (populatedChild) {
          return onSelectAllSectors(
            functionToCallOnSelect,
            populatedChild,
            _,
            remove,
            false,
            {
              operateur,
              idx,
              field,
            },
          );
        }
      }
      return onSelectAllSectors(
        functionToCallOnSelect,
        child,
        _,
        remove,
        false,
        {operateur, idx, field},
      );
    });
  };
  return {
    onSelectAllSectors,
  };
};
