import {App, createApp} from "vue";
import {createPinia} from "pinia";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import vueApexCharts from "vue3-apexcharts";
import numeral from "@/plugins/external/numeral";
import VueFeather from "vue-feather";
import ECharts from "vue-echarts";
import {use} from "echarts/core";
import {Translation as I18nComponent} from "vue-i18n";
import * as Sentry from "@sentry/vue";
import {environment} from "@/firebase/init";

// import ECharts modules manually to reduce bundle size
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";

import appComponent from "@/App.vue";
import router from "@/router";
import i18n from "@/i18n";
import DevHelper from "@/domains/dev/components/DevHelper.vue";
import OplitIcon from "@/components/Commons/OplitIcon.vue";
import {applyUiScaling} from "./tscript/utils/uiScaling";

import fb, {segment_key} from "@/firebase/init";
import vuetify from "@/plugins/external/vuetify";
import segment from "@/plugins/external/segment";
import useCustomPlugins from "@/plugins/utils/useCustomPlugins";
import "vue-datepicker-next/index.css";

import moment from "moment";
import "moment/dist/locale/fr";
moment.locale("fr");

import "@/scss/constants.css";

document.addEventListener("DOMContentLoaded", () => {
  const initialScaling =
    parseFloat(localStorage.getItem("initialScaling")) || 1.0;
  applyUiScaling(initialScaling);
});

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
]);

const pinia = createPinia();

let app: App;
const auth = getAuth(fb);
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(appComponent);

    if (import.meta.env.PROD) {
      Sentry.init({
        app,
        dsn: "https://29be5516257294c26c4785112a981c3e@o4505647187886080.ingest.sentry.io/4505647192408064",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              "https:app.oplit.fr",
              "https:demo.oplit.fr",
              "https:staging.oplit.fr",
              "https:dev.oplit.fr",
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
            maskAllInputs: true,
          }),
        ],

        ignoreErrors: [
          /**
           * FirebaseError explicit listing
           */
          /Missing or insufficient permissions/,
          /deadline-exceeded/,
          /**
           * OPL-7778
           * we prevent these errors from being sent to Sentry as they are handled by the router.onError hook
           */
          /Failed to fetch dynamically imported module/,
          /Unable to preload CSS/,
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment,
      });

      // OPL-5394: Add Jimo Invader to live demo env
      if (environment === "demo") {
        (window as any).jimo = [];
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://undercity.usejimo.com/jimo-invader.js";
        window["JIMO_MANUAL_INIT"] = true;
        window["JIMO_PROJECT_ID"] = "9afae62b-8421-4b03-bb26-8d7fde70430d";
        document.getElementsByTagName("head")[0].appendChild(s);
      }
    }

    app
      .use(numeral) //
      .use(i18n)
      .use(router)
      .use(pinia)
      .use(vuetify)
      .use(vueApexCharts)
      .use(segment, {key: segment_key})
      .mount("#app");

    // FIXME: OPL-2410 remove this code and use segment analytics composable inside store modules
    pinia.use(({store}) => {
      store.$segment = app.config.globalProperties.$segment;
    });

    app.component("DevHelper", DevHelper);
    app.component("OplitIcon", OplitIcon);
    app.component(VueFeather.name, VueFeather);
    // register globally (or you can do it locally)
    app.component("v-chart", ECharts);
    // eslint-disable-next-line vue/multi-word-component-names
    app.component("i18n", I18nComponent);

    useCustomPlugins(app, {i18n: i18n.global, vuetify, router});
  }
});
