import {App, defineComponent, h, render} from "vue";
import ConwipTicketsGaugeSidebar from "@/components/Scheduling/Conwip/ConwipTicketsGaugeSidebar.vue";
import {createHTMLElementWithID} from "@/plugins/utils/pluginsHelper";
import type {
  ConwipSectorLike,
  OpenConwipTicketsGaugeSidebarFunction,
} from "@/interfaces";

/**
 * opens the conwip tickets gauge sidebar, with definition of `conwip_tickets_gauges` for a given sector within a group
 * invoke with this.$openConwipTicketsGaugeSidebar(sector)
 */
const OpenConwipTicketsGaugeSidebar = {
  install(app: App) {
    const openConwipTicketsGaugeSidebar: OpenConwipTicketsGaugeSidebarFunction =
      function (sector: ConwipSectorLike) {
        const conwipTicketsGaugeSidebarID = "conwip-tickets-gauge-sidebar";

        const conwipTicketsGaugeSidebarElement = document.getElementById(
          conwipTicketsGaugeSidebarID,
        );

        if (conwipTicketsGaugeSidebarElement)
          conwipTicketsGaugeSidebarElement.remove();

        const {container, id} = createHTMLElementWithID(
          conwipTicketsGaugeSidebarID,
        );

        const sidebarInstance = h(
          ConwipTicketsGaugeSidebar as ReturnType<typeof defineComponent>,
          {
            sector,
            id, // required from proper removal from DOM
          },
        );

        // the selector taken has to be a child of `.v-application` for the appropriate style to be applied
        const sibling = document.getElementsByClassName(
          "v-application__wrap",
        )[0];

        // container.style.zIndex = `${ofSidebarZindex}`;

        sibling.appendChild(container);

        sidebarInstance.appContext = app._context;
        render(sidebarInstance, container);
      };

    app.config.globalProperties.$openConwipTicketsGaugeSidebar =
      openConwipTicketsGaugeSidebar;
    app.provide("openConwipTicketsGaugeSidebar", openConwipTicketsGaugeSidebar);
  },
};

export default OpenConwipTicketsGaugeSidebar;
