<script setup lang="ts">
import {ref, watch} from "vue";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import {useMainStore} from "@/stores/mainStore";
import {HTMLElementStyleObject} from "@/interfaces";

const DEFAULT_COLOR_VALUE = null as string;

interface CustomColorPickerProps {
  colors: string[];
  currentColor?: string;
  columnsCount?: number;
  bordered?: boolean;
  hideDefault?: boolean;
}

const props = withDefaults(defineProps<CustomColorPickerProps>(), {
  currentColor: DEFAULT_COLOR_VALUE,
  bordered: false,
  hideDefault: false,
  columnsCount: (props) => props.colors.length,
});

const emit = defineEmits<{
  (e: "update:currentColor", color: string);
}>();

const mainStore = useMainStore();
const {variables} = storeToRefs(mainStore);

const selectedColor = ref<string>(props.currentColor);

const gridStyle = computed<HTMLElementStyleObject>(() => ({
  "grid-template-columns": `repeat(${
    props.columnsCount + +!props.hideDefault
  }, minmax(0, 1fr))`,
}));
const displayedColors = computed(() => {
  if (props.hideDefault) return props.colors;
  return [DEFAULT_COLOR_VALUE, ...props.colors];
});
/**
 * updates the selected color when the prop value changes
 * used to default the value upon CustomColorDefiner menu "disappearance"
 */
watch(
  () => props.currentColor,
  (newColor: string) => {
    selectedColor.value = newColor;
  },
);

function isSelectedColor(color: string): boolean {
  return selectedColor.value === color;
}
function selectColor(color: string): void {
  selectedColor.value = isSelectedColor(color) ? DEFAULT_COLOR_VALUE : color;
  emit("update:currentColor", selectedColor.value);
}
</script>

<template>
  <div class="custom-color-picker" :style="gridStyle">
    <div v-for="color in displayedColors" :key="`color-${color}`">
      <v-chip
        :color="variables[color || 'blanc']"
        :class="{
          selected: isSelectedColor(color),
          default: color === DEFAULT_COLOR_VALUE,
          bordered,
        }"
        class="cursor-pointer"
        size="small"
        variant="flat"
        @click="() => selectColor(color)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-color-picker {
  display: grid;
  gap: 12px;

  & .v-chip {
    padding: 0 13px;

    &.bordered {
      border: 1px solid rgb(var(--v-theme-newSelected)) !important;
      // removes the border width from the original padding ($chip-padding)
      padding: 0 12px;
    }

    &.selected {
      position: relative;
      overflow: visible;

      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 32px;
        left: 50%;
        top: 50%;
        background: transparent;
        transform: translate(-50%, -50%);
        border: 2px solid rgb(var(--v-theme-newPrimaryRegular));
        border-radius: 50%;
      }
    }

    &.default {
      padding: 0 12px;
      border: 1px solid rgb(var(--v-theme-grey-200));

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: rgb(var(--v-theme-red-600));
        top: 0;
        left: 50%;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
